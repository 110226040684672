import { HandleRefSearchSelector } from "./HandleRefSearchSelector.js";
import { DivWithModal } from "./ModalYesNoDialog.js";
import { QueryFormMultiSelect } from "./QueryFormMultiSelect.js";
import { QueryFormSearchResultsSelect } from "./QueryFormSearchResultsSelect.js";

export interface QueryFormConfig {
    description?: string;
    fields: QueryFormField[];
}

export interface QueryFormField {
    name: string;
    type: string;
    types?: string[];
    excludeTypes?: string[];
    allowedValues?: string[];
    queryField?: string; //used by QueryFormMultiSelect
    combineTermsWithOr?: boolean; //used by QueryFormMultiSelect
    query?: string;
}

export class QueryForm {
    private readonly queryFormConfig: QueryFormConfig;
    private readonly modalContainerDiv: DivWithModal;
    private readonly inputElements: any = []; //can be input or object

    constructor(
            title: string,
            queryTemplate: string,
            queryFormConfig: QueryFormConfig,
            onSearchClickCallback: (query: string) => void
    ) {
        this.queryFormConfig = queryFormConfig;
        this.modalContainerDiv = $('<div class="modal fade"></div>') as DivWithModal;
        $('body').append(this.modalContainerDiv);

        const modalDialog = $('<div class="modal-dialog">');
        this.modalContainerDiv.append(modalDialog);

        const modalContent = $('<div class="modal-content">');
        modalDialog.append(modalContent);

        const modalHeader = $('<div class="modal-header"></div>');
        modalContent.append(modalHeader);

        const closeButton = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>');
        modalHeader.append(closeButton);

        const titleElement = $('<h4 class="modal-title"></h4>');
        titleElement.text(title);
        modalHeader.append(titleElement);

        const modalBody = $('<div class="modal-body"></div>');
        modalContent.append(modalBody);

        if (queryFormConfig.description) {
            const description = $('<p></p>');
            description.text(queryFormConfig.description);
            modalBody.append(description);
        }

        const form = this.buildForm();
        modalBody.append(form);

        const modalFooter = $('<div class="modal-footer"></div>');
        modalContent.append(modalFooter);

        const searchButton = $('<button type="button" class="btn btn-sm btn-primary">Search</button>');
        modalFooter.append(searchButton);

        searchButton.on('click', () => {
            const formValues = this.getFormValues();
            const resultQuery = this.populateQueryWithValues(formValues, queryTemplate);
            onSearchClickCallback(resultQuery);
            this.hide();
        });

        this.modalContainerDiv.on('hidden', () => this.destroy());
    }

    populateQueryWithValues(formValues: string[], queryTemplate: string): string {
        //const escapedFormValues = formValues.map(s => this.escapeForQuery(s));
        return this.format(queryTemplate, formValues);
    }

    format(template: string, args: string[]): string {
        return template.replace(/{([0-9]+)}/g, (match, index) => {
            return typeof args[index] == 'undefined' ? match : args[index];
        });
    }

    escapeForQuery(s: string): string {
        if (!s) return s;
        return s.replace(/[\\+\-!():^[\]"{}~*?|&/]/g, '\\$&');
    }

    getFormValues(): string[] {
        const resultValues = [];
        for (const input of this.inputElements) {
            const inputType = input.attr('type');
            let val = null;
            if (inputType === "checkbox") {
                if (input.prop('checked')) {
                    val = "true";
                } else {
                    val = "false";
                }
            } else {
                val = input.val() as string;
            }
            if (inputType !== "QueryFormMultiSelect") {
                val = this.escapeForQuery(val);
            }
            resultValues.push(val);
        }
        return resultValues;
    }

    buildForm(): JQuery {
        const form = $('<form></form>');
        if (!this.queryFormConfig.fields) return form;
        for (const field of this.queryFormConfig.fields) {
            const formElement = this.buildElementForField(field);
            if (formElement) form.append(formElement);
        }
        return form;
    }

    buildElementForField(field: QueryFormField): JQuery | null {
        if (field.type === "boolean") {
            return this.buildBooleanElementForField(field);
        } else if (field.type === "enum") {
            return this.buildEnumElementForField(field);
        } else if (field.type === "searchResultsSelect") {
            return this.buildSearchResultsEnumElementForField(field);
        } else if (field.type === "multiSelect") {
            return this.buildMultiSelectElementForField(field);
        } else if (field.type === "handleReference") {
            return this.buildHandleReferenceElementForField(field);
        } else if (field.type === "string") {
            return this.buildStringElementForField(field);
        } else {
            console.log("UI config error " + field.type + " is not a valued field.type");
            return null;
        }
    }

    buildMultiSelectElementForField(field: QueryFormField): JQuery {
        const group = $('<div class="form-group"></div>');
        const multiSelect: QueryFormMultiSelect = new QueryFormMultiSelect(group, field);
        this.inputElements.push(multiSelect);
        return group;
    }

    buildEnumElementForField(field: QueryFormField): JQuery {
        const group = $('<div class="form-group"></div>');
        const label = $('<label></label>');
        label.text(field.name);
        group.append(label);
        const select = $('<select class="form-control"></select>');
        this.inputElements.push(select);
        group.append(select);
        for (const text of field.allowedValues!) {
            const option = $('<option></option>');
            option.text(text);
            select.append(option);
        }
        return group;
    }

    buildSearchResultsEnumElementForField(field: QueryFormField): JQuery {
        const group = $('<div class="form-group"></div>');
        const resultsSelect: QueryFormSearchResultsSelect = new QueryFormSearchResultsSelect(group, field);
        this.inputElements.push(resultsSelect);
        return group;
//         const group = $('<div class="form-group"></div>');
//         const label = $('<label></label>');
//         label.text(field.name);
//         group.append(label);
//         const select = $('<select class="form-control"></select>');
//         this.inputElements.push(select);
//         group.append(select);
//
//         let query = field.query;
//         const params = {
//           sortFields: [{name: "date"}]
//         };
//         const queryResults = await APP.doipClient.search(query, params);
//         for (const text of field.allowedValues!) {
//             const option = $('<option></option>');
//             option.text(text);
//             select.append(option);
//         }
//         return group;
    }

    buildBooleanElementForField(field: QueryFormField): JQuery {
        const checkbox = $('<div class="checkbox"></div>');
        const label = $('<label></label>');
        checkbox.append(label);
        const input = $('<input type="checkbox"/>');
        this.inputElements.push(input);
        label.append(input);
        const span = $('<span></span>');
        span.text(field.name);
        label.append(span);
        return checkbox;
    }

    buildHandleReferenceElementForField(field: QueryFormField): JQuery {
        const group = $('<div class="form-group"></div>');
        const label = $('<label></label>');
        label.text(field.name);
        group.append(label);
        const input = $('<input class="form-control"/>') as JQuery<HTMLInputElement> & { popover: (arg: unknown) => void };
        const types = field.types!;
        const excludeTypes = field.excludeTypes!;
        // Consider support prepend and prependHandleMintingConfigPrefix
        new HandleRefSearchSelector(input, null, types, excludeTypes);
        this.inputElements.push(input);
        group.append(input);
        return group;
    }

    buildStringElementForField(field: QueryFormField): JQuery {
        const group = $('<div class="form-group"></div>');
        const label = $('<label></label>');
        label.text(field.name);
        group.append(label);
        const input = $('<input class="form-control"/>');
        this.inputElements.push(input);
        group.append(input);
        return group;
    }

    show(): void {
        this.modalContainerDiv.modal('show');
    }

    hide(): void {
        this.modalContainerDiv.modal('hide');
    }

    destroy(): void {
        this.modalContainerDiv.remove();
    }
}
