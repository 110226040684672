import { DigitalObject } from '@cnri/doip-client';
import { SchemaEditor } from "./SchemaEditor.js";
import { SchemasToolBar } from "./SchemasToolBar.js";

export class SchemasEditor {
    private readonly schemasToolBar: SchemasToolBar;
    private readonly toolBarDiv: JQuery<HTMLDivElement>;
    private readonly schemaEditorDiv: JQuery<HTMLDivElement>;
    private schemaNameToSchemaMap: Record<string, unknown>;
    private schemaIdToNameMap: Record<string, string>;
    private schemaEditor!: SchemaEditor;

    constructor(
            containerDiv: JQuery,
            schemasParam: Record<string, unknown>,
            schemaIdsParam: Record<string, string>,
            disabledParam: boolean
    ) {
        this.schemaNameToSchemaMap = schemasParam;
        this.schemaIdToNameMap = schemaIdsParam;
        const headerRow = $('<div class="row object-header"></div>');
        containerDiv.append(headerRow);

        const objectHeader = $('<div class="heading col-md-6"></div>');
        const objectIdHeading = $('<h3 class="editorTitle">Types</h3>');
        objectHeader.append(objectIdHeading);
        headerRow.append(objectHeader);

        this.toolBarDiv = $(
            '<div id="schema-tool-bar" class="object-editor-toolbar col-md-6 pull-right"></div>'
        );
        headerRow.append(this.toolBarDiv);

        const description = $(
            '<p class="schemas-header">Click on "Add" button above (to the right) to add new types.</p>'
        );
        containerDiv.append(description);

        const schemasTotalHeader = $(
            '<div id="schemas-total-header" class="col-md-12 nopadding schemas-header"></div>'
        );
        containerDiv.append(schemasTotalHeader);

        const schemasList = $(
            '<div id="schemas-list" class="schemas-scroll col-md-12"></div>'
        );
        containerDiv.append(schemasList);

        this.schemasToolBar = new SchemasToolBar(
            containerDiv,
            schemasParam,
            disabledParam,
            (objectType: string, template?: unknown, js?: string) => this.createNewSchema(objectType, template, js),
            (objectType: string, disabled: boolean) => this.showSchemaEditorFor(objectType, disabled)
        );

        this.schemaEditorDiv = $(
            '<div id="schema-editor" class="schema-editor col-md-12"></div>'
        );
        containerDiv.append(this.schemaEditorDiv);
    }

    refresh(newSchemas?: Record<string, unknown>, newSchemaIds?: Record<string, string>): void {
        if (newSchemas && newSchemaIds) {
            this.schemaNameToSchemaMap = newSchemas;
            this.schemaIdToNameMap = newSchemaIds;
        }
        this.schemasToolBar.refresh(newSchemas);
    }

    enable(): void {
        this.schemasToolBar.enable();
        if (this.schemaEditor) {
            this.schemaEditor.enable();
        }
    }

    disable(): void {
        this.schemasToolBar.disable();
        if (this.schemaEditor) {
            this.schemaEditor.disable();
        }
    }

    showSchemaEditorFor(objectType?: string, disabled: boolean = false): void {
        if (this.schemaEditor) this.schemaEditor.destroy();
        this.schemaEditorDiv.empty();
        if (objectType) {
            const schemaId = this.getIdForSchema(objectType);
            if (!schemaId) return;
            APP.getObject(
                schemaId,
                (schemaDigitalObject: DigitalObject) => {
                    this.schemaEditor = new SchemaEditor(
                        this.schemaEditorDiv,
                        schemaDigitalObject,
                        objectType,
                        disabled
                    );
                    this.schemaEditorDiv.show();
                },
                (err: ErrorEvent) => {
                    console.log(JSON.stringify(err));
                }
            );
        }
    }

    getIdForSchema(type: string): string | undefined {
        for (const [id, schemaType] of Object.entries(this.schemaIdToNameMap)) {
            if (schemaType === type) return id;
        }
        return undefined;
    }

    createNewSchema(objectType: string, template?: unknown, js?: string): void {
        if (this.schemaNameToSchemaMap[objectType]) {
            APP.notifications.alertError(
                "The type " + objectType + " already exists."
            );
        } else {
            const content: any = {
                name: objectType
            };
            const schemaDigitalObject = {
                type: "Schema",
                attributes: {
                    content
                }
            };
            if (js) {
                content.javascript = js;
            }
            if (template) {
                content.schema = template;
            } else {
                content.schema = this.getDefaultSchema();
            }

            if (this.schemaEditor) this.schemaEditor.destroy();
            this.schemaEditorDiv.empty();
            this.schemaEditor = new SchemaEditor(
                this.schemaEditorDiv,
                schemaDigitalObject,
                objectType,
                false
            );
            this.schemaEditor.toggleCancelDeleteControls();
            this.schemaEditorDiv.show();
        }
    }

    getDefaultSchema(): unknown {
        return {
            type: "object",
            required: ["name", "description"],
            properties: {
                identifier: {
                    type: "string",
                    cordra: {
                        type: {
                            autoGeneratedField: "handle"
                        }
                    }
                },
                name: {
                    type: "string",
                    maxLength: 128,
                    title: "Name",
                    cordra: {
                        preview: {
                            showInPreview: true,
                            isPrimary: true
                        }
                    }
                },
                description: {
                    type: "string",
                    format: "textarea",
                    maxLength: 2048,
                    title: "Description",
                    cordra: {
                        preview: {
                            showInPreview: true,
                            excludeTitle: true
                        }
                    }
                }
            }
        };
    }
}
