import { html, HTMLTemplateResult, render } from 'lit-html';
import { SortField } from '@cnri/doip-client';

export class SortFieldComponent {
    private readonly container: HTMLElement | undefined;
    private readonly removeCallback: () => void;
    private readonly updateCallback: (newField?: SortField) => void;
    private readonly template: (name: string) => HTMLTemplateResult;

    constructor(
            container: Element | undefined,
            sortField: SortField,
            removeCallback: () => void,
            updateCallback: (newField?: SortField) => void
    ) {
        this.container = container as HTMLElement | undefined;
        this.removeCallback = removeCallback;
        this.updateCallback = updateCallback;
        this.template = (name: string) => {
            const scoreChecked = sortField.name === 'score';
            const createdChecked = sortField.name === 'metadata/createdOn';
            const modifiedChecked = sortField.name === 'metadata/modifiedOn';
            const customChecked = !scoreChecked && !createdChecked && !modifiedChecked;
            const customValue = customChecked ? '' : sortField.name;
            return html`
                <div @change=${() => this.updateSortField()}>
                    <label>
                        <input ?checked=${scoreChecked} value="score" name="${name}" type="radio"/ >
                        Relevance
                    </label>
                    <label>
                        <input ?checked=${createdChecked} value="metadata/createdOn" name="${name}" type="radio"/ >
                        Created On
                    </label>
                    <label>
                        <input ?checked=${modifiedChecked} value="metadata/modifiedOn" name="${name}" type="radio"/ >
                        Modified On
                    </label>
                    <label>
                        <input ?checked=${customChecked} value="${customValue}" class="sort-custom" name="${name}" type="radio"/ >
                        Custom
                    </label>
                    <input @change=${() => this.updateCustomValue()}
                            type="text"
                            name="sortText"
                            class="form-control"
                            value="${sortField.name}"
                            ?disabled=${!customChecked}
                            placeholder="example: /name" />
                    <select class="form-control">
                        <option ?selected=${!sortField.reverse} value="ASC">Ascending</option>
                        <option ?selected=${sortField.reverse} value="DESC">Descending</option>
                    </select>
                    <button class="btn btn-sm" @click=${() => this.removeCallback()}>
                        <i class="fa fa-subtract"></i>
                    </button>
                </div>
            `;
        };
        this.render();
    }

    private render(): void {
        if (!this.container) return;
        const id = (Math.random() + 1).toString(36).substring(2);
        const name = `sortField-${id}`;
        render(this.template(name), this.container);
    }

    private updateSortField(): void {
        if (!this.container) return;
        const selected = this.container.querySelector('input[type=radio]:checked') as HTMLInputElement | null;
        const sortDirection = this.container.querySelector('select');
        let newSortField = undefined;
        if (sortDirection && selected) {
            newSortField = {
                name: selected.value,
                reverse: sortDirection?.value === 'DESC'
            };
            if (this.isCustomSortActive()) {
                const isNonCustomVal = ['score', 'metadata/createdOn', 'metadata/modifiedOn'].includes(selected.value);
                const customRadio = this.container.querySelector('.sort-custom') as HTMLInputElement;
                const customVal = customRadio.value || '';
                newSortField.name = isNonCustomVal ? '' : customVal;
            }
            const sortText = this.container.querySelector('input[name=sortText]') as HTMLInputElement | null;
            // TODO figure out why, without this next line, the input text won't always be updated properly after a re-render.
            if (sortText) sortText.value = newSortField.name;
        }
        this.updateCallback(newSortField);
    }

    private updateCustomValue(): void {
        if (!this.container || !this.isCustomSortActive()) return;
        const sortText = this.container.querySelector('input[name=sortText]') as HTMLInputElement;
        const customRadio = this.container.querySelector('.sort-custom') as HTMLInputElement;
        if (!sortText || !customRadio) return;
        customRadio.value = sortText.value;
    }

    private isCustomSortActive(): boolean {
        if (!this.container) return false;
        const customRadio = this.container.querySelector('.sort-custom') as HTMLInputElement;
        return customRadio?.checked || false;
    }
}
