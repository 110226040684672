import ClickEvent = JQuery.ClickEvent;

// Simple text-input to combo-box; depends on Twitter Bootstrap
export class ComboBox {
    textInput: JQuery<HTMLInputElement>;
    div: JQuery;

    constructor(textInput: JQuery<HTMLInputElement>, items: string[]) {
        const div = $('<div class="dropdown" style="line-height: 30px"/>');
        textInput.before(div);
        textInput.detach();
        const inputGroup = $('<div class="input-group" style="width: 100%" data-toggle="dropdown"/>');
        inputGroup.on('click', (e) => {
            // Without this, Chrome will show the dropdown even when the input box is disabled.
            if (textInput.prop("disabled")) {
                e.stopPropagation();
            }
        });
        div.append(inputGroup);
        inputGroup.append(textInput);
        const inputGroupBtnSpan = $('<span class="input-group-btn"/>');
        inputGroup.append(inputGroupBtnSpan);
        const button = $('<button type="button" class="btn btn-default"/>');
        button.append($('<span class="caret"/>'));
        inputGroupBtnSpan.append(button);
        const ul = $('<ul class="dropdown-menu"/>');
        ul.on('click', 'a', (e) => this.onClick(e));
        div.append(ul);
        for (const item of items) {
            const li = $('<li>');
            const a = $('<a>').attr('href', '#').text(item);
            li.append(a);
            ul.append(li);
        }
        this.div = div;
        this.textInput = textInput;
    }

    hide(): void {
        this.div.hide();
    }

    show(): void {
        this.div.css("display", "inline-block");
    }

    enable(): void {

    }

    disable(): void {

    }

    onClick(event: ClickEvent): void {
        event.preventDefault();
        this.textInput.val($(event.target).parent().text());
        this.textInput.trigger('change');
    }
}
