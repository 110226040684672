export class HtmlPageViewer {
    constructor(containerDiv: JQuery, options: { url?: string; embedded?: boolean }) {
        containerDiv.empty();
        if (options.url) {
            this.showFramedPage(containerDiv, options.url);
        } else {
            this.showNotFoundPage(containerDiv);
        }
    }

    showNotFoundPage(containerDiv: JQuery): void {
        const notFound = `<div>Page Not Found</div>`;
        containerDiv.html(notFound);
    }

    showFramedPage(containerDiv: JQuery, url: string): void {
        const iframe: JQuery<HTMLIFrameElement> = $(
            '<iframe style="width:100%; min-height:217px; max-height:1200px; border:0; margin:0; overflow:scroll;"></iframe>'
        );
        iframe.attr('src', url);
        iframe.on("load", () => {
            if (iframe[0].contentDocument) {
                iframe.height(iframe[0].contentDocument.body.scrollHeight);
            }
            if (217 === iframe.height()) {
                iframe.css("min-height", "900px");
            }
        });
        containerDiv.append(iframe);
        let iframeElement: any = iframe[0];
        iframeElement.contentWindow.CordraUiSupport = APP.getUiSupport();
    }
}
