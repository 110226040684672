import { DigitalElement } from '@cnri/doip-client';
import { VideoPlayer } from "./VideoPlayer.js";
import { AudioPlayer } from "./AudioPlayer.js";
import * as FileIconUtil from "./FileIconUtil.js";
import { ElementsEditor } from './ElementsEditor.js';

export class ElementEditor {
    private nameInput?: JQuery;
    private nameLabel?: JQuery;
    private readonly fileInput: JQuery<HTMLInputElement>;

    private readonly tdName: JQuery;
    private readonly tdFilenameAndSize: JQuery;
    private readonly tdFileInput: JQuery;
    private readonly tdDelete: JQuery;

    private mediaTr?: JQuery;
    private textEditorTr?: JQuery;
    private readonly elementTr: JQuery;
    private mediaContainer!: JQuery;
    private textEditor?: AceAjax.Editor;
    private isTextChanged: boolean = false;
    private revertButton!: JQuery;
    private editButton!: JQuery;
    private readonly parentElementsEditor: ElementsEditor;
    private readonly allowDownloadElements: boolean;
    private readonly objectId?: string;
    private readonly element: DigitalElement | null;
    private isShowingTextEditor: boolean = false;

    readonly isNew: boolean;

    constructor(
            parentElementsEditor: ElementsEditor,
            elementTr: JQuery,
            element: DigitalElement | null,
            disabled: boolean,
            isNewParam: boolean,
            allowDownloadElements: boolean = false
    ) {
        this.parentElementsEditor = parentElementsEditor;
        this.isNew = isNewParam;
        this.allowDownloadElements = allowDownloadElements;
        this.element = element;
        this.elementTr = elementTr;
        this.tdName = $("<td></td>");
        this.tdFilenameAndSize = $("<td></td>");
        this.tdFileInput = $("<td></td>");
        this.tdDelete = $('<td colspan="2"></td>');

        this.elementTr.append(this.tdName);
        this.elementTr.append(this.tdFilenameAndSize);
        this.elementTr.append(this.tdFileInput);
        this.elementTr.append(this.tdDelete);

        this.objectId = APP.getObjectId();

        this.fileInput = $("<input/>");
        this.fileInput.attr("type", "file");

        this.buildControls(element, disabled);

        if (!disabled) {
            this.tdFileInput.append(this.fileInput);
            this.prettifyThisFileInput(this.fileInput);
        }
    }

    getName(): string { //TODO change to getId
        if (this.element) {
            return this.element.id;
        } else {
            return (this.nameInput?.val() || 'unknown') as string;
        }
    }

    getNameFromInputOrLabel(): string | undefined {
        if (this.nameInput != null) {
            return this.nameInput.val() as string | undefined;
        } else {
            return this.nameLabel?.text();
        }
    }

    getBlob(): File | undefined {
        let file = this.fileInput?.[0].files?.[0];
        if (!file && this.isTextChanged) {
            const text = this.getCurrentText();
            if (!text) return undefined;
            const blob = new Blob([text]);
            const options = { type: this.element!.type };
            const filename = this.getFilenameFromElement(this.element!);
            file = new File([blob], filename || 'unknown', options);
        }
        return file;
    }

    buildControls(element: DigitalElement | null, disabled: boolean): void {
        if (!disabled) {
            const closeButton = $(
                '<button class="btn btn-sm btn-danger pull-right"><i class="fa fa-trash"></i></button>'
            );
            this.tdDelete.append(closeButton);
            closeButton.on("click", () => this.onCloseClick());
        }
        if (this.isNew) {
            this.nameInput = $('<input type="text" style="width:100%" />');
            const elementId = this.parentElementsEditor.getNextDefaultElementName();
            this.nameInput.val(elementId);
            this.tdName.append(this.nameInput);
            this.nameInput.trigger("focus");
        } else if (element) {
            this.nameLabel = $("<span></span>");
            this.tdName.append(this.nameLabel);
            this.nameLabel.text(element.id);

            if (this.isVideo() || this.isAudio() || this.isImage()) {
                const playButton = $(
                    //'<button class="btn btn-sm btn-default pull-right"><span class="glyphicon glyphicon-play"></span></button>'
                    '<button class="btn btn-sm btn-default pull-right"><i class="fa fa-play"></i></button>'
                );
                this.tdDelete.append(playButton);
                playButton.on("click", () => this.showMediaPlayer(element));
            }
            if (!disabled && this.isText()) {
                this.editButton = $(
                    '<button class="btn btn-sm btn-primary pull-right"><i class="fa fa-edit"></i></button>'
                );
                this.tdDelete.append(this.editButton);
                this.editButton.on("click", () => this.showTextEditor(element.id));

                this.revertButton = $(
                    '<button class="btn btn-sm btn-warning pull-right" style="display:none"><i class="fa fa-undo"></i></button>'
                );
                this.tdDelete.append(this.revertButton);
                this.revertButton.on("click", () => this.revertTextEditor());

                const revertButtonSpan = $("<span></span>");
                this.revertButton.append(revertButtonSpan);
                revertButtonSpan.text("Revert");
            }
            const filename = this.getFilenameFromElement(element);
            const iconName = FileIconUtil.getFontAwesomeIconNameFor(
                element.type,
                filename
            );
            const icon = $('<i class="fa fa-' + iconName + ' fa-1x"></i>');
            this.tdFilenameAndSize.append(icon);
            this.tdFilenameAndSize.append(" ");

            if (this.allowDownloadElements) {
                const downloadButton = this.buildDownloadButton(filename || 'unknown');
                this.tdFilenameAndSize.append(downloadButton);
            } else {
                const filenameLabel = $("<span></span>");
                filenameLabel.text(filename || 'unknown');
                this.tdFilenameAndSize.append(filenameLabel);
            }

            const sizeLabel = $('<p class="helpText"></p>');
            sizeLabel.text("[" + element.length + " bytes]");
            this.tdFilenameAndSize.append(sizeLabel);
        }
    }

    getFilenameFromElement(elementId: DigitalElement): string | undefined {
        const attributes: any = elementId?.attributes;
        const filename = attributes.filename;
        return filename;
    }

    isImage(): boolean {
        const filename = this.getFilenameFromElement(this.element!);
        return !!this.element && FileIconUtil.isImage(this.element.type, filename);
    }

    isVideo(): boolean {
        const filename = this.getFilenameFromElement(this.element!);
        return !!this.element && FileIconUtil.isVideo(this.element.type, filename);
    }

    isAudio(): boolean {
        const filename = this.getFilenameFromElement(this.element!);
        return !!this.element && FileIconUtil.isAudio(this.element.type, filename);
    }

    isText(): boolean {
        const filename = this.getFilenameFromElement(this.element!);
        return !!this.element && FileIconUtil.isText(this.element.type, filename);
    }

    isJson(): boolean {
        const filename = this.getFilenameFromElement(this.element!);
        return !!this.element && FileIconUtil.isJson(this.element.type, filename);
    }

    isJavaScript(): boolean {
        const filename = this.getFilenameFromElement(this.element!);
        return !!this.element && FileIconUtil.isJavaScript(this.element.type, filename);
    }

    isHtml(): boolean {
        const filename = this.getFilenameFromElement(this.element!);
        return !!this.element && FileIconUtil.isHtml(this.element.type, filename);
    }

    async showMediaPlayer(element: DigitalElement): Promise<void> {
        if (this.mediaTr != null) {
            this.mediaTr.remove();
            delete this.mediaTr;
        } else {
            this.mediaTr = $("<tr></tr>");
            const td = $('<td colspan="5"></td>');
            const container = $('<div style="width:100%"></div>');
            this.elementTr.after(this.mediaTr);
            this.mediaTr.append(td);
            td.append(container);
            let mediaUri = this.getUri();
            const accessToken = await APP.getAccessToken();
            if (accessToken) {
                mediaUri += "&access_token=" + accessToken;
            }
            if (this.isVideo()) {
                new VideoPlayer(container, mediaUri);
            } else if (this.isAudio()) {
                new AudioPlayer(container, mediaUri);
            } else if (this.isImage()) {
                const image = $(`<img alt="${element.id}" style="width:20%">`);
                image.attr("src", mediaUri);
                container.append(image);
            }
        }
    }

    getCurrentText(): string | undefined {
        return this.textEditor?.getValue();
    }

    revertTextEditor(): void {
        if (this.textEditorTr) {
            this.textEditorTr.remove();
            delete this.textEditorTr;
            this.isShowingTextEditor = false;
            this.isTextChanged = false;
            delete this.textEditor;
            this.revertButton.hide();
        }
    }

    showTextEditor(elementId: string): void {
        if (this.isShowingTextEditor) {
            this.textEditorTr!.hide();
            this.isShowingTextEditor = false;
        } else {
            if (this.textEditorTr) {
                this.textEditorTr.show();
            } else {
                this.textEditorTr = $("<tr></tr>");
                const td = $('<td colspan="5"></td>');
                this.mediaContainer = $('<div style="width:100%"></div>');
                this.elementTr.after(this.textEditorTr);
                this.textEditorTr.append(td);
                td.append(this.mediaContainer);
                APP.getElementContent(
                    this.objectId,
                    elementId,
                    (result: Body) => this.onGotElementContentSuccess(result),
                    (err: unknown) => this.onGotElementError(err)
                );
            }
            this.isShowingTextEditor = true;
        }
    }

    async onGotElementContentSuccess(result: Body): Promise<void> {
        const streamText = await result.text();
        this.showTextInEditor(streamText);
    }

    showTextInEditor(elementText: string): void {
        const textEditorDiv = $('<div class="ace_editor"></div>');
        this.mediaContainer.append(textEditorDiv);
        const textEditor = ace.edit(textEditorDiv[0]);
        textEditor.setTheme("ace/theme/textmate");
        if (this.isJavaScript()) {
            APP.fixAceJavascriptEditor(textEditor);
            textEditor.getSession().setMode("ace/mode/javascript");
        } else if (this.isJson()) {
            textEditor.getSession().setMode("ace/mode/json");
        } else if (this.isHtml()) {
            textEditor.getSession().setMode("ace/mode/html");
        }
        textEditor.setOptions({
            maxLines: Infinity,
            minLines: 10
        });
        textEditor.$blockScrolling = Infinity;
        textEditor.setValue(elementText, -1);
        textEditor.getSession().on("change", () => {
            this.isTextChanged = true;
            this.revertButton.show();
        });
        this.textEditor = textEditor;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onGotElementError(err: any): void {
        if (typeof err === 'object' && err.json) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            err.json().then((json: any) => {
                console.log(json);
            });
        } else {
            console.log(err);
        }
    }

    onCloseClick(): void {
        this.parentElementsEditor.deleteElement(this);
        this.elementTr.remove();
        if (this.mediaTr) {
            this.mediaTr.remove();
        }
        if (this.textEditorTr) {
            this.textEditorTr.remove();
        }
    }

    buildDownloadButton(text: string): JQuery {
        const form = $('<form style="display:none" method="POST" target="_blank"/>');
        form.attr("action", () => this.getUri());
        const accessTokenInput = $('<input type="hidden" name="access_token"/>');
        form.append(accessTokenInput);
        const downloadButton = $('<a href="#"></a>');
        if (text) {
            downloadButton.text(text);
        } else {
            downloadButton.text("Download");
        }
        downloadButton.on("click", async (event) => {
            event.preventDefault();
            const accessToken = await APP.getAccessToken();
            if (accessToken) accessTokenInput.val(accessToken);
            form.trigger("submit");
        });
        downloadButton.append(form);
        return downloadButton;
    }

    getUri(): string {
        if (!this.element) return '';
        return (
            APP.getBaseUri() + "doip/?o=Retrieve&t=" +
            this.objectId +
            "&element=" +
            encodeURIComponent(this.element.id).replace(/%2F/g, "/")
        );
    }

    prettifyThisFileInput(input: JQuery<HTMLInputElement>): void {
        if (input.css("opacity") === "0") return;
        input.css("opacity", "0");
        input.css("z-index", "-100");
        input.css("position", "fixed");
        input.css("left", "-10px");
        input.css("height", "1px");
        input.css("width", "1px");
        input.css("margin", "0");
        input.css("padding", "0");
        const textForButton = "Choose file";
        const button = $(
            '<button class="btn btn-sm btn-primary" type="button"><i class="fa fa-file"></i><span>' +
            textForButton +
            "</span></button>"
        );
        const span = $('<p class="helpText">No files chosen</p>');
        const div = $('<div class="hide-with-buttons"/>');
        div.append(button, span);
        input.before(div);
        button.off("click").on("click", (event) => {
            event.stopImmediatePropagation();
            input.trigger("click");
        });
        input.on("change", () => {
            if (input[0].files) {
                if (input[0].files.length === 0) {
                    span.text("No files chosen");
                } else if (input[0].files.length === 1) {
                    span.text(input[0].files[0].name);
                } else {
                    span.text(input[0].files.length + " files");
                }
            }
            this.revertTextEditor();
            if (this.editButton) this.editButton.hide();
        });
    }
}
