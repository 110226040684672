import { html, HTMLTemplateResult, render } from 'lit-html';

export class CreateButtonComponent {
    private readonly container: HTMLElement | undefined;
    private readonly types: string[];
    private readonly numTypes: number;

    constructor(container: Element | undefined, types: string[], numTypes: number) {
        this.container = container as HTMLElement | undefined;
        this.types = types.filter(type => type !== "CordraDesign");
        this.types.sort();
        this.numTypes = numTypes;
        this.render();
    }

    private render(): void {
        if (!this.container) return;
        render(this.getTemplate(), this.container);
        this.activateTypeaheadIfNeeded();
    }

    private getTemplate(): HTMLTemplateResult {
        if (!this.types || this.types.length === 0) {
            return html``;
        }
        let button;
        if (this.types.length === 1) {
            button = this.buildSingleCreateButton(this.types[0]);
        } else if (this.types.length <= (this.numTypes || 0)) {
            button = this.buildCreateDropdown(this.types);
        } else {
            button = this.buildCreateTypeahead();
        }
        return html`
            <span class="input-group-btn">
                ${button}
            </span>
        `;
    }

    private buildSingleCreateButton(type: string): HTMLTemplateResult {
        let label = 'Create';
        if (type) label += ` ${type}`;
        return html`
            <button @click=${this.onCreateClicked} class="btn btn-default cordra-create-button" data-type="${type}">
                ${label}
            </button>
        `;
    }

    private onCreateClicked(e: Event): void {
        const type = (e.target! as HTMLElement).dataset.type;
        if (type) APP.setCreateInFragment(type);
    }

    private buildCreateDropdown(types: string[]): HTMLTemplateResult {
        types.sort();
        return html`
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle cordra-create-button"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown">
                        Create <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    ${types.map(t => {
                        return html`
                            <li>
                                <a @click=${this.onCreateClicked} style="cursor: pointer" tabindex="-1" data-type="${t}">
                                    ${t}
                                </a>
                            </li>
                        `;
                    })}
                </ul>
            </div>
        `;
    }

    private buildCreateTypeahead(): HTMLTemplateResult {
        return html`
            <input class="typeahead form-control create-typeahead"
                   type="text"
                   placeholder="Type to create">
        `;
    }

    private activateTypeaheadIfNeeded(): void {
        if (!this.types || this.types.length === 0) return;
        const inputs = document.getElementsByClassName('create-typeahead');
        if (inputs.length !== 1) return;
        // typeahead requires jquery
        const input = $(inputs[0]) as JQuery & { typeahead: (...args: object[]) => void };
        input.typeahead(
            {
                hint: true,
                highlight: true,
                minLength: 1
            },
            {
                name: "create",
                source: this.substringMatcher()
            }
        );
        input.on("typeahead:select", (_, selection) => {
            APP.setCreateInFragment(selection as string);
        });
    }

    private substringMatcher() {
        return (q: string, cb: (m: string[]) => void): void => {
            // an array that will be populated with substring matches
            const matches: string[] = [];
            // regex used to determine if a string contains the substring `q`
            const substrRegex = new RegExp(q, "i");

            // iterate through the pool of strings and for any string that
            // contains the substring `q`, add it to the `matches` array
            this.types.forEach((s) => {
                if (substrRegex.test(s)) {
                    matches.push(s);
                }
            });

            cb(matches);
        };
    }
}
