import * as tv4 from 'tv4';
import { JsonSchema, ValidationError } from "tv4";
import { Constants } from "./Constants.js";

class SchemaExtractor {
    pointers?: Record<string, JsonSchema>;

    extract(json: unknown, schema: JsonSchema): Record<string, JsonSchema> {
        this.pointers = {};
        tv4.validate(json, schema);
        return this.pointers;
    }
}

const schemaExtractor = new SchemaExtractor();

export class SchemaExtractorFactory {
    static get(): SchemaExtractor {
        return schemaExtractor;
    }

    static newSchemas(uriToNormalizedSchemaMap: Record<string, JsonSchema | undefined>): void {
        tv4.reset();
        for (const uri in uriToNormalizedSchemaMap) {
            const schema = uriToNormalizedSchemaMap[uri];
            if (!schema) continue;
            tv4.addSchema(uri, schema);
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function keywordHandler(_data: any, _value: any, schema: JsonSchema, jsonPointer: string): null | string | ValidationError {
    const extractor = SchemaExtractorFactory.get();
    if (extractor.pointers) {
        extractor.pointers[jsonPointer] = schema;
    }
    return null;
}

tv4.defineKeyword(Constants.CORDRA_SCHEMA_KEYWORD, keywordHandler);
tv4.defineKeyword(Constants.OLD_REPOSITORY_SCHEMA_KEYWORD, keywordHandler);
