export type DivWithModal = JQuery<HTMLDivElement> & { modal: (arg: string | { keyboard: boolean }) => void };

export class ModalYesNoDialog {
    private readonly modalContainerDiv: DivWithModal;
    private readonly yesCallback: () => void;
    private readonly noCallback?: () => void;

    constructor(msg: string, yesCallback: () => void, noCallback?: () => void) {
        this.yesCallback = yesCallback;
        this.noCallback = noCallback;
        this.modalContainerDiv = $('<div class="modal fade"></div>') as DivWithModal;
        $('body').append(this.modalContainerDiv);

        const modalDialog = $('<div class="modal-dialog">');
        this.modalContainerDiv.append(modalDialog);

        const modalContent = $('<div class="modal-content">');
        modalDialog.append(modalContent);

        const modalBody = $('<div class="modal-body"></div>');
        modalContent.append(modalBody);
        const message = $('<p></p>');
        message.text(msg);
        modalBody.append(message);

        const modalFooter = $('<div class="modal-footer"></div>');
        modalContent.append(modalFooter);

        const noButton = $('<input type="button" value="No" class="btn btn-sm btn-default" />');
        const yesButton = $('<input type="button" value="Yes" class="btn btn-sm btn-primary" />');

        modalFooter.append(noButton);
        modalFooter.append(yesButton);

        noButton.on('click', () => this.onNoClick());
        yesButton.on('click', () => this.onYesClick());

        this.modalContainerDiv.on('hidden', () => this.destroy());
    }

    onNoClick(): void {
        if (this.noCallback) this.noCallback();
        this.hide();
    }

    onYesClick(): void {
        if (this.yesCallback) this.yesCallback();
        this.hide();
    }

    show(): void {
        this.modalContainerDiv.modal('show');
    }

    hide(): void {
        this.modalContainerDiv.modal('hide');
    }

    destroy(): void {
        this.modalContainerDiv.remove();
    }
}
