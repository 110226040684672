import { QueryFormField } from "./QueryForm.js";
import { DigitalObject } from '@cnri/doip-client';
import { ObjectPreviewUtil } from '../ObjectPreviewUtil.js';
import { ObjectConvertUtil } from './ObjectConvertUtil.js';

export class QueryFormSearchResultsSelect {

    private readonly div: JQuery;
    private select: JQuery | null;

    constructor(
            containerDiv: JQuery,
            queryFormField: QueryFormField
    ) {
        this.div = containerDiv;
        this.select = null;
        this.buildElementsForField(queryFormField);
    }

    buildElementsForField(field: QueryFormField) {
        const label = $('<label></label>');
        label.text(field.name);
        this.div.append(label);
        this.select = $('<select class="form-control"></select>');
        this.div.append(this.select);
        this.populateSelectWithSearch(field); // not waiting for promise to resolve
    }

    async populateSelectWithSearch(field: QueryFormField): Promise<void> {
        const query = field.query!;
        const params = {
            sortFields: [ { name: "date" } ]
        };
        const queryResults = await APP.getUiSupport().getDoipClient().search(query, params);
        for (const result of queryResults.results) {
            const digitalObject: DigitalObject = ObjectConvertUtil.cordraObjectToDigitalObject(result)!;
            const title = this.getTitleFormPreviewData(digitalObject);
            const option = $('<option value="' + result.id! + '"></option>');
            option.text(title);
            this.select!.append(option);
        }
    }

    getTitleFormPreviewData(digitalObject: DigitalObject): string {
        const previewData = ObjectPreviewUtil.getPreviewData(digitalObject);
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            const prettifiedPreviewData = this.prettifyPreviewJson(thisPreviewData.previewJson);
            if (!prettifiedPreviewData) continue;
            if (thisPreviewData.isPrimary) {
                return prettifiedPreviewData;
            }
        }
        return digitalObject.id!;
    }

    prettifyPreviewJson(previewJson: unknown, maxLength?: number): string {
        let res;
        if (typeof previewJson === "string") {
            res = previewJson;
        } else {
            res = JSON.stringify(previewJson);
        }
        if (maxLength) {
            if (res.length > maxLength) {
                res = res.substring(0, maxLength) + "...";
            }
        }
        return res;
    }

    val() {
        return this.select!.val();
    }

    //hack because this object will be in a list of input elements
    attr() {
        return 'QueryFormSearchResultsSelect';
    }

    escapeForQuery(s: string): string {
        if (!s) return s;
        return s.replace(/[\\+\-!():^[\]"{}~*?|&/]/g, '\\$&');
    }
}