import { html, HTMLTemplateResult, render } from 'lit-html';
import { DigitalObject, SearchResponse, QueryParams } from '@cnri/doip-client';

const showTotalResultsLabel: boolean = true;

export class PaginationComponent {
    private readonly container: HTMLElement;
    private readonly numResults: number;
    private readonly pageOffset: number;
    private readonly pageSize: number;
    private readonly responseSize: number;
    private readonly clickHandler: (e: Event) => void;

    constructor(container: HTMLElement, results: SearchResponse<string | DigitalObject>, params: QueryParams, clickHandler: (e: Event) => void) {
        this.container = container;
        this.numResults = results.size;
        this.pageOffset = params.pageNum!;
        this.pageSize = params.pageSize!;
        this.responseSize = results.size;
        this.clickHandler = clickHandler;
        this.render();
    }

    private render(): void {
        if (!this.container) return;
        if (this.responseSize <= this.pageSize && this.responseSize !== -1) {
            render(html``, this.container);
            return;
        }
        const navLinks = this.getNavLinks();
        const totalsLabel = this.getTotalResultsLabel();
        const template = html`
            <div class="pagination-container">
                <ul class="pagination cordra-pagination-small">
                    ${navLinks}
                </ul>
                ${totalsLabel}
            </div>
        `;
        render(template, this.container);
    }

    private getNavLinks(): HTMLTemplateResult[] {
        const links = [];
        const totalNumOfPages = this.numResults === -1 ? -1 : this.numResults / this.pageSize;
        const roundedTotalNumOfPages = Math.ceil(totalNumOfPages);
        const currentPageNumber = this.pageOffset;
        const startingNumber = this.getStartPaginationNumberForCurrent(currentPageNumber, totalNumOfPages);
        const endingNumber = this.getEndPaginationNumberForCurrent(currentPageNumber, roundedTotalNumOfPages);
        if (totalNumOfPages > 1 || totalNumOfPages === -1) {
            const isFirstPage = currentPageNumber === 0;
            const prevLi = html`
                <li class="page-item ${isFirstPage ? 'disabled' : ''}">
                    <a id="pagePrev"
                        href="#"
                        class="page-link"
                        @click=${(e: Event) => this.clickHandler(e)}
                        data-pagenum="${isFirstPage ? currentPageNumber : currentPageNumber - 1}">
                            «
                    </a>
                </li>
            `;
            links.push(prevLi);

            for (let i = startingNumber; i < endingNumber; i++) {
                const pageNumForDisplay = i + 1;
                const isActive = currentPageNumber === i;
                const li = html`
                    <li class="page-item ${isActive ? 'active' : ''}">
                        <a id="${`page` + pageNumForDisplay}"
                            href="#"
                            class="page-link"
                            data-pagenum="${i}"
                            @click=${(e: Event) => this.clickHandler(e)}>
                                ${pageNumForDisplay}
                        </a>
                    </li>
                `;
                links.push(li);
            }

            const isLastPage = currentPageNumber === roundedTotalNumOfPages - 1;
            const nextLi = html`
                <li class="page-item ${isLastPage ? 'disabled' : ''}">
                    <a id="pageNext"
                        href="#"
                        class="page-link"
                        data-pagenum="${isLastPage ? currentPageNumber : currentPageNumber + 1}"
                        @click=${(e: Event) => this.clickHandler(e)}>
                            »
                    </a>
                </li>
            `;
            links.push(nextLi);
        }
        return links;
    }

    private getStartPaginationNumberForCurrent(currentPageNumber: number, totalNumOfPages: number): number {
        const roundedTotalNumOfPages = Math.ceil(totalNumOfPages);
        let result;
        if (currentPageNumber < 6) {
            result = 0;
        } else {
            result = currentPageNumber - 5;
        }
        if (roundedTotalNumOfPages - result < 10) {
            result = roundedTotalNumOfPages - 10;
        }
        if (result < 0) {
            result = 0;
        }
        return result;
    }

    private getEndPaginationNumberForCurrent(currentPageNumber: number, totalNumOfPages: number): number {
        let result;
        if (currentPageNumber >= 6) {
            result = currentPageNumber + 4;
        } else {
            result = 9;
        }
        if (result > totalNumOfPages) {
            result = totalNumOfPages;
        }
        return result;
    }

    private getTotalResultsLabel(): HTMLTemplateResult {
        if (!showTotalResultsLabel) return html``;
        let text;
        const rangeText = this.getRangeTextForPage();
        if (this.numResults === 1) {
            text = `Showing ${this.numResults} result`;
        } else if (this.numResults === -1) {
            text = `Showing ${rangeText} of many results`;
        } else {
            text = `Showing ${rangeText} of ${this.numResults} results`;
        }
        if (text) {
            return html`
                <span class="pagination-text" style="vertical-align: middle">${text}</span>
            `;
        } else {
            return html``;
        }
    }

    private getRangeTextForPage(): string {
        const firstResultOnPageNumber = this.pageOffset * this.pageSize + 1;
        let lastResultOnPageNumber = (this.pageOffset + 1) * this.pageSize;
        if (this.numResults !== -1 && lastResultOnPageNumber > this.numResults) lastResultOnPageNumber = this.numResults;
        return firstResultOnPageNumber + " to " + lastResultOnPageNumber;
    }
}
