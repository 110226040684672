import { html, render, HTMLTemplateResult } from 'lit-html';
import { DataSet } from 'vis-data';
import { Network, IdType } from 'vis-network';
import { DigitalObject } from '@cnri/doip-client';
import { ref } from 'lit-html/directives/ref.js';
import { ObjectPreviewUtil } from '../ObjectPreviewUtil.js';
import { SchemaExtractorFactory } from '../cordra/SchemaExtractor.js';
import { SchemaUtil } from '../cordra/SchemaUtil.js';
import { JsonUtil } from '../cordra/JsonUtil.js';

interface NetworkNode {
    id: string;
    label?: string;
    level?: number;
    color?: { background: string };
    searchResult?: DigitalObject;
    y?: number;
    allowedToMoveY?: boolean;
}

interface NetworkEdge {
    id: string;
    from: string;
    to: string;
    style: string;
    jsonPointer: string;
    level?: number;
    label?: string;
}

export interface RelationshipResponse {
    nodes: NetworkNode[];
    edges: NetworkEdge[];
    results: Record<string, DigitalObject>;
}

export class RelationshipsGraphComponent {
    private readonly container: HTMLElement;
    private readonly objectId?: string;
    private readonly template: (b: boolean, doSearch?: (e: Event) => void) => HTMLTemplateResult;
    private readonly resizeHandler: () => void;

    private nodes?: DataSet<NetworkNode>;
    private edges?: DataSet<NetworkEdge>;
    private network?: Network;

    private canvasDiv?: HTMLElement;
    private isBig: boolean = false;

    private existingEdges: Record<string, boolean> = {};
    private existingNodes: Record<string, NetworkNode> = {};

    private currentSelectedNode?: string;
    private selectedDetails?: HTMLElement;

    private outboundOnly: boolean = true;

    constructor(container: HTMLElement, objectId: string) {
        this.container = container;
        this.objectId = objectId;
        this.resizeHandler = this.resize.bind(this);
        this.template = (outboundOnly: boolean, doSearch?: (e: Event) => void) => {
            const link = html`
                <span">
                    <br/>
                    There are objects which refer to this object.
                    <a @click=${doSearch} href="#">Click here</a> to list them.
                </span>
            `;
            return html`
                <div class="action-tool-bar pull-right">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-clipboard-list"></i>Graph Actions...<span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li @click=${(e: Event) => this.inboundToggleButtonClick(e)}>
                                <a class="dropdown-item">
                                    <i class="fa fa-arrow-right"></i>
                                    Restart with ${outboundOnly ? 'inbound' : 'outbound'} links
                                </a>
                            </li>
                            <li @click=${(e: Event) => this.onFanOutSelectedClick(e)}>
                                <a class="dropdown-item"><i class="fa fa-compress"></i>Fan Out</a>
                            </li>
                            <li @click=${() => this.onFanOutAllClick()}>
                                <a class="dropdown-item"><i class="fa fa-expand-arrows-alt"></i>Fan Out All</a>
                            </li>
                            <li @click=${(e: Event) => this.deleteLastAddedItems(e)}>
                                <a class="dropdown-item"><i class="fa fa-compress-arrows-alt"></i>Undo Fan Out</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    Click and drag to manipulate graph. Double click to load object.
                    ${doSearch ? link : ''}
                </div>
                <div class="clearfix"></div>
                <div ${ref((el) => this.canvasDiv = el as HTMLElement)} style="height:730px; visibility: hidden"></div>
                <div ${ref((el) => this.selectedDetails = el as HTMLElement)} style="margin:5px 5px 0 10px"></div>
            `;
        };
        this.render().catch(this.onGotRelationshipsError);
    }

    private async render(): Promise<void> {
        if (!this.objectId || !this.container) return;
        const doSearch = await this.maybeGetSearchFunction();
        render(this.template(this.outboundOnly, doSearch), this.container);
        try {
            const response: RelationshipResponse = await APP.getRelationships(this.objectId, this.outboundOnly);
            this.addNewRelationshipsToGraph(response, 1);
        } catch (e) {
            this.onGotRelationshipsError(e);
        }
        window.addEventListener('resize', this.resizeHandler);
    }

    destroy(): void {
        if (this.network) this.network.destroy();
        window.removeEventListener('resize', this.resizeHandler);
    }

    private getCurrentTopLevel(): number {
        if (!this.nodes || !this.edges) return -1;
        let topLevel = 0;
        const nodeIds = this.nodes.getIds();
        for (const nodeId of nodeIds) {
            const node = this.nodes.get(nodeId) as NetworkNode;
            if (node.level && node.level > topLevel) {
                topLevel = node.level;
            }
        }

        const edgeIds = this.edges.getIds();
        for (const edgeId of edgeIds) {
            const edge = this.edges.get(edgeId) as NetworkEdge;
            if (edge.level && edge.level > topLevel) {
                topLevel = edge.level;
            }
        }
        return topLevel;
    }

    private buildNetworkDynamicLayout(): void {
        if (!this.nodes || !this.edges || !this.canvasDiv) return;
        this.nodes.add({ id: "fakeNode" });
        const data = {
            nodes: this.nodes,
            edges: this.edges
        };
        const options = {
            physics: {
                barnesHut: {
                    gravitationalConstant: -4250,
                    centralGravity: 0.05,
                    springConstant: 0.002,
                    springLength: 500
                },
                stabilization: {
                    iterations: 1500
                }
            },
            nodes: {
                shape: "box"
            },
            edges: {
                arrows: "to",
                length: 500
            }
        };

        const network = new Network(this.canvasDiv, data, options);
        network.on("select", (props: { nodes: string[] }) => this.onSelect(props));
        network.on("doubleClick", (props: { nodes: string[] }) => this.doubleClick(props));
        network.selectNodes([this.objectId!]);
        this.currentSelectedNode = this.objectId!;
        this.displaySelectedNodeData(this.currentSelectedNode);

        network.on("stabilized", () => {
            if (this.canvasDiv) this.canvasDiv.style.visibility = "visible";
        });
        this.network = network;

        setTimeout(() => {
            this.nodes?.remove(["fakeNode"]);
        }, 1);

        this.animatedZoomExtent();
    }

    private animatedZoomExtent(): void {
        const intervalId = setInterval(() => {
            this.network?.fit();
        }, 1000 / 60);

        this.network?.once("stabilized", () => {
            clearInterval(intervalId);
            this.network?.fit({ animation: { duration: 200, easingFunction: 'linear' } });
        });

        setTimeout(() => {
            clearInterval(intervalId);
        }, 5000);
    }

    private addNewRelationshipsToGraph(res: RelationshipResponse, requestedLevel: number, zoomExtent: boolean = false): void {
        const nodesToAdd = [];
        for (const node of res.nodes) {
            if (this.existingNodes[node.id]) {
                continue;
            }
            this.existingNodes[node.id] = node;
            if (node.id === this.objectId) {
                node.level = 0;
            } else {
                node.level = requestedLevel;
            }

            nodesToAdd.push(node);
            if (node.id === this.objectId) {
                node.color = { background: "Silver" };
            }
            const searchResult = res.results[node.id];
            node.searchResult = searchResult;
            if (searchResult != null) {
                this.addPreviewDataToNode(node, searchResult);
            }
        }

        this.addLabelsToEdges(res.edges, res.results);

        if (!this.network || !this.edges || !this.nodes) {
            this.nodes = new DataSet();
            this.edges = new DataSet();
            if (res.nodes.length === 2) {
                this.setInitialPositionForNodes(res.nodes);
            }
            this.nodes.add(nodesToAdd);
            this.addEdges(res.edges, requestedLevel);
            this.buildNetworkDynamicLayout();
        } else {
            this.nodes.add(nodesToAdd);
            this.addEdges(res.edges, requestedLevel);
            if (zoomExtent) {
                this.animatedZoomExtent();
            }
        }
    }

    private doubleClick(properties: { nodes: string[] }): void {
        const selectedNodes = properties.nodes;
        if (selectedNodes.length > 0) {
            const firstSelectedNodeId = selectedNodes[0];
            APP.resolveHandle(firstSelectedNodeId);
        }
    }

    private inboundToggleButtonClick(ev: Event): void {
        ev.target?.dispatchEvent(new Event("blur"));
        this.outboundOnly = !this.outboundOnly;
        render(this.template(this.outboundOnly), this.container);
        this.resetNetworkByPrune().catch(console.error);
    }

    private async resetNetworkByPrune(): Promise<void> {
        this.pruneBackToLevel1OutboundOnly();

        if (!this.outboundOnly) {
            try {
                // get relationships for the root node
                const response: RelationshipResponse = await APP.getRelationships(this.objectId!, this.outboundOnly);
                this.addNewRelationshipsToGraph(response, 1, true);
            } catch (e) {
                this.onGotRelationshipsError(e);
            }
        }
    }

    private deleteLastAddedItems(ev: Event): void {
        if (!this.nodes || !this.edges) return;
        ev.target?.dispatchEvent(new Event("blur"));
        const currentTopLevel = this.getCurrentTopLevel();
        if (currentTopLevel === 1) return;

        const nodeIds = this.nodes.getIds();
        const nodesToDelete = [];

        for (const nodeId of nodeIds) {
            const node = this.nodes.get(nodeId) as NetworkNode;
            if (node.level === currentTopLevel) {
                nodesToDelete.push(nodeId);
            }
        }

        const edgeIds = this.edges.getIds();
        const edgesToDelete = [];

        for (const edgeId of edgeIds) {
            const edge = this.edges.get(edgeId) as NetworkEdge;
            if (edge.level === currentTopLevel) {
                edgesToDelete.push(edgeId);
            }
        }

        this.removeNodes(nodesToDelete);
        this.removeEdges(edgesToDelete);
    }

    // get the level 0 node
    // find its outbound links
    // find the nodes those links point at.
    // delete all nodes and links not in the above
    private pruneBackToLevel1OutboundOnly(): void {
        if (!this.nodes || !this.edges || !this.objectId) return;
        const rootObjectId = this.objectId;
        const rootNode: NetworkNode = this.nodes.get(rootObjectId)!;
        const nodesToKeep: NetworkNode[] = [];
        const edgesToDelete: IdType[] = [];
        const nodesToDelete: IdType[] = [];

        nodesToKeep.push(rootNode);

        const edgeIds = this.edges.getIds();
        for (const edgeId of edgeIds) {
            const edge = this.edges.get(edgeId)!;
            if (edge.from === rootObjectId) {
                const nodeToKeep: NetworkNode | null = this.nodes.get(edge.to);
                if (nodeToKeep) nodesToKeep.push(nodeToKeep);
            } else {
                edgesToDelete.push(edgeId);
            }
        }

        const nodeIds = this.nodes.getIds();
        for (const nodeId of nodeIds) {
            const node: NetworkNode = this.nodes.get(nodeId)!;
            if (!nodesToKeep.includes(node)) {
                nodesToDelete.push(nodeId);
            }
        }

        this.removeNodes(nodesToDelete);
        this.removeEdges(edgesToDelete);
    }

    private removeEdges(edgeIds: IdType[]): void {
        this.edges?.remove(edgeIds);
        for (const edgeId of edgeIds) {
            // delete edgeId.id;
            // delete edgeId.level; // level not part of edgeName
            // const edgeName = JSON.stringify(edgeId);
            delete this.existingEdges[edgeId];
        }
    }

    private removeNodes(nodeIds: IdType[]): void {
        this.nodes?.remove(nodeIds);
        for (const nodeId of nodeIds) {
            if (nodeId === this.currentSelectedNode) delete this.currentSelectedNode;
            delete this.existingNodes[nodeId];
        }
    }

    private setInitialPositionForNodes(nodes: NetworkNode[]): void {
        nodes[0].y = 200;
        nodes[0].allowedToMoveY = true;
        nodes[1].y = 600;
        nodes[1].allowedToMoveY = true;
    }

    private addLabelsToEdges(edges: NetworkEdge[], searchResultsMap: Record<string, DigitalObject>): void {
        for (const edge of edges) {
            const fromId = edge.from;
            const fromSearchResult = searchResultsMap[fromId];
            if (fromSearchResult != null) {
                this.addLabelToEdge(edge, fromSearchResult);
            }
        }
    }

    private getContent(digitalObject: DigitalObject): any {
        return digitalObject.attributes.content;
    }

    private addLabelToEdge(edge: NetworkEdge, fromSearchResult: DigitalObject): void {
        const jsonPointer = edge.jsonPointer;
        const schema = APP.getSchema(fromSearchResult.type!);
        if (!schema) return;
        const content = this.getContent(fromSearchResult);
        const pointerToSchemaMap = SchemaExtractorFactory.get().extract(
            content,
            schema
        );
        const subSchema = pointerToSchemaMap[jsonPointer];
        if (subSchema === undefined) return;
        const handleReferenceNode = SchemaUtil.getDeepCordraSchemaProperty(
            subSchema,
            "type",
            "handleReference"
        ) as {
            types: string[];
            prepend?: string;
            prependHandleMintingConfigPrefix?: string;
            name?: string;
        };
        if (!handleReferenceNode) return;
        const handleReferenceType = handleReferenceNode.types;
        if (!handleReferenceType) return;
        let idPointedToByReference = JsonUtil.getJsonAtPointer(
            content as object,
            jsonPointer
        );
        let handleReferencePrepend = handleReferenceNode.prepend;
        if (!handleReferencePrepend && handleReferenceNode.prependHandleMintingConfigPrefix) {
            const prefix = APP.getPrefix();
            if (prefix) handleReferencePrepend = this.ensureSlash(prefix);
        }
        if (handleReferencePrepend) {
            idPointedToByReference =
                handleReferencePrepend + idPointedToByReference;
        }
        if (idPointedToByReference !== edge.to) return;

        const handleReferenceName = handleReferenceNode.name;
        if (!handleReferenceName) {
            edge.label = jsonPointer;
        } else if (handleReferenceName.startsWith("{{") && handleReferenceName.endsWith("}}")) {
            const expression = handleReferenceName.substring(2, handleReferenceName.length - 4);
            const label = this.getValueForExpression(
                jsonPointer,
                expression,
                content as object
            );
            if (label && label !== "") {
                edge.label = label;
            }
        } else {
            edge.label = handleReferenceName;
        }
    }

    private ensureSlash(prefix: string): string {
        if (prefix.length === 0) return "/";
        if (prefix.substring(prefix.length - 1) === "/") {
            return prefix;
        }
        return prefix + "/";
    }

    private getValueForExpression(jsonPointer: string, expression: string, jsonObject: object): string | undefined {
        let result;
        const segments = jsonPointer.split("/").slice(1);
        if (expression.startsWith("/")) {
            // treat the expression as a jsonPointer starting at the root
            result = JsonUtil.getJsonAtPointer(jsonObject, expression);
        } else if (expression.startsWith("..")) {
            const segmentsFromRelativeExpression = expression.split("/").slice(1);
            segments.pop();
            const combinedSegments = segments.concat(segmentsFromRelativeExpression);
            const jsonPointerFromExpression = this.getJsonPointerFromSegments(combinedSegments);
            result = JsonUtil.getJsonAtPointer(
                jsonObject,
                jsonPointerFromExpression
            );
        } else {
            // consider the expression to be a jsonPointer starting at the current jsonPointer
            const targetPointer = jsonPointer + "/" + expression;
            result = JsonUtil.getJsonAtPointer(jsonObject, targetPointer);
        }

        if (result && typeof result !== "string") {
            result = JSON.stringify(result);
        }
        return result as string | undefined;
    }

    private getJsonPointerFromSegments(segments: string[]): string {
        return segments
            .map(JsonUtil.encodeJsonPointerSegment)
            .join('/');
        // let jsonPointer = "";
        // for (const segment of segments) {
        //     const encodedSegment = JsonUtil.encodeJsonPointerSegment(segment);
        //     jsonPointer = jsonPointer + "/" + encodedSegment;
        // }
        // return jsonPointer;
    }

    private addPreviewDataToNode(node: NetworkNode, searchResult: DigitalObject): void {
        let nodeId = node.id;
        if (nodeId.length > 30) {
            nodeId = nodeId.substring(0, 30) + "...";
        }
        node.label = nodeId;
        const previewData = ObjectPreviewUtil.getPreviewData(searchResult);
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            if (thisPreviewData.isPrimary) {
                const prettifiedPreviewData = ObjectPreviewUtil.prettifyPreviewJson(
                    thisPreviewData.previewJson,
                    20
                );
                if (!prettifiedPreviewData) continue;
                node.label += `\n${thisPreviewData.title}: ${prettifiedPreviewData}`;
            }
        }

        // If there are multiple schemas in Cordra include the type
        if (APP.getSchemaCount() > 1) {
            const schema = APP.getSchema(searchResult.type!);
            let typeTitle = searchResult.type;
            if (schema && schema.title) {
                typeTitle = schema.title;
            }
            if (typeTitle) node.label += `\nType: ${typeTitle}`;
        }
    }

    private addEdges(edgesToAdd: NetworkEdge[], requestedLevel: number): boolean {
        if (!this.edges) return false;
        let added = false;
        for (const edge of edgesToAdd) {
            const edgeName = JSON.stringify(edge);
            if (!this.existingEdges[edgeName]) {
                this.existingEdges[edgeName] = true;
                edge.level = requestedLevel;
                this.edges.add(edge);
                added = true;
            }
        }
        return added;
    }

    private async onFanOutAllClick(): Promise<void> {
        if (!this.nodes || !this.edges) return;
        const requestedLevel = this.getCurrentTopLevel() + 1;
        for (const nodeId in this.existingNodes) {
            try {
                const response: RelationshipResponse = await APP.getRelationships(nodeId, this.outboundOnly);
                this.addNewRelationshipsToGraph(response, requestedLevel);
            } catch (e) {
                this.onGotRelationshipsError(e);
            }
        }
    }

    private onSelect(properties: { nodes: string[] }): void {
        const selectedNodes = properties.nodes;
        if (selectedNodes.length > 0) {
            this.currentSelectedNode = selectedNodes[0];
            this.displaySelectedNodeData(this.currentSelectedNode);
        } else {
            this.selectedDetails!.innerHTML = '<ul class="graph-selected-node-preview"></ul>';
        }
    }

    private displaySelectedNodeData(selectedNodeId: string): void {
        const node = this.existingNodes[selectedNodeId];
        const searchResult = node.searchResult!;
        const previewData = ObjectPreviewUtil.getPreviewData(searchResult);
        const ul = $('<ul class="graph-selected-node-preview"></ul>');
        let placedId = false;
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            const prettifiedPreviewData = ObjectPreviewUtil.prettifyPreviewJson(
                thisPreviewData.previewJson
            );
            if (!prettifiedPreviewData) continue;
            let nodeDetails = $("<li/>");
            if (thisPreviewData.isPrimary) {
                const b = $("<b/>");
                nodeDetails.append(b);
                nodeDetails = b;
            }
            if (thisPreviewData.excludeTitle) {
                nodeDetails.text(prettifiedPreviewData);
            } else {
                nodeDetails.text(
                    thisPreviewData.title + ": " + prettifiedPreviewData
                );
            }
            if (thisPreviewData.isPrimary && !placedId) {
                ul.prepend($("<li/>").text("Id: " + searchResult.id));
                ul.prepend(nodeDetails);
                placedId = true;
            } else {
                ul.append(nodeDetails);
            }
        }
        if (!placedId) {
            ul.prepend($("<li/>").append($("<b/>").text("Id: " + searchResult.id)));
        }
        $(this.selectedDetails!).empty().append(ul);
        this.resize(false);
    }

    private async onFanOutSelectedClick(ev: Event): Promise<void> {
        if (!this.nodes || !this.edges) return;
        ev.target?.dispatchEvent(new Event("blur"));
        if (this.currentSelectedNode != null) {
            try {
                const requestedLevel = this.getCurrentTopLevel() + 1;
                const response: RelationshipResponse = await APP.getRelationships(this.currentSelectedNode, this.outboundOnly);
                this.addNewRelationshipsToGraph(response, requestedLevel);
            } catch (e) {
                this.onGotRelationshipsError(e);
            }
        }
    }

    private resize(force: boolean = false): void {
        if (!this.network || !this.canvasDiv) return;
        const canvas = $(this.canvasDiv);
        if (this.isBig) {
            canvas.height(
                $(this).height()! - $(this.selectedDetails!).outerHeight(true)! - 52
            );
        }
        if (this.isBig || force) {
            this.network.setSize(canvas.width()!.toString(), canvas.height()!.toString());
            this.network.redraw();
        }
    }

    private onGotRelationshipsError(res: unknown): void {
        console.error(res);
    }

    private async maybeGetSearchFunction(): Promise<((e: Event) => void) | undefined> {
        if (!this.objectId) return undefined;
        const referrersQuery = "internal.pointsAt:" + this.objectId;
        try {
            const response = await APP.doipClient.search(referrersQuery, {
                pageNum: 0,
                pageSize: 1
            });
            if (response.size !== 0) {
                return (e: Event) => {
                    e.preventDefault();
                    APP.performSearchWidgetSearch(referrersQuery);
                };
            }
        } catch (e) {
            APP.onErrorResponse(e);
        }
        return undefined;
    }
}
