import { html, HTMLTemplateResult, render } from 'lit-html';
import type { CordraObject } from "@cnri/cordra-client";
import { DigitalObject } from '@cnri/doip-client';
import { ObjectConvertUtil } from "../cordra/ObjectConvertUtil.js";

export class BatchUpload {

    private readonly container: HTMLElement;
    private disabled: boolean;
    private fileName: string = "";
    private isUploading: boolean = false;

    constructor(container: HTMLElement, disabled: boolean) {
        this.container = container;
        this.disabled = disabled;
        this.render();
    }

    render(): void {
        const template = this.buildTemplate();
        render(template, this.container);
    }

    buildTemplate(): HTMLTemplateResult {
        if (this.disabled) {
            return html`
                    <div class="row object-header">
                        <div class="heading col-md-6">
                            <h3 class="editorTitle">Batch Upload</h3>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <p>You need to be authenticated as admin in order to use batch upload.</p>
                    </div>
               `;
        } else {
            return this.getEnabledTemplate();
        }
    }

    getEnabledTemplate() : HTMLTemplateResult {
        const uploading = this.getMaybeUploadingGif(this.isUploading);
        return html`
            <div class="row object-header">
                <div class="heading col-md-6">
                    <h3 class="editorTitle">Batch Upload</h3>
                </div>
            </div>
            <div>
                <form class="form-horizontal" role="form">
                    <div class="form-group">
                        <label class="col-sm-2 control-label">File</label>
                        <div class="col-sm-10">
                            <p class="form-control uploadFileName">${this.fileName}</p>
                            <label class="btn btn-sm btn-primary">Browse...
                                <input class="fileToLoad" @change=${() => this.onFileSelectChange()} type="file" style="display: none;">
                            </label>
                            <button ?disabled=${this.isUploading} @click=${() => this.onBatchUploadButtonClick()} class="btn btn-sm btn-primary" type="button">
                                <i class="fa fa-file"></i>
                                <span>Upload</span>
                            </button>
                            ${uploading}
                        </div>
                    </div>
                </form>
                <div class="object-editor-toolbar col-md-6 pull-right">
                </div>
            </div>
        `;
    }

    getMaybeUploadingGif(isUploading: boolean): HTMLTemplateResult {
        if (isUploading) {
            return html`<img class="objectLoadingGif" src="./img/load.gif" style="padding-left: 5px;"  alt="loading"/>`;
        } else {
            return html`<img class="objectLoadingGif" src="./img/load.gif" style="padding-left: 5px; display:none"  alt="loading"/>`;
        }
    }

    onFileSelectChange(): void {
        const fileToLoad: HTMLInputElement = this.getFileToLoad();
        const files = fileToLoad.files;
        if (files) {
            const file = files[0];
            if (file) {
                this.fileName = file.name;
            } else {
                this.fileName = "";
            }
        } else {
            this.fileName = "";
        }
        this.render();
    }

    getFileToLoad(): HTMLInputElement {
        const fileToLoad = (this.container?.getElementsByClassName("fileToLoad")[0]) as HTMLInputElement;
        return fileToLoad;
    }

    getUploadFileTextElement(): Element {
        const uploadFileName = (this.container?.getElementsByClassName("uploadFileName")[0]);
        return uploadFileName;
    }

    onBatchUploadButtonClick(): void {
        const fileToLoad: HTMLInputElement = this.getFileToLoad();
        const files = fileToLoad.files;
        if (!files || !files[0]) {
            APP.notifications.alertError(
                'Please select a file before clicking the "Load" button.'
            );
        } else {
            const file = files[0];
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                let objects: any = JSON.parse(reader.result as string);
                if (objects.results) {
                    objects = objects.results;
                }
                let digitalObjects: DigitalObject[];
                if (this.isCordraObjects(objects)) {
                    digitalObjects = ObjectConvertUtil.cordraObjectsToDigitalObjects(objects as CordraObject[]);
                } else {
                    digitalObjects = objects;
                }
                this.sortSchemasFirstForLoadObjects(digitalObjects);
                APP.loadObjects(digitalObjects, () => this.onBatchUploadDone(), () => this.onBatchUploadDone());
                this.isUploading = true;
                this.render();
            };
        }
    }

    onBatchUploadDone(): void {
        this.isUploading = false;
        const fileToLoad: HTMLInputElement = this.getFileToLoad();
        fileToLoad.value = "";
        this.render();
    }

    sortSchemasFirstForLoadObjects(digitalObjects: DigitalObject[]): void {
        digitalObjects.sort((a: DigitalObject, b: DigitalObject) => {
            if (a.type === "Schema" && b.type !== "Schema") {
                return -1;
            }
            if (b.type === "Schema" && a.type !== "Schema") {
                return 1;
            }
            return 0;
        });
    }

    isCordraObjects(objects: any[]): boolean {
        if (objects.length > 0) {
            const first = objects[0];
            if (first.content) {
                return true;
            }
        }
        return false;
    }

    enable(): void {
        this.disabled = false;
        this.render();
    }

    disable(): void {
        this.disabled = true;
        this.render();
    }
}
