import { html, HTMLTemplateResult, render} from 'lit-html';
import { ref, Ref } from 'lit-html/directives/ref.js';

import { ModalYesNoDialog } from '../cordra/ModalYesNoDialog.js';

export class BatchDelete {

    private readonly container: HTMLElement;
    private mode: string = "Query"; //All or Query or IDs
    private disabled: boolean;

    private queryInputRef: Ref<HTMLInputElement> = {};
    private idsInputRef: Ref<HTMLInputElement> = {};
    private parallelCheckboxRef: Ref<HTMLInputElement> = {};

    constructor(container: HTMLElement, disabled: boolean) {
        this.container = container;
        this.disabled = disabled;
        this.render();
    }


    render(): void {
        const template = this.buildTemplate();
        render(template, this.container);
    }

    buildTemplate(): HTMLTemplateResult {
        if (this.disabled) {
            return html`
                    <div class="row object-header">
                        <div class="heading col-md-6">
                            <h3 class="editorTitle">Batch Delete</h3>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <p>You need to be authenticated as admin in order to use batch delete.</p>
                    </div>
               `;
        } else {
            return this.getEnabledTemplate();
        }
    }

    getEnabledTemplate() : HTMLTemplateResult {
        return html`
            <div class="row object-header">
                <div class="heading col-md-6">
                    <h3 class="editorTitle">Batch Delete</h3>
                </div>
                <div class="object-editor-toolbar col-md-6 pull-right">
                    <button @click=${() => this.onBatchDeleteButtonClick()} class="btn btn-sm btn-primary" type="button">
                        <i class="fa fa-trash"></i>
                        <span>Batch Delete</span>
                    </button>
                </div>
            </div>
            <div class="col-md-12">
                <form class="form-horizontal col-md-12">
                    <div class="form-group">
                        <label for="modeSelect" class="col-sm-2 control-label">Mode</label>
                        <div class="col-sm-10">
                            <select @change=${(e: Event) => this.onModeChange(e)} class="form-control batchDeleteModeSelect" id="batchDeleteModeSelect">
                                <option value="Query">Query</option>
                                <option value="IDs">IDs</option>
                                <option value="All">All</option>
                            </select>
                        </div>
                    </div>
                    ${this.getQueryInputTemplate(this.mode)}
                    ${this.getIdsInputTemplate(this.mode)}
                    <div class="form-group">
                        <div class="col-sm-offset-2 col-sm-10">
                            <div class="checkbox">
                                <label>
                                    <input ${ref(this.parallelCheckboxRef)} type="checkbox" class="batchDeleteInParallel"> Delete in parallel
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        `;
    }

    getQueryInputTemplate(mode: string): HTMLTemplateResult {
        let queryInputTemplate;
        if (mode === "Query") {
            queryInputTemplate = html`
                <div class="form-group">
                    <label for="batchDeleteQueryInput" class="col-sm-2 control-label">Query</label>
                    <div class="col-sm-10">
                        <input ${ref(this.queryInputRef)} class="form-control batchDeleteQueryInput"></input>
                        <p>Enter a query to match the objects you want to delete.<p/>
                    </div>

                </div>
            `;
        } else {
            queryInputTemplate = html`
               <div>
               </div>
            `;
        }
        return queryInputTemplate;
    }

    getIdsInputTemplate(mode: string): HTMLTemplateResult {
        let idsInputTemplate;
        if (mode === "IDs") {
            idsInputTemplate = html`
                <div class="form-group">
                    <label for="batchDeleteIdsInput" class="col-sm-2 control-label">IDs</label>
                    <div class="col-sm-10">
                        <textarea ${ref(this.idsInputRef)} class="form-control batchDeleteIdsInput"></textarea>
                        <p>Enter a JSON array of the object IDs you want to delete.<p/>
                </div>
            `;
        } else {
            idsInputTemplate = html`
               <div>
               </div>
            `;
        }
        return idsInputTemplate;
    }

    onModeChange(e: Event): void {
        const select = (this.container?.getElementsByClassName("batchDeleteModeSelect")[0]) as HTMLInputElement;
        this.mode = select.value;
        this.render();
    }

    async onBatchDeleteButtonClick(): Promise<void> {
        let all: boolean = false;
        let query: string | undefined = undefined;
        const parallel = this.getParallel();
        let ids: string[] | undefined = undefined;
        if (this.mode === "Query") {
            query = this.getQuery();
            if (query === "") {
                APP.notifications.alertError("The empty string is not a valid query");
                return;
            }
        } else if (this.mode === "IDs") {
            try {
                ids = this.getIds();
                if (!Array.isArray(ids)) {
                    APP.notifications.alertError("The JSON you entered is not an array.");
                    return;
                }
            } catch (e) {
                APP.notifications.alertError("Unable to parse IDs JSON.");
                return;
            }
        } else {
            all = true;
        }
        try {
            const result = await APP.performBatchDelete(all, query, ids, false, true);
            const confirmMessage = 'This batch delete will attempt to delete ' + result.count + ' objects. Are you sure you want to do this?';
            const dialog = new ModalYesNoDialog(
                confirmMessage,
                (() => {
                    this.yesDeleteCallback(all, query, ids, parallel).catch(console.error);
                }),
                () => this.noDeleteCallback()
            );
            dialog.show();
        } catch (e) {
            APP.onErrorResponse(e);
        }
    }

    async yesDeleteCallback(all: boolean, query: string | undefined, ids: string[] | undefined, parallel: boolean): Promise<void> {
        try {
            const dryRun = false;
            const result = await APP.performBatchDelete(all, query, ids, parallel, dryRun);
            const resultMessage = this.buildResultMessage(result);
            APP.notifications.alertSuccess(resultMessage);
        } catch (e) {
            APP.onErrorResponse(e);
        }
        this.render();
    }

    noDeleteCallback(): void {
        APP.notifications.alertInfo("No objects were deleted.");
    }

    buildResultMessage(result: any): string {
        if (result.count === 1) {
            return "1 object deleted";
        } else {
            return result.count + " objects deleted";
        }
    }

    getQuery(): string {
        const queryInput = this.queryInputRef.value!;
        const query = queryInput.value;
        return query;
    }

    getIds(): string[] {
        const idsInput = this.idsInputRef.value!;
        const idsJson = idsInput.value;
        return JSON.parse(idsJson);
    }

    getParallel(): boolean {
        const parallelCheckbox = this.parallelCheckboxRef.value!;
        const parallel = parallelCheckbox.checked;
        return parallel;
    }

    enable(): void {
        this.disabled = false;
        this.render();
    }

    disable(): void {
        this.disabled = true;
        this.render();
    }
}
