import { html, render, HTMLTemplateResult } from 'lit-html';
import { Bucket } from '@cnri/doip-client';
import { FacetFieldConfig } from '../../cordra/CordraUiMain.js';

export type FacetBucketWithUi = Bucket & { displayName?: string };

export class FacetOptionComponent {
    private readonly container: HTMLElement | undefined;
    private readonly fieldConfig: FacetFieldConfig;
    private readonly getQueryCallback: (() => string | undefined) | undefined;
    private readonly template: (buckets: FacetBucketWithUi[]) => HTMLTemplateResult;

    constructor(
            container: Element | undefined,
            fieldConfig: FacetFieldConfig,
            types: string[],
            filters: string[],
            getQueryCallback: (() => (string | undefined)) | undefined
    ) {
        this.container = container as HTMLElement | undefined;
        this.fieldConfig = fieldConfig;
        this.getQueryCallback = getQueryCallback;
        this.template = (buckets: FacetBucketWithUi[]) => {
            const bucketTemplate = (bucket: FacetBucketWithUi) => {
                const bucketName = bucket.displayName ?? bucket.value
                return html`
                    <label>
                        <input type="checkbox" value="${bucket.filterQuery}">
                        ${bucketName} (${bucket.count})
                    </label>
                `;
            };
            const boxes = buckets.length > 0
                ? html`
                    <fieldset>
                        ${buckets.map(bucketTemplate)}
                    </fieldset>
                `
                : html`<p>No buckets</p>`;
            return html`
                <div ?hidden="${!this.fieldConfig}">
                    <label>
                        ${this.fieldConfig?.displayName || this.fieldConfig?.path || 'error'}
                        ${boxes}
                    </label>
                </div>
            `;
        };
        this.render(types, filters).catch(console.error);
    }

    async render(types: string[], filters: string[]): Promise<void> {
        if (!this.container) return ;
        const buckets = await this.getFacetBuckets(types);
        render(this.template(buckets), this.container);
        this.updateFilters(filters);
    }

    async getFacetBuckets(types: string[] = []): Promise<FacetBucketWithUi[]> {
        if (!this.fieldConfig || !this.getQueryCallback) return [];
        let query = this.getQueryCallback() ?? '*:*';
        if (types.length > 0) {
            query += ` +type:("${types.join('" "')}")`;
        }
        return APP.getFacetBucketsForField(this.fieldConfig.path, query);
    }

    updateFilters(filters: string[] = []): void {
        if (!this.fieldConfig || !this.container) return;
        const inputs = this.container.querySelectorAll(`input`);
        inputs.forEach(i => {
            i.checked = filters.filter(f => f.includes(i.value)).length > 0;
        });
    }
}
