import { html, render, HTMLTemplateResult } from 'lit-html';

export class TypeSelectComponent {
    private readonly container: HTMLElement | undefined;
    private readonly typeChangeCallback: (types: string[]) => void;
    private readonly typeOptions: string[];
    private readonly selectedTypes: string[];
    private readonly template: (options: string[]) => HTMLTemplateResult;

    constructor(
            container: Element | undefined,
            typeChangeCallback: (types: string[]) => void,
            typeOptions: string[] = [],
            selectedTypes: string[] = []
    ) {
        this.container = container as HTMLElement | undefined;
        this.typeChangeCallback = typeChangeCallback;
        this.typeOptions = typeOptions;
        this.selectedTypes = selectedTypes;
        this.template = (options: string[]) => {
            return html`
                <div class="col-md-12 nopadding">
                    <form class="form-inline" role="form" @change=${() => this.notifyTypesChanged()}>
                        Type(s):
                        <a style="cursor: pointer" @click=${() => this.toggleAll(true)}>Select all</a>
                        <a style="cursor: pointer" @click=${() => this.toggleAll(false)}>Clear all</a>
                        <fieldset>
                            ${options.map(
                                (type) => html`
                                <label>
                                    <input type="checkbox" value="${type}">
                                    ${type}
                                </label>`
                            )}
                        </fieldset>
                    </form>
                </div>
            `;
        };
        this.render();
    }

    private render(): void {
        if (!this.container) return;
        render(this.template(this.typeOptions), this.container);
        this.updateTypes();
    }

    toggleAll(selected: boolean): void {
        const checkboxes = this.container?.getElementsByTagName('input') || [];
        for (const checkbox of checkboxes) {
            checkbox.checked = selected;
        }
        this.notifyTypesChanged();
    }

    private notifyTypesChanged() {
        const checkboxes = this.container?.getElementsByTagName('input') || [];
        const selectedTypes = [];
        for (const checkbox of checkboxes) {
            if (checkbox.checked && checkbox.value) {
                selectedTypes.push(checkbox.value);
            }
        }
        this.typeChangeCallback(selectedTypes);
    }

    private updateTypes(): void {
        if (!this.container) return;
        const inputs = this.container.querySelectorAll(`input`);
        inputs.forEach(i => {
            i.checked = this.selectedTypes.filter(t => t === i.value).length > 0;
        });
    }
}
