import { DigitalObject, DoipConstants } from '@cnri/doip-client';
import { html, HTMLTemplateResult, render } from 'lit-html';
import { createRef, ref } from 'lit-html/directives/ref.js';
import { ObjectPreviewUtil, PreviewData } from '../../ObjectPreviewUtil.js';

interface SearchResultConfig {
    includeType?: boolean;
    includeModifiedDate?: boolean;
    includeCreatedDate?: boolean;
    includePayloadLinks?: boolean;
}

export class SearchResultComponent {
    private readonly container: HTMLElement | undefined;
    private readonly digitalObject: DigitalObject;
    private readonly searchResultsConfig: SearchResultConfig;

    constructor(container: Element | undefined, digitalObject: DigitalObject) {
        this.container = container as HTMLElement | undefined;
        this.digitalObject = digitalObject;
        this.searchResultsConfig = APP.getUiConfig()?.searchResults || {};
        this.render();
    }

    render(): void {
        if (!this.digitalObject || !this.container) return;
        const previewData = this.getPreviewData(this.digitalObject);
        const headerLinkText = this.getHeaderLinkText(previewData) || this.digitalObject.id!;
        const details = this.getDetailsHtml(this.digitalObject, previewData, this.searchResultsConfig);
        const metadata = this.getMetadataHtml(this.digitalObject.attributes.metadata!, this.searchResultsConfig);
        const payloadLinks = this.getPayloadLinksHtml(this.digitalObject, this.searchResultsConfig);
        const template = html`
            <div class="search-result" @click=${(e: Event) => this.onHandleClick(e)}>
                <div class="row">
                    <div class="col-md-8">
                        <div>
                            <a style="display: block;font-size:18px"
                                target="_blank"
                                data-handle="${this.digitalObject.id}"
                                href="${`#objects/${this.digitalObject.id}`}">
                                    ${headerLinkText}
                            </a>
                        </div>
                        ${details}
                        ${payloadLinks}
                    </div>
                    ${metadata}
                </div>
            </div>
        `;
        render(template, this.container);
    }

    private getHeaderLinkText(previewData: Record<string, PreviewData>): string | undefined {
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            const prettifiedPreviewData = this.prettifyPreviewJson(thisPreviewData.previewJson);
            if (!prettifiedPreviewData) continue;
            if (thisPreviewData.isPrimary) {
                return prettifiedPreviewData;
            }
        }
        return undefined;
    }

    private getDetailsHtml(result: DigitalObject, previewData: Record<string, PreviewData>, searchResultsConfig: SearchResultConfig): HTMLTemplateResult {
        const typeSpan = searchResultsConfig.includeType
            ? html`
                <span style="display:block">
                    <i class="fa fa-file-alt"></i>
                    Type: ${result.type}
                </span>
            `
            : html``;
        const previewDataElements = [];
        let detailsText = result.id;
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            const prettifiedPreviewData = this.prettifyPreviewJson(thisPreviewData.previewJson);
            if (!prettifiedPreviewData) continue;
            if (thisPreviewData.isPrimary) continue; // header text is set elsewhere

            if (thisPreviewData.excludeTitle) {
                if (thisPreviewData.isUri) {
                    const link = html`
                        <a style="display:block" target="_blank" href="${prettifiedPreviewData}">
                            ${prettifiedPreviewData}
                        </a>
                    `;
                    previewDataElements.push(link);
                } else {
                    detailsText = prettifiedPreviewData;
                }
            } else if (thisPreviewData.isUri) {
                const link = html`
                    <a style="display:block" target="_blank" href="${prettifiedPreviewData}">
                        ${prettifiedPreviewData}
                    </a>
                `;
                detailsText = `${thisPreviewData.title}: `;
                previewDataElements.push(link);
            } else {
                const infoHeaderSpan = html`
                    <span style="display:block">${`${thisPreviewData.title}: ${prettifiedPreviewData}`}<span>
                `;
                previewDataElements.push(infoHeaderSpan);
            }
        }

        return html`
            <div class="details">
                <span style="display:block">
                    <i class="fa fa-bullseye"></i>
                    <a class="list-handles-link"
                        target="_blank"
                        href="${`#objects/${result.id}`}"
                        data-handle="${result.id}" >
                            ${detailsText}
                    </a>
                </span>
                ${typeSpan}
                ${previewDataElements}
            </div>
        `;
    }

    private getMetadataHtml(metadata: any, searchResultsConfig: SearchResultConfig): HTMLTemplateResult {
        const modifiedData = searchResultsConfig.includeModifiedDate
            ? html`
                <span style="display:block; float:right; padding-right: 10px;">
                    ${`Modified: ${this.toDateString(metadata.modifiedOn)}`}
                <span>
            `
            : html``;
        const createdData = searchResultsConfig.includeCreatedDate
            ? html`
                <span style="display:block; float:right; padding-right: 10px;">
                    ${`Created: ${this.toDateString(metadata.createdOn)}`}
                <span>
            `
            : html``;

        return html`
            <div class="metadata col-md-4">
                ${modifiedData}
                ${createdData}
            </div>
        `;
    }

    private getPayloadLinksHtml(result: DigitalObject, searchResultsConfig: SearchResultConfig): HTMLTemplateResult {
        const links = [];
        if (searchResultsConfig.includePayloadLinks && result.elements) {
            for (const payload of result.elements) {
                let filename: string | undefined;
                if (payload.attributes) {
                    const attributes: any = payload.attributes;
                    filename = attributes.filename;
                }
                const downloadButton = this.buildDownloadButton(filename, payload.id, result.id!);
                links.push(downloadButton);
            }
        }
        return html`<div>${links}</div>`;
    }

    private buildDownloadButton(filename: string | undefined, payloadName: string, objectId: string): HTMLTemplateResult {
        //TODO what to do about right click save?
        const uri = APP.getBaseUri() + "doip/?t=" +
            objectId +
            "&o=" + DoipConstants.OP_RETRIEVE +
            "&element=" +
            encodeURIComponent(payloadName).replace(/%2F/g, "/");
        const buttonText = filename || `Download: ${payloadName}`;
        const form = createRef<HTMLFormElement>();
        const accessTokenInput = createRef<HTMLInputElement>();
        const clickHandler = async (e: Event) => {
            e.preventDefault();
            const accessToken = await APP.getAccessToken();
            if (accessToken) accessTokenInput.value!.value = accessToken;
            form.value!.submit();
        };
        return html`
            <a href="#" @click=${(e: Event) => clickHandler(e)}>
                ${buttonText}
                <form ${ref(form)} style="display:block" method="POST" target="_blank" action="${uri}">
                    <input ${ref(accessTokenInput)} type="hidden" name="access_token"/>
                </form>
            </a>
        `;
    }

    private getPreviewData(digitalObject?: DigitalObject): Record<string, PreviewData> {
        if (!digitalObject) return {};
        return ObjectPreviewUtil.getPreviewData(digitalObject);
    }

    private prettifyPreviewJson(previewJson: unknown, maxLength?: number): string {
        let res;
        if (typeof previewJson === "string") {
            res = previewJson;
        } else {
            res = JSON.stringify(previewJson);
        }
        if (maxLength) {
            if (res.length > maxLength) {
                res = res.substring(0, maxLength) + "...";
            }
        }
        return res;
    }

    private toDateString(timestamp: number): string {
        return new Date(timestamp).toISOString();
    }

    private onHandleClick(e: Event): void {
        e.preventDefault();
        const handle = (e.target! as HTMLElement).dataset.handle;
        if (handle) APP.resolveHandle(handle);
    }
}
