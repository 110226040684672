import type { CordraObject } from "@cnri/cordra-client";
import { DigitalObject } from '@cnri/doip-client';
import { DivWithModal } from "../cordra/ModalYesNoDialog.js";
import { ObjectConvertUtil } from "../cordra/ObjectConvertUtil.js";
import ClickEvent = JQuery.ClickEvent;

export class SchemasToolBar {
    private readonly containerDiv: JQuery;
    private readonly createNewSchema: (objectType: string, template?: unknown, js?: string) => void;
    private readonly showSchemaEditorFor: (objectType: string, disabled: boolean) => void;

    private disabled: boolean;
    private schemas: Record<string, unknown>;
    private newSchemaObjectTypeInput!: JQuery<HTMLInputElement>;
    private addSchemaDiv!: DivWithModal;
    private templates!: Record<string, unknown>;
    private templateSelect!: JQuery<HTMLSelectElement>;
    private exampleJavaScript!: string;
    private jsCheckBox!: JQuery<HTMLInputElement>;
    private schemaToolBar!: JQuery;
    private schemasList!: JQuery;
    private schemasTotalHeader!: JQuery;

    constructor(
            containerDiv: JQuery,
            schemasParam: Record<string, unknown>,
            disabledParam: boolean,
            createNewSchema: (objectType: string, template?: unknown, js?: string) => void,
            showSchemaEditorFor: (objectType: string, disabled: boolean) => void
    ) {
        this.containerDiv = containerDiv;
        this.schemas = schemasParam;
        this.disabled = disabledParam;
        this.createNewSchema = createNewSchema;
        this.showSchemaEditorFor = showSchemaEditorFor;
        this.init().catch(e => console.error(e));
    }

    async init(): Promise<void> {
        const templateResponse = await fetch(APP.getBaseUri() + "schemaTemplates");
        const templateJson: { templates: Record<string, unknown>; exampleJavaScript: string } = await templateResponse.json();
        this.templates = templateJson.templates;
        this.exampleJavaScript = templateJson.exampleJavaScript;

        this.schemaToolBar = this.containerDiv.find("#schema-tool-bar");

        if (this.disabled) {
            this.schemaToolBar.hide();
        } else {
            this.schemaToolBar.show();
        }

        this.schemasTotalHeader = this.containerDiv.find("#schemas-total-header");

        this.schemasList = this.containerDiv.find("#schemas-list");
        this.buildSchemaAdder();
        // this.buildFileLoader();
        this.buildSchemasTable();
    }

    buildSchemasTable(): void {
        const schemaNamesArray = this.getSchemaNames();
        const numberOfSchemas = schemaNamesArray.length;
        const totalNumberOfSchemas = $("<p></p>");

        let text;
        if (numberOfSchemas === 0) {
            text = "There are no types to show.";
        } else {
            if (numberOfSchemas > 1) {
                text =
                    "There are " +
                    numberOfSchemas +
                    " types in the system, the names of which are shown below.";
            } else {
                text = "There is 1 type in the system.";
            }
            text +=
                " Click on a name below to view or edit the schema and/or JavaScript associated with that type.";
        }

        totalNumberOfSchemas.text(text);

        this.schemasTotalHeader.append(totalNumberOfSchemas);

        const header = $('<div class="header col-md-12"></div>');
        this.schemasList.append(header);

        const row = $('<div class="col-md-12 header-content"></div>');
        this.schemasList.append(row);
        const name = $('<div class="col-md-12">Type Name</div>');
        row.append(name);
        this.addSchemas(schemaNamesArray);
    }

    addSchemas(schemaNamesArray: string[]): void {
        for (const objectTypeName of schemaNamesArray) {
            const row = $('<div class="col-md-12 content"></div>');
            this.schemasList.append(row);
            row.data('objectType', objectTypeName);
            const objectType = $(
                '<div class="schema-object col-md-12">' + objectTypeName + '</div>'
            );
            objectType.data('objectType', objectTypeName);
            row.append(objectType);
            row.on("click", (e) => this.onSchemaRowClicked(e));
        }
    }

    getSchemaNames(): string[] {
        const schemaNamesArray = [];
        for (const objectType in this.schemas) {
            if ("Schema" !== objectType && "CordraDesign" !== objectType) {
                schemaNamesArray.push(objectType);
            }
        }
        schemaNamesArray.sort();
        return schemaNamesArray;
    }

    onSchemaRowClicked(e: ClickEvent): void {
        this.setAllRowsInactive();
        const clickedSchemaLi = $(e.target);
        clickedSchemaLi.addClass("info");
        const objectType = clickedSchemaLi.data("objectType") as string;
        this.showSchemaEditorFor(objectType, this.disabled);
        $("html, body").animate(
            {
                scrollTop: $("#schema-editor").offset()!.top
            },
            500
        );
    }

    setAllRowsInactive(): void {
        this.schemasList.find(".info").each(() => {
            $(this).removeClass("info");
        });
    }

    buildSchemaAdder(): void {
        const addButton = $(
            '<button class="btn btn-sm btn-primary"><i class="fa fa-plus"></i></button>'
        );
        this.schemaToolBar.append(addButton);
        addButton.on("click", () => this.onAddClicked());

        const addButtonSpan = $("<span></span>");
        addButton.append(addButtonSpan);
        addButtonSpan.text("Add");

        this.buildAddSchemaDialog();
    }

    buildAddSchemaDialog(): void {
        this.addSchemaDiv = $('<div class="modal fade" tabindex="-1"></div>') as DivWithModal;

        const modalDialog = $('<div class="modal-dialog"></div>');
        this.addSchemaDiv.append(modalDialog);

        const modalContent = $('<div class="modal-content"></div>');
        modalDialog.append(modalContent);

        const modalHeader = $('<div class="modal-header"></div>');
        modalContent.append(modalHeader);
        //        var closeButton = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>');
        //        modalHeader.append(closeButton);

        const title = $('<h4 class="modal-title">Add Type</h4>');
        modalHeader.append(title);

        const modalBody = $('<div class="modal-body"></div>');
        modalContent.append(modalBody);

        const addForm = $('<form class="form-horizontal" role="form"></form>');
        addForm.on("submit", () => {
            return false;
        });
        modalBody.append(addForm);

        const nameGroup = $('<div class="form-group"></div>');
        addForm.append(nameGroup);

        const nameLabel = $(
            '<label for="newSchemaNameInput" class="col-sm-2 control-label">Name</label>'
        );
        nameGroup.append(nameLabel);

        const nameCol = $('<div class="col-sm-10">');
        nameGroup.append(nameCol);

        this.newSchemaObjectTypeInput = $(
            '<input id="newSchemaNameInput" type="text" class="form-control input-sm" placeholder="Type name">'
        );
        nameCol.append(this.newSchemaObjectTypeInput);

        const templateGroup = $('<div class="form-group"></div>');
        addForm.append(templateGroup);

        const templateLabel = $(
            '<label for="templateSelect" class="col-sm-2 control-label">Template</label>'
        );
        templateGroup.append(templateLabel);

        const templateCol = $('<div class="col-sm-10">');
        templateGroup.append(templateCol);

        this.templateSelect = $(
            '<select id="templateSelect" class="form-control"></select>'
        );
        templateCol.append(this.templateSelect);

        for (const templateName in this.templates) {
            const option = $(
                '<option value="' + templateName + '">' + templateName + "</option>"
            );
            this.templateSelect.append(option);
        }

        const jsCheckGroup = $('<div class="form-group"></div>');
        addForm.append(jsCheckGroup);

        const jsCheckColumn = $('<div class="col-sm-offset-2 col-sm-10">');
        jsCheckGroup.append(jsCheckColumn);

        const jsCheckDiv = $('<div class="checkbox">');
        jsCheckColumn.append(jsCheckDiv);

        const jsLabel = $("<label>Include example JavaScript</label>");
        jsCheckDiv.append(jsLabel);

        this.jsCheckBox = $('<input type="checkbox">');
        jsLabel.prepend(this.jsCheckBox);

        this.addSchemaDiv.on("shown.bs.modal", () => {
            this.newSchemaObjectTypeInput.trigger("focus");
        });

        const modalFooter = $('<div class="modal-footer"></div>');
        modalContent.append(modalFooter);

        const cancelButton = $(
            '<button type="button" class="btn btn-sm btn-warning" style="min-width: 130px;" >Cancel</button>'
        );
        modalFooter.append(cancelButton);
        cancelButton.on("click", () => this.onCancelButtonClick());

        const addDoneButton = $(
            '<button type="button" class="btn btn-sm btn-primary" style="min-width: 130px;" >Add</button>'
        );
        modalFooter.append(addDoneButton);
        addDoneButton.on("click", () => this.onAddDoneButtonClick());
    }

    enable(): void {
        this.disabled = false;
        if (this.schemaToolBar) this.schemaToolBar.show();
    }

    disable(): void {
        this.disabled = true;
        if (this.schemaToolBar) this.schemaToolBar.hide();
    }

    onCancelButtonClick(): void {
        this.newSchemaObjectTypeInput.val("");
        this.addSchemaDiv.modal("hide");
    }

    onAddDoneButtonClick(): void {
        const objectType = this.newSchemaObjectTypeInput.val() as string;
        if (!objectType) return;
        if (objectType === "") {
            APP.notifications.alertError("Type name is a required.");
        } else {
            const templateName = this.templateSelect.val() as string;
            const template = this.templates[templateName];
            const copyOfTemplate = {};
            $.extend(copyOfTemplate, template);

            const isIncludeExampleJs = this.jsCheckBox.is(":checked");
            let js;
            if (isIncludeExampleJs) {
                js = this.exampleJavaScript;
            }
            this.createNewSchema(objectType, copyOfTemplate, js);
            this.newSchemaObjectTypeInput.val("");
            this.addSchemaDiv.modal("hide");
        }
    }

    onAddClicked(): void {
        this.addSchemaDiv.modal({ keyboard: true });
    }

    refresh(newSchemas?: Record<string, unknown>): void {
        if (newSchemas && this.schemasList) {
            this.refreshSchemas(newSchemas);
        }
    }

    refreshSchemas(schemas: Record<string, unknown>): void {
        this.schemas = schemas;
        this.schemasList.empty();
        this.schemasTotalHeader.empty();
        this.buildSchemasTable();
    }
}
