import {
    DoipError,
    AuthenticationInfo,
    PasswordAuthenticationInfo,
    PrivateKeyAuthenticationInfo,
    TokenAuthenticationInfo,
} from '@cnri/doip-client';

import { AuthResponse } from "./cordra/AuthResponse.js";
import { ClientSideFileWidget } from './ClientSideFileWidget.js';
import { Notifications } from './cordra/ToastrNotifications.js';
import { EncryptionUtil, JsonWebKeyWithG } from "./EncryptionUtil.js";
import { Encoder } from "./encoder/index.js";
import { DivWithModal } from "./cordra/ModalYesNoDialog.js";

import ClickEvent = JQuery.ClickEvent;

const HEARTBEAT_TIMEOUT = 1000 * 30;

type BootstrapButton = JQuery<HTMLButtonElement> & { button: (state: string) => void };

export interface CustomAuthenticationConfig {
    url: string;
    tabName?: string;
    height?: number;
}

export class AuthenticatorWidget {
    private readonly signInButton: JQuery<HTMLButtonElement>;
    private readonly signOutLink: JQuery<HTMLButtonElement>;
    private readonly authenticatedLabel: JQuery<HTMLLabelElement>;
    private readonly onAuthenticationStateChangeCallback: () => void;
    private readonly authenticatedDiv: JQuery<HTMLDivElement>;
    private readonly customAuthentication: CustomAuthenticationConfig;
    private readonly allowLogin: boolean;

    private authenticateDiv!: DivWithModal;

    private privateKeyAuthenticateDiv!: JQuery<HTMLDivElement>;
    private secretKeyAuthenticateDiv!: JQuery<HTMLDivElement>;
    private customAuthenticateDiv!: JQuery<HTMLDivElement>;
    private fileReaderDiv!: JQuery<HTMLDivElement>;
    private newPasswordContainer!: JQuery<HTMLDivElement>;

    private customIframe!: JQuery<HTMLIFrameElement>;

    private privateKeyAuthenticateButton!: BootstrapButton;
    private authenticateButton!: BootstrapButton;

    private handleInput!: JQuery<HTMLInputElement>;
    private privateKeyPassPhraseInput!: JQuery<HTMLInputElement>;
    private usernameInput!: JQuery<HTMLInputElement>;
    private passwordInput!: JQuery<HTMLInputElement>;
    private newPasswordInput!: JQuery<HTMLInputElement>;

    private fileReader!: ClientSideFileWidget;
    private dialogNotifications!: Notifications;
    private privateKeyBytes!: Uint8Array | null;
    private isEncryptedKey = false;
    private heartbeatTimerId: number | undefined;
    private isHeartbeatInFlight: boolean = false;
    private isActiveSession: boolean;
    private ignoreNextHeartbeatResponse: boolean = false;
    private typesPermittedToCreate: string[] | undefined;
    private key: JsonWebKeyWithG | undefined;

    private readonly userInfo: {
        userId?: string;
        username?: string;
    };

    constructor(
            containerDiv: JQuery<HTMLDivElement>,
            onAuthenticationStateChangeCallback: () => void,
            isActiveSession: boolean,
            usernameParam: string | undefined,
            userIdParam: string | undefined,
            typesPermittedToCreateParam: string[],
            allowLogin: boolean,
            customAuthentication: CustomAuthenticationConfig
    ) {
        this.onAuthenticationStateChangeCallback = onAuthenticationStateChangeCallback;
        this.isActiveSession = isActiveSession;
        this.allowLogin = allowLogin;
        this.typesPermittedToCreate = typesPermittedToCreateParam;
        this.customAuthentication = customAuthentication;
        if (allowLogin) {
            this.signInButton = $(
                '<button type="button" class="btn btn-primary btn-sm"><i class="fa fa-user"></i>Sign In</button>'
            );
            this.signInButton.on("click", () => this.onSignInClick());
        } else {
            this.signInButton = $(
                '<button type="button" class="btn btn-primary btn-sm" data-state="unclicked"></i>Login only allowed over HTTPS</button>'
            );
            this.signInButton.on("click", () => this.clickDisallowedSignInButton());
        }
        containerDiv.append(this.signInButton);

        this.authenticatedDiv = $(
            '<div class="authenticatedDiv" style="display:none;"></div>'
        );
        containerDiv.append(this.authenticatedDiv);
        const signOutForm = $('<form class="form-inline"></form>');
        this.authenticatedDiv.append(signOutForm);
        const signOutGroup = $('<div class="control-group"></div>');
        signOutForm.append(signOutGroup);

        this.authenticatedLabel = $(
            '<span class="help-inline" style="color:white; cursor: pointer;"></span>'
        );
        signOutGroup.append(this.authenticatedLabel);
        signOutGroup.append(" ");
        this.signOutLink = $('<a class="sign-out-link">[Sign Out]</a>');
        signOutGroup.append(this.signOutLink);
        this.signOutLink.on("click", (e) => this.onSignOutLinkClick(e));

        this.buildAuthenticateDialog();
        this.userInfo = {
            userId: undefined,
            username: undefined
        };
        if (isActiveSession) {
            this.userInfo.userId = userIdParam;
            this.userInfo.username = usernameParam;
            this.setAuthenticated();
        }
    }

    clickDisallowedSignInButton(): void {
        if (this.signInButton.data('state') === 'unclicked') {
            this.signInButton.data('state', 'clicked');
            this.signInButton.text('Click again to allow login without HTTPS');
            setTimeout(() => {
                if (this.signInButton.data('state') === 'clicked') {
                    this.signInButton.data('state', 'unclicked');
                    this.signInButton.text('Login only allowed over HTTPS');
                }
            }, 5000);
        } else if (this.signInButton.data('state') === 'clicked') {
            this.signInButton.data('state', 'clickedTwice');
            this.signInButton.text('Sign In (warning: not HTTPS)');
        } else {
            this.onSignInClick();
        }
    }

    buildAuthenticateDialog(): void {
        this.authenticateDiv = $('<div class="modal fade" tabindex="-1"></div>') as DivWithModal;

        const modalDialog = $(
            '<div class="modal-dialog" style="width: 428px;"></div>'
        );
        this.authenticateDiv.append(modalDialog);

        const modalContent = $('<div class="modal-content"></div>');
        modalDialog.append(modalContent);

        const modalHeader = $('<div class="modal-header"></div>');
        modalContent.append(modalHeader);
        const closeButton = $(
            '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>'
        );
        modalHeader.append(closeButton);

        let title;
        if (this.allowLogin) {
            title = $('<h4 class="modal-title">Authenticate</h4>');
        } else {
            title = $('<h4 class="modal-title">Authenticate (warning: not HTTPS)</h4>');
        }
        modalHeader.append(title);

        const modalBody = $('<div class="modal-body"></div>');
        modalContent.append(modalBody);

        // const dialogNotificationsDiv = $("<div></div>");
        // modalContent.append(dialogNotificationsDiv);
        this.dialogNotifications = new Notifications();

        const authenticateModeSelectDiv = $(
            '<div class="tabbable tab authenticateModeSelect"></div>'
        );
        modalBody.append(authenticateModeSelectDiv);
        const tabNav = $(
            '<ul id="authTab" class="nav nav-tabs" style="margin-bottom: 5px;">'
        );
        const privateKeyNav = $(
            '<li><a href="#privateKeyAuth" data-toggle="tab">Private Key</a></li>'
        );
        const secretKeyNav = $(
            '<li class="active"><a href="#secretKeyAuth" data-toggle="tab">Password</a></li>'
        );
        tabNav.append(secretKeyNav);
        tabNav.append(privateKeyNav);
        authenticateModeSelectDiv.append(tabNav);

        const tabContentDiv = $(
            '<div id="authenticateTabContent" class="tab-content">'
        );
        authenticateModeSelectDiv.append(tabContentDiv);

        this.privateKeyAuthenticateDiv = $(
            '<div class="tab-pane fade " id="privateKeyAuth"></div>'
        );
        this.secretKeyAuthenticateDiv = $(
            '<div class="tab-pane fade in active" id="secretKeyAuth"></div>'
        );

        tabContentDiv.append(this.secretKeyAuthenticateDiv);
        tabContentDiv.append(this.privateKeyAuthenticateDiv);

        // private key
        const privateKeyAuthenticateForm = $("<form>");
        this.privateKeyAuthenticateDiv.append(privateKeyAuthenticateForm);
        const handleFormGroup = $('<div class="form-group">');
        privateKeyAuthenticateForm.append(handleFormGroup);
        this.handleInput = $(
            '<input id="authHandleInput" type="text" class="form-control input-sm" style="min-width: 150px;" placeholder="Username/Handle">'
        );
        handleFormGroup.append(this.handleInput);

        const keyFileFormGroup = $('<div class="form-group">');
        privateKeyAuthenticateForm.append(keyFileFormGroup);
        this.fileReaderDiv = $('<div class="form-inline"></div>');
        keyFileFormGroup.append(this.fileReaderDiv);
        this.fileReader = new ClientSideFileWidget(
            this.fileReaderDiv,
            (keyBytes) => this.onPrivateKeySelected(keyBytes),
            false,
            "Select private key"
        );
        keyFileFormGroup.append(" ");

        const decoyTextInput = $('<input type="text" style="display:none"/>');
        this.fileReaderDiv.append(decoyTextInput);
        this.privateKeyPassPhraseInput = $(
            '<input type="password" class="form-control input-sm" style="display:none;" placeholder="Passphrase">'
        );
        this.fileReaderDiv.append(" ");
        this.fileReaderDiv.append(this.privateKeyPassPhraseInput);
        this.privateKeyPassPhraseInput.on("keypress", (event) => {
            if (event.which === 13) {
                event.preventDefault();
                this.onPrivateKeyAuthenticateButtonClick();
            }
        });

        this.privateKeyAuthenticateButton = $(
            '<button type="button" class="btn btn-sm btn-primary" style="min-width: 130px;" data-loading-text="Authenticating...">Authenticate</button>'
        ) as BootstrapButton;
        privateKeyAuthenticateForm.append(this.privateKeyAuthenticateButton);
        this.privateKeyAuthenticateButton.on(
            "click",
            () => this.onPrivateKeyAuthenticateButtonClick()
        );

        // username and password
        const passwordAuthenticateForm = $("<form>");
        this.secretKeyAuthenticateDiv.append(passwordAuthenticateForm);
        const usernameFormGroup = $('<div class="form-group">');
        passwordAuthenticateForm.append(usernameFormGroup);
        this.usernameInput = $(
            '<input type="text" class="form-control input-sm username-input" style="min-width: 150px;" placeholder="Username">'
        );
        usernameFormGroup.append(this.usernameInput);

        this.newPasswordContainer = $('<form style="display:none;"></form>');
        modalBody.append(this.newPasswordContainer);
        const newPasswordForGroup = $('<div class="form-group"></div>');
        this.newPasswordContainer.append(newPasswordForGroup);
        const newPasswordMessage = $(
            "<label>Server requires a new password be set.</label>"
        );
        newPasswordForGroup.append(newPasswordMessage);
        this.newPasswordInput = $(
            '<input type="password" class="form-control input-sm" placeholder="New Password">'
        );
        newPasswordForGroup.append(this.newPasswordInput);

        this.authenticateDiv.on("shown.bs.modal", () => {
            this.usernameInput.trigger("focus");
        });

        const passwordFormGroup = $('<div class="form-group">');
        passwordAuthenticateForm.append(passwordFormGroup);

        this.passwordInput = $(
            '<input type="password" class="form-control input-sm" placeholder="Password">'
        );
        this.passwordInput.on("keypress", (event) => {
            if (event.which === 13) {
                event.preventDefault();
                this.onAuthenticateButtonClick();
            }
        });
        passwordFormGroup.append(this.passwordInput);
        this.authenticateButton = $(
            '<button type="button" class="btn btn-sm btn-primary" style="min-width: 130px;" data-loading-text="Authenticating...">Authenticate</button>'
        ) as BootstrapButton;
        passwordAuthenticateForm.append(this.authenticateButton);
        this.authenticateButton.on("click", () => this.onAuthenticateButtonClick());

        // custom
        if (this.customAuthentication) {
            const anchor = $('<a href="#customAuth" data-toggle="tab">Custom</a>');
            if (this.customAuthentication.tabName) {
                anchor.text(this.customAuthentication.tabName);
            }
            const customNav = $(
                '<li></li>'
            );
            customNav.append(anchor);
            tabNav.append(customNav);
            this.customAuthenticateDiv = $(
                '<div class="tab-pane fade " id="customAuth"></div>'
            );
            tabContentDiv.append(this.customAuthenticateDiv);
            this.buildCustomAuthenticateDiv();
        }

    }

    buildCustomAuthenticateDiv(): void {
        this.customIframe = $(
            '<iframe style="width:100%;" frameborder="0" scrolling="auto" marginheight="0" marginwidth="0"></iframe>'
        );
        if (this.customAuthentication.height) {
            this.customIframe.height(this.customAuthentication.height);
        }
        this.customIframe.attr('src', this.customAuthentication.url);
        window.addEventListener("message", (event) => {
            const message = event.data;
            if (message.type === "customAuthentication") {
                this.authenticateCustom(message.token);
            }
        }, false);
        this.customAuthenticateDiv.append(this.customIframe);
    }

    authenticateCustom(token: string): void {
        //TODO this needs testing since the switch to DOIP
        const authInfo: AuthenticationInfo = new TokenAuthenticationInfo(undefined, token);
        APP.authenticate(authInfo)
            .then((resp: AuthResponse) => {
                const userKey = resp.userId || resp.username;
                APP.storeCurrentUserKey(userKey);
                return APP.getAuthenticationStatus(true)
                .then((statusResp: AuthResponse) => {
                    this.onAuthenticateSuccess(statusResp);
                });
            })
            .catch((resp: DoipError) => this.onAuthenticateError(resp));
    }

    onSignInClick(): void {
        this.usernameInput.val("");
        this.passwordInput.val("");
        this.newPasswordInput.val("");
        this.newPasswordContainer.hide();
        this.authenticateDiv.modal({ keyboard: true });
    }

    onAuthenticateButtonClick(): void {
        const password = this.passwordInput.val() as string;
        const username = this.usernameInput.val() as string;
        if (username === "") {
            this.dialogNotifications.alertError("Missing username.");
            return;
        }
        if (password === "") {
            this.dialogNotifications.alertError("Missing password.");
            return;
        }
        if (this.isHeartbeatInFlight) {
            this.ignoreNextHeartbeatResponse = true;
        }
        if (this.newPasswordContainer.is(":visible")) {
            const newPassword = this.newPasswordInput.val() as string;
            if (newPassword === "") {
                this.dialogNotifications.alertError("Missing password.");
                return;
            }
            const authInfo: AuthenticationInfo = new PasswordAuthenticationInfo(username, password);
            APP.changePassword(newPassword, authInfo)
                .then(() => {
                    const newAuthInfo: AuthenticationInfo = new PasswordAuthenticationInfo(username, newPassword);
                    return APP.authenticate(newAuthInfo);
                })
                .then(() => {
                    APP.storeCurrentUserKey(username);
                    return APP.getAuthenticationStatus(true)
                    .then((statusResp: AuthResponse) => {
                        this.onAuthenticateSuccess(statusResp);
                    });
                })
                .catch((resp: DoipError) => this.onAuthenticateError(resp));
        } else {
            const authInfo: AuthenticationInfo = new PasswordAuthenticationInfo(username, password);
            APP.authenticate(authInfo)
                .then(() => {
                    APP.storeCurrentUserKey(username);
                    return APP.getAuthenticationStatus(true)
                    .then((statusResp: AuthResponse) => {
                        this.onAuthenticateSuccess(statusResp);
                    });
                })
                .catch((resp: DoipError) => this.onAuthenticateError(resp));
        }
    }

    onPrivateKeyAuthenticateButtonClick(): void {
        const handle = this.handleInput.val() as string;
        if (handle === "") {
            this.dialogNotifications.alertError(
                "You must specify the handle containing your public key to authenticate."
            );
            return;
        }
        if (this.privateKeyBytes === null) {
            this.dialogNotifications.alertError(
                "You must select a private key file to authenticate."
            );
            return;
        }
        if (this.isEncryptedKey) {
            const passPhrase = this.getPassPhrase();
            if (passPhrase === "") {
                this.dialogNotifications.alertError(
                    "The selected private key requires a passphrase to decrypt it."
                );
                return;
            }
            try {
                EncryptionUtil.decryptPrivateKeyAes(
                    this.privateKeyBytes,
                    passPhrase
                )
                    .then((keyBytesBuffer) => {
                        const keyBytes = new Uint8Array(keyBytesBuffer);
                        this.privateKeyAuthenticateForKeyBytes(handle, keyBytes);
                    })
                    .catch((error) => {
                        this.dialogNotifications.alertError("Invalid private key file.");
                        console.log(error);
                    });
            } catch (error) {
                this.dialogNotifications.alertError("Invalid private key file.");
                console.log(error);
            }
        } else {
            this.privateKeyAuthenticateForKeyBytes(handle, this.privateKeyBytes);
        }
    }

    privateKeyAuthenticateForKeyBytes(handle: string, keyBytes: Uint8Array): void {
        this.dialogNotifications.clear();
        const key = this.parsePrivateKeyFile(keyBytes);
        if (key == null) {
            return;
        }
        this.privateKeyAuthenticateButton.button("loading");
        this.privateKeyAuthenticate(handle, key);
    }

    privateKeyAuthenticate(handle: string, privateKey: JsonWebKeyWithG): void {
        const authInfo: AuthenticationInfo = new PrivateKeyAuthenticationInfo(handle, privateKey);
        APP.authenticate(authInfo)
            .then(() => {
                APP.storeCurrentUserKey(handle);
                return APP.getAuthenticationStatus(true)
                .then((statusResp: AuthResponse) => {
                    this.onAuthenticateSuccess(statusResp);
                });
            })
            .catch((resp: DoipError) => this.onAuthenticateError(resp));
    }

    getIsActiveSession(): boolean {
        return this.isActiveSession;
    }

    onSignOutLinkClick(e: JQuery.ClickEvent): void {
        e.preventDefault();
        this.signOut();
    }

    signOut(): void {
        if (this.isHeartbeatInFlight) {
            this.ignoreNextHeartbeatResponse = true;
        }
        APP.signOut()
            .then(() => {
                APP.clearCurrentUserKey();
                return APP.getAuthenticationStatus(true)
                .then((statusResp: AuthResponse) => {
                    this.onSignOutSuccess(statusResp);
                });
            })
            .catch(() => {
                APP.clearCurrentUserKey();
                this.onSignOutError();
            });
    }

    onSignOutSuccess(response: AuthResponse): void {
        this.setTypesPermittedToCreate(response.typesPermittedToCreate);
        APP.notifications.clear();
        this.setUiToStateUnauthenticated();
    }

    setUiToStateUnauthenticated(): void {
        if (!this.isActiveSession) return;
        this.isActiveSession = false;
        this.authenticatedLabel.text("");
        this.signInButton.show();
        this.authenticatedDiv.hide();
        delete this.userInfo.userId;
        delete this.userInfo.username;
        if (this.fileReader) {
            this.fileReader.clear();
        }
        if (this.customIframe) {
            const signOutMessage = {
                type: "signOut"
            };
            this.customIframe.get(0)!.contentWindow!.postMessage(signOutMessage, "*");
            console.log("sent signOut message to iframe");
        }
        clearTimeout(this.heartbeatTimerId);
        this.onAuthenticationStateChangeCallback();
    }

    onSignOutError(): void {
        this.setUiToStateUnauthenticated();
    }

    setAuthenticated(): void {
        this.isActiveSession = true;
        APP.notifications.clear();
        this.dialogNotifications.clear();
        this.usernameInput.val("");
        this.passwordInput.val("");
        this.privateKeyPassPhraseInput.val("");
        this.signInButton.hide();
        this.authenticateButton.button("reset");
        this.privateKeyAuthenticateButton.button("reset");
        this.authenticatedLabel.show();
        if (this.userInfo.username) {
            this.authenticatedLabel.text(this.userInfo.username);
        } else {
            this.authenticatedLabel.text(this.userInfo.userId!);
        }

        if (this.userInfo.username !== "admin") {
            this.authenticatedLabel.on("click", (e) => this.onUsernameLabelClick(e));
        }
        this.authenticateDiv.modal("hide");
        this.authenticateDiv.hide();
        this.authenticatedDiv.show();
        // Use window.setTimeout instead of setTimeout (which uses NodeJS typings)
        this.heartbeatTimerId = window.setTimeout(() => this.heartbeat(), HEARTBEAT_TIMEOUT);
    }

    onUsernameLabelClick(e: ClickEvent): void {
        e.preventDefault();
        APP.resolveHandle(this.userInfo.userId);
    }

    onAuthenticateSuccess(response: AuthResponse): void {
        let isUserAndSessionChanged = true;
        if (this.isActiveSession && this.userInfo.userId === response.userId) {
            // we were already in a session for this user
            isUserAndSessionChanged = false;
        }
        this.userInfo.userId = response.userId;
        this.userInfo.username = response.username;
        this.typesPermittedToCreate = response.typesPermittedToCreate;
        if (isUserAndSessionChanged) {
            this.setAuthenticated();
            this.onAuthenticationStateChangeCallback();
        }
    }

    getTypesPermittedToCreate(): string[] | undefined {
        return this.typesPermittedToCreate;
    }

    setTypesPermittedToCreate(types: string[] | undefined): void {
        this.typesPermittedToCreate = types;
    }

    onAuthenticateError(error: DoipError): void {
        let msg: string;
        if (this.secretKeyAuthenticateDiv.is(":visible")) {
            msg = "The username or password you entered is incorrect";
        } else {
            msg = "The authentication has failed";
        }
        if (error.response) {
            msg = error.message!;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((error.response as any).passwordChangeRequired) {
                this.newPasswordContainer.show();
            }
        }
        this.dialogNotifications.alertError(msg);
        this.authenticateButton.button("reset");
        this.privateKeyAuthenticateButton.button("reset");
    }

    getCurrentUserId(): string | undefined {
        return this.userInfo.userId;
    }

    heartbeat(): void {
        this.isHeartbeatInFlight = true;
        APP.getAuthenticationStatus()
            .then((resp: AuthResponse) => {
                this.isHeartbeatInFlight = false;
                if (this.ignoreNextHeartbeatResponse) {
                    this.ignoreNextHeartbeatResponse = false;
                    return;
                }
                if (resp.active) {
                    if (!this.isActiveSession || resp.userId !== this.userInfo.userId) {
                        this.retrieveFullAuthenticationStatus();
                    }
                } else if (this.isActiveSession) {
                    this.signOut();
                }
            })
            .catch(() => {
                this.isHeartbeatInFlight = false;
                if (this.ignoreNextHeartbeatResponse) {
                    this.ignoreNextHeartbeatResponse = false;
                    return;
                }
                if (this.isActiveSession) {
                    this.signOut();
                }
                APP.notifications.alertError("The repository could not be reached.");
            });
        this.heartbeatTimerId = window.setTimeout(() => this.heartbeat(), HEARTBEAT_TIMEOUT);
    }

    retrieveFullAuthenticationStatus(): void {
        APP.getAuthenticationStatus(true)
            .then((resp: AuthResponse) => this.onAuthenticateSuccess(resp))
            .catch(() => {
                if (this.isActiveSession) {
                    this.signOut();
                }
                APP.notifications.alertError("The repository could not be reached.");
            });
    }

    onPrivateKeySelected(keyBytes: ArrayBuffer | string | null): void {
        this.dialogNotifications.clear();
        this.isEncryptedKey = false;
        if (!keyBytes) {
            this.privateKeyBytes = null;
            this.privateKeyPassPhraseInput.hide();
            return;
        }
        if (typeof keyBytes === 'string') {
            keyBytes = Encoder.Utf8.decode(keyBytes);
        }
        if (this.isCnriFormattedKey(keyBytes as Uint8Array)) {
            if (EncryptionUtil.requiresSecretKey(keyBytes as Uint8Array)) {
                this.isEncryptedKey = true;
                this.privateKeyPassPhraseInput.show(400);
            }
        } else {
            this.privateKeyPassPhraseInput.hide();
        }
        this.privateKeyBytes = keyBytes as Uint8Array;
    }

    isCnriFormattedKey(keyBytes: Uint8Array): boolean {
        return keyBytes[0] === 0;
    }

    getPassPhrase(): string {
        return this.privateKeyPassPhraseInput.val() as string;
    }

    parsePrivateKeyAsJwk(keyBytes: Uint8Array): JsonWebKeyWithG {
        const jsonString = Encoder.Utf8.encode(keyBytes);
        this.key = JSON.parse(jsonString) as JsonWebKeyWithG;
        return this.key;
    }

    parsePrivateKeyFile(keyBytes: Uint8Array): JsonWebKeyWithG | undefined {
        let key;
        try {
            if (!this.isCnriFormattedKey(keyBytes)) {
                key = this.parsePrivateKeyAsJwk(keyBytes);
            } else {
                let offset = 4;
                if (this.isEncryptedKey) offset = 0;
                key = EncryptionUtil.getPrivateKeyFromBytes(keyBytes, offset);
            }
            this.dialogNotifications.clear();
        } catch (err) {
            this.dialogNotifications.alertError(
                "Selected file could not be parsed as a private key."
            );
        }
        return key;
    }
}
