import {
    AbstractDoipClient,
    DoipRequest,
    ServiceInfo,
    RequestOptions,
    DoipClientResponse,
    AuthenticationInfo,
    PasswordAuthenticationInfo,
    PrivateKeyAuthenticationInfo,
    TokenAuthenticationInfo,
    DoipConstants
} from '@cnri/doip-client';

export class TokenUsingDoipClient extends AbstractDoipClient {

    private readonly doipClient: AbstractDoipClient;

    userToServiceToTokenMap: any = {};
    currentUserKey: string | undefined;

    constructor(doipClient: AbstractDoipClient) {
        super();
        this.doipClient = doipClient;
        this.userToServiceToTokenMap = this.loadAuthTokens();
    }

    setCurrentUserKey(userKey: string | undefined): void {
        this.currentUserKey = userKey;
    }

    getDefaultServiceInfo(): ServiceInfo | undefined {
        return this.doipClient.getDefaultServiceInfo();
    }

    async performOperation(doipRequest: DoipRequest, serviceInfo?: ServiceInfo, requestOptions?: RequestOptions): Promise<DoipClientResponse> {
        //TODO performOperationWithToken
        const doipResponse: DoipClientResponse = await this.performOperationWithTokenAndRetry(doipRequest, serviceInfo, requestOptions, false);
        //const doipResponse: DoipClientResponse = await this.doipClient.performOperation(doipRequest, serviceInfo, requestOptions);
        return doipResponse;
    }

    async performCompactOperation(doipRequest: DoipRequest, serviceInfo?: ServiceInfo, requestOptions?: RequestOptions): Promise<DoipClientResponse> {
        const doipResponse: DoipClientResponse = await this.performOperationWithTokenAndRetry(doipRequest, serviceInfo, requestOptions, true);
        return doipResponse;
    }

    async performOperationWithTokenAndRetry(doipRequest: DoipRequest, serviceInfo?: ServiceInfo, requestOptions?: RequestOptions, isCompact: boolean = true): Promise<DoipClientResponse> {
        let isCachedToken = false;
        let doipRequestToSend = doipRequest;
        let userKey: string | undefined;
        const serviceInfoForRequest = await this.getServiceInfoForRequest(doipRequest, serviceInfo);
        if (doipRequest.authentication instanceof TokenAuthenticationInfo) {
            // don't look up a cached token, just use the one in the authenticaiton
        } else {
            userKey = this.userKeyFromDoipRequest(doipRequest) || this.currentUserKey;
            if (userKey) {
                let token = this.getTokenFromCache(userKey, serviceInfoForRequest);
                if (!token && doipRequest.authentication) {
                    const authTokenResponse = await this.acquireNewToken(doipRequest.authentication, serviceInfoForRequest);
                    token = authTokenResponse.access_token as string;
                    this.cacheToken(userKey, serviceInfoForRequest, token);
                }
                if (token) {
                    isCachedToken = true;
                    doipRequestToSend = { ...doipRequest };
                    doipRequestToSend.authentication = new TokenAuthenticationInfo(doipRequest.clientId, token);
                }
            }
        }
        let doipResponse: DoipClientResponse;
        if (isCompact) {
            doipResponse = await this.doipClient.performCompactOperation(doipRequestToSend, serviceInfoForRequest, requestOptions);
        } else {
            doipResponse = await this.doipClient.performOperation(doipRequestToSend, serviceInfoForRequest, requestOptions);
        }
        if (isCachedToken && this.isUnauthorizedResponse(doipResponse)) {
            this.removeToken(userKey, serviceInfoForRequest);
            if (isCompact && doipRequest.authentication) {
                const authTokenResponse = await this.acquireNewToken(doipRequest.authentication, serviceInfoForRequest);
                const token = authTokenResponse.access_token as string;
                this.cacheToken(userKey!, serviceInfoForRequest, token);
                doipRequestToSend = { ...doipRequest };
                doipRequestToSend.authentication = new TokenAuthenticationInfo(doipRequest.clientId, token);
                doipResponse = await this.doipClient.performCompactOperation(doipRequestToSend, serviceInfoForRequest, requestOptions);
            }
        }
        return doipResponse;
    }

    async changePassword(newPassword: string, authInfo: AuthenticationInfo): Promise<any> {
        const request: DoipRequest = {
            targetId: "service",
            operationId: "20.DOIP/Op.ChangePassword",
            authentication: authInfo,
            inputJson: {
                password: newPassword
            }
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson;
    }

    private isUnauthorizedResponse(doipResponse: DoipClientResponse): boolean {
        if (doipResponse.getStatus() === DoipConstants.STATUS_UNAUTHENTICATED) {
            return true;
        } else {
            return false;
        }
    }

    async getServiceInfoForRequest(doipRequest: DoipRequest, serviceInfo?: ServiceInfo | undefined): Promise<ServiceInfo> {
        return this.doipClient.getServiceInfoForRequest(doipRequest, serviceInfo);
    }

    private removeToken(userKey: string | undefined, serviceInfo: ServiceInfo): void {
        if (!userKey) {
            return;
        }
        const serviceToTokenMap = this.userToServiceToTokenMap[userKey];
        if (!serviceToTokenMap) {
            return;
        }
        const serviceKey = this.getKeyForServiceInfo(serviceInfo);
        delete serviceToTokenMap[serviceKey];
        this.storeAuthTokens();
    }

    private cacheToken(userKey: string, serviceInfo: ServiceInfo, token: string): void {
        let serviceToTokenMap = this.userToServiceToTokenMap[userKey];
        if (!serviceToTokenMap) {
            serviceToTokenMap = {};
            this.userToServiceToTokenMap[userKey] = serviceToTokenMap;
        }
        const serviceKey = this.getKeyForServiceInfo(serviceInfo);
        serviceToTokenMap[serviceKey] = token;
        this.storeAuthTokens();
    }

    private getTokenFromCache(userKey: string, serviceInfo: ServiceInfo | undefined): string | undefined {
        //TODO consider storing tokenInfo rather than just token
        //and look at exp and last used values as in CordraClient
        if (!userKey) {
            return undefined;
        }
        const serviceToTokenMap = this.userToServiceToTokenMap[userKey];
        if (!serviceToTokenMap) {
            return undefined;
        }
        if (!serviceInfo) {
            return undefined;
        }
        const serviceKey = this.getKeyForServiceInfo(serviceInfo);
        const token = serviceToTokenMap[serviceKey];
        return token;
    }

    private storeAuthTokens(): void {
        if (localStorage) {
            localStorage.setItem('TokenUsingDoipClient.authTokens', JSON.stringify(this.userToServiceToTokenMap));
        }
    }

    private loadAuthTokens(): any {
        if (localStorage) {
            const storedTokensJson = localStorage.getItem('TokenUsingDoipClient.authTokens');
            if (storedTokensJson) {
                return JSON.parse(storedTokensJson);
            }
        }
        return {};
    }

    async authenticate(authInfo: AuthenticationInfo, serviceInfo?: ServiceInfo): Promise<any> {
        const serviceInfoForRequest: ServiceInfo | undefined = serviceInfo || this.doipClient.getDefaultServiceInfo();
        if (!serviceInfoForRequest) {
            throw new Error("No serviceInfo provided for authenticate request");
        }
        const authTokenResponse = await this.acquireNewToken(authInfo, serviceInfoForRequest);
        const token: string = authTokenResponse.access_token;
        this.cacheToken(authInfo.getUserKey()!, serviceInfoForRequest, token);
        return authTokenResponse;
    }

    private async createAuthTokenInput(authInfo: AuthenticationInfo | unknown): Promise<any> {
        let inputJson: any = {};
        if (authInfo instanceof PasswordAuthenticationInfo) {
            inputJson.grant_type = "password";
            inputJson.username = authInfo.username;
            inputJson.password = authInfo.password;
        } else if (authInfo instanceof PrivateKeyAuthenticationInfo) {
            inputJson.grant_type = "urn:ietf:params:oauth:grant-type:jwt-bearer";
            const jwt = await authInfo.createBearerToken();
            inputJson.assertion = jwt; //odd that this token will be slightly different than the one in the DOIP request headers???
        } else {
            inputJson = authInfo;
        }
        // else if (authInfo instanceof TokenAuthenticationInfo) {
        //     inputJson.grant_type = "urn:ietf:params:oauth:grant-type:jwt-bearer";
        //     inputJson.assertion = authInfo.getToken();
        // }
        return inputJson;
    }

    async signOut(userKey?: string, serviceInfo?: ServiceInfo): Promise<any> {
        const serviceInfoForRequest: ServiceInfo | undefined = serviceInfo || this.doipClient.getDefaultServiceInfo();
        if (!serviceInfoForRequest) {
            throw new Error("No serviceInfo provided for sign out request");
        }
        if (!userKey) {
            userKey = this.currentUserKey;
        }
        if (!userKey) {
            return { active: false }
        }
        const token = this.getTokenFromCache(userKey, serviceInfoForRequest);
        if (!token) {
            return { active: false }
        }
        const inputJson = {
            token
        };
        const revokeTokenRequest: DoipRequest = {
            targetId: serviceInfoForRequest?.serviceId || 'service',
            operationId: "20.DOIP/Op.Auth.Revoke",
            inputJson
        };
        const authTokenResponse = await this.doipClient.performCompactOperationJsonResponse(revokeTokenRequest, serviceInfoForRequest);
        this.removeToken(userKey, serviceInfoForRequest);
    }

    private async acquireNewToken(authInfo: AuthenticationInfo | unknown, serviceInfo: ServiceInfo): Promise<any> {
        const inputJson: any = await this.createAuthTokenInput(authInfo);
        const tokenRequest: DoipRequest = {
            targetId: serviceInfo?.serviceId || 'service',
            operationId: "20.DOIP/Op.Auth.Token",
            inputJson
        };
        const authTokenResponse = await this.doipClient.performCompactOperationJsonResponse(tokenRequest, serviceInfo);
        return authTokenResponse;
    }

    private getKeyForServiceInfo(serviceInfo: ServiceInfo): string {
        if (serviceInfo.serviceId) {
            return serviceInfo.serviceId;
        }
        if (serviceInfo.baseUri) {
            return serviceInfo.baseUri;
        }
        throw new Error('Unable to get key for serviceInfo. No serviceId or baseUri');
    }

    private userKeyFromDoipRequest(doipRequest: DoipRequest): string | undefined {
        if (doipRequest.clientId) {
            return doipRequest.clientId;
        }
        if (doipRequest.authentication instanceof AuthenticationInfo) {
            return doipRequest.authentication.getUserKey();
        }
        return undefined;
    }

    getAccessToken(): string | undefined {
        if (!this.currentUserKey) {
            return undefined;
        }
        const serviceInfo: ServiceInfo | undefined = this.doipClient.getDefaultServiceInfo();
        const token = this.getTokenFromCache(this.currentUserKey, serviceInfo);
        return token;
    }

    async shutdown(): Promise<void> {
        await this.doipClient.shutdown();
    }
}
