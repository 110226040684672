import { DigitalElement, DigitalElementWithBody } from '@cnri/doip-client';
import { ElementEditor } from "./ElementEditor.js";

export class ElementsEditor {
    private readonly elementEditorsList: ElementEditor[] = [];
    private readonly elementsContainerDiv: JQuery;
    private deletedElements: string[] = [];
    private table!: JQuery;

    constructor(
            containerDiv: JQuery,
            elements: DigitalElement[],
            disabled: boolean,
            allowDownloadElements: boolean
    ) {
        this.buildTable(containerDiv, disabled);

        this.elementsContainerDiv = $("<div></div>");
        containerDiv.append(this.elementsContainerDiv);
        if (elements) {
            for (const element of elements) {
                const elementTr = $("<tr></tr>");
                this.table.append(elementTr);

                const isNew = false;
                const elementEditor = new ElementEditor(
                    this,
                    elementTr,
                    element,
                    disabled,
                    isNew,
                    allowDownloadElements
                );
                this.elementEditorsList.push(elementEditor);
            }
        }
    }

    buildTable(containerDiv: JQuery, disabled: boolean): void {
        this.table = $('<table class="table"></table>');
        this.table.css("margin-bottom", "0");
        containerDiv.append(this.table);

        const colgroup = $(
            '<colgroup><col style="width:25%"><col style="width:25%"><col style="width:20%"><col style="width:15%"><col style="width:15%"></colgroup>'
        );
        this.table.append(colgroup);

        const thead = $("<thead></thead>");
        this.table.append(thead);

        const titleRow = $("<tr></tr>");
        thead.append(titleRow);

        const titleRowColumn = $('<th colspan="5"></th>');
        titleRowColumn.append("<span>Elements</span");
        titleRow.append(titleRowColumn);

        if (!disabled) {
            const addElementButton = $(
                '<button class="btn btn-sm btn-primary"><i class="fa fa-plus"></i></button>'
            );
            titleRowColumn.append(addElementButton);
            addElementButton.on("click", (e) => this.addNewElement(e));

            const addElementButtonSpan = $("<span></span>");
            addElementButton.append(addElementButtonSpan);
            addElementButtonSpan.text("Add");
        }

        const tbody = $("<tbody></tbody>");
        this.table.append(tbody);
    }

    addNewElement(e: JQuery.ClickEvent): void {
        e.preventDefault();
        const elementTr = $("<tr></tr>");
        this.table.append(elementTr);
        const isNew = true;
        const elementEditor = new ElementEditor(this, elementTr, null, false, isNew);
        this.elementEditorsList.push(elementEditor);
    }

    getNextDefaultElementName(): string {
        if (this.elementEditorsList.length === 0) {
            return "element";
        } else {
            let highestElementNameCount = 0;
            for (const elementEditor of this.elementEditorsList) {
                const name = elementEditor.getNameFromInputOrLabel();
                if (name && name.startsWith("element")) {
                    const suffix = name.substring(7);
                    // Convert to number and trim fractional part with double bitwise operator
                    const n = ~~Number(suffix);
                    if (String(n) === suffix && n >= 0) {
                        if (n > highestElementNameCount) {
                            highestElementNameCount = n;
                        }
                    }
                }
            }
            return "element" + (highestElementNameCount + 1);
        }
    }

    deleteElement(elementEditor: ElementEditor): void {
        const i = this.elementEditorsList.indexOf(elementEditor);
        if (i < 0) return;
        this.elementEditorsList.splice(i, 1);
        if (!elementEditor.isNew) {
            if (!this.deletedElements.includes(elementEditor.getName())) {
                this.deletedElements.push(elementEditor.getName());
            }
        }
    }

    getElementsToDelete(): string[] | undefined {
        this.cleanUpDeletedElements();
        if (this.deletedElements.length > 0) {
            return this.deletedElements;
        }
        return undefined;
    }

    getElements(): DigitalElement[] {
        this.cleanUpDeletedElements();
        const elements = [];
        for (const elementEditor of this.elementEditorsList) {
            const blob = elementEditor.getBlob();
            if (blob) {
                elements.push(new DigitalElementWithBody({
                    id: elementEditor.getName(),
                    body: blob,
                    attributes: {
                        filename: blob.name
                    },
                    type: blob.type
                }));
            }
        }
        return elements;
    }

    // clean up deletedElements before building HTTP request, in case someone deletes then re-adds a element of the same name
    cleanUpDeletedElements(): void {
        const map: Record<string, boolean> = {};
        for (const element of this.deletedElements) {
            map[element] = true;
        }
        for (const elementEditor of this.elementEditorsList) {
            delete map[elementEditor.getName()];
        }
        this.deletedElements = Object.keys(map);
    }
}
