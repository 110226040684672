export const JsonUtil = {
    getJsonAtPointer,
    replaceJsonAtPointer,
    getPropertiesNamed,
    decodeJsonPointerSegment,
    jsonPointerToJsonEditorDataSchemaPath,
    jsonPointerToJsonEditorFormName,
    escapeForJQuerySelector,
    inputElementForJsonPointer,
    encodeJsonPointerSegment,
    jsonPointerForElement,
    getDeepProperty
};

function getJsonAtPointer(jsonObject: object, jsonPointer: string): unknown {
    if (!jsonObject || jsonPointer === '') return jsonObject;
    const segments = jsonPointer.split('/').slice(1);
    let node = jsonObject;
    let decodedSegment;
    for (const segment of segments) {
        decodedSegment = decodeJsonPointerSegment(segment);
        node = (node as { [key: string]: unknown })[decodedSegment] as object;
        if (node === undefined) return undefined;
    }
    return node;
}

function replaceJsonAtPointer(jsonObject: object, jsonPointer: string, replacement: unknown): void {
    if (!jsonObject || jsonPointer === '') return;
    const segments = jsonPointer.split('/').slice(1);
    let parent = null;
    let child = jsonObject;
    let decodedSegment = undefined;
    for (const segment of segments) {
        decodedSegment = decodeJsonPointerSegment(segment);
        parent = child;
        child = (parent as { [key: string]: unknown })[decodedSegment] as object;
        if (child === undefined) return;
    }
    if (!decodedSegment) return;
    (parent as { [key: string]: unknown })[decodedSegment] = replacement;
}

//Recursively retrieves all properties and sub properties with the given property name.
function getPropertiesNamed(targetPropertyName: string, jsonObject: unknown): unknown[] {
    let result: unknown[] = [];
    if (typeof jsonObject === 'object') {
        for (const propertyName in jsonObject) {
            const property = (jsonObject as { [key: string]: unknown })[propertyName];
            if (targetPropertyName === propertyName) {
                result.push(property);
            } else if (typeof property === 'object' || property instanceof Array) {
                const subResult = getPropertiesNamed(targetPropertyName, property);
                result = result.concat(subResult);
            }
        }
    } else if (jsonObject instanceof Array) {
        for (const element of jsonObject) {
            if (typeof element === 'object' || element instanceof Array) {
                const subResult = getPropertiesNamed(targetPropertyName, element);
                result = result.concat(subResult);
            }
        }
    }
    return result;
}

function decodeJsonPointerSegment(segment: string): string {
    return segment.replace(/~1/g, "/").replace(/~0/g, "~");
}

function jsonPointerToJsonEditorDataSchemaPath(jsonPointer: string): string {
    return "root" + jsonPointer.replace(/\//g, ".").replace(/~1/g, "/").replace(/~0/g, "~");
}

function jsonPointerToJsonEditorFormName(jsonPointer: string): string {
    return "root" + jsonPointer.replace(/\//g, ".").replace(/~1/g, "/").replace(/~0/g, "~").replace(/\.([^.]+)/g, '[$1]');
}

function escapeForJQuerySelector(str: string): string {
    if (!str) return str;
    return str.replace(/([ #;?%&,.+*~':"!^$[\]()=>|/@])/g, '\\$1');
}

function inputElementForJsonPointer(container: JQuery, jsonPointer: string): JQuery {
    let selector = '[name="' + escapeForJQuerySelector(jsonPointerToJsonEditorFormName(jsonPointer)) + '"]';
    let res = container.find(selector);
    while (res.length >= 2) {
        selector = '[data-schemapath="' + escapeForJQuerySelector(jsonPointerToJsonEditorDataSchemaPath(jsonPointer)) + '"] ' + selector;
        res = container.find(selector);
        if (res.length <= 1 || jsonPointer.length === 0) break;
        jsonPointer = jsonPointer.replace(/\/[^/]*$/, '');
    }
    return res;
}

function encodeJsonPointerSegment(segment: string): string {
    return segment.replace(/~/g, "~0").replace(/\//g, "~1");
}

function jsonPointerForElement(element: HTMLElement | JQuery): string {
    const container = $(element).closest('[data-schemapath]');
    const path = container.attr('data-schemapath');
    if (path === 'root' || !path) return '';
    const parent = container.parent().closest('[data-schemapath]');
    const parentPath = parent.attr('data-schemapath');
    if (path === parentPath) return jsonPointerForElement(parent);
    const key = path.substring(parentPath!.length + 1);
    return jsonPointerForElement(parent) + '/' + JsonUtil.encodeJsonPointerSegment(key);
}

function getDeepProperty(obj: unknown, ...args: string[]): unknown {
    for (const arg of args) {
        if (typeof obj === 'object') {
            obj = (obj as { [key: string]: unknown })[arg];
        } else {
            return null;
        }
    }
    return obj;
}
