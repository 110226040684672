import { html, render, HTMLTemplateResult } from 'lit-html';
import { ref } from 'lit-html/directives/ref.js';
import { FacetFieldConfig } from '../../cordra/CordraUiMain.js';
import { FacetOptionComponent } from './FacetOptionComponent.js';

export class FilterSelectComponent {
    private readonly container: HTMLElement | undefined;
    private readonly getQueryCallback: (() => string | undefined) | undefined;
    private readonly filtersChangeCallback: (filters: string[]) => void;
    private readonly filters: string[];
    private readonly template: () => HTMLTemplateResult;
    private types: string[];

    constructor(container: Element | undefined, currentTypes: string[], currentFilters: string[], getQueryCallback: (() => (string | undefined)) | undefined, filtersChangeCallback: (filters: string[]) => void) {
        this.container = container as HTMLElement | undefined;
        this.types = currentTypes || [];
        this.filters = currentFilters || [];
        this.getQueryCallback = getQueryCallback;
        this.filtersChangeCallback = filtersChangeCallback;
        this.template = () => {
            const fieldConfigs = this.getFieldConfigsForTypes(this.types);
            return html`
                <div class="col-md-12 nopadding" @change=${() => this.notifyFiltersChanged()}>
                    <form class="form-inline" ?hidden="${fieldConfigs.length === 0}">
                        Filters:
                        ${fieldConfigs.map(
                            (fieldConfig) => html`
                                <div ${ref(el => new FacetOptionComponent(el, fieldConfig, this.types, this.filters, this.getQueryCallback))}>
                                </div>`
                        )}
                    </form>
                </div>
            `;
        };
        this.render();
    }

    private render(): void {
        if (!this.container) return;
        render(this.template(), this.container);
    }

    getFieldConfigsForTypes(types: string[] | null): FacetFieldConfig[] {
        const fields = new Set<FacetFieldConfig>();
        for (const type of types || []) {
            const uiConfig = APP.getSchemaUiConfig(type);
            uiConfig?.facetFields?.forEach(f => fields.add(f));
        }
        return Array.from(fields);
    }

    private notifyFiltersChanged() {
        const checkboxes = this.container?.getElementsByTagName('input') || [];
        const filters = [];
        for (const checkbox of checkboxes) {
            if (checkbox.checked && checkbox.value) {
                filters.push(checkbox.value);
            }
        }
        this.filtersChangeCallback(filters);
    }
}
