import { JsonWebKey } from "./JsonWebKey.js";
import { Encoder } from './encoder/index.js';

export const EncryptionUtil = {
    getPrivateKeyFromBytes,
    getPublicKeyFromBytes,
    requiresSecretKey,
    decryptPrivateKeyAes,
    stripContainerFromUnencryptedData
};

export type JsonWebKeyWithG = JsonWebKey & { g: string };

const KEY_ENCODING_DSA_PRIVATE = Encoder.Utf8.decode("DSA_PRIV_KEY");
const KEY_ENCODING_RSA_PRIVATE = Encoder.Utf8.decode("RSA_PRIV_KEY");
const KEY_ENCODING_RSACRT_PRIVATE = Encoder.Utf8.decode("RSA_PRIVCRT_KEY");

const KEY_ENCODING_DSA_PUBLIC = Encoder.Utf8.decode("DSA_PUB_KEY");
const KEY_ENCODING_RSA_PUBLIC = Encoder.Utf8.decode("RSA_PUB_KEY");
const KEY_ENCODING_DH_PUBLIC = Encoder.Utf8.decode("DH_PUB_KEY");

const DEFAULT_E = new Uint8Array([1, 0, 1]);

// const ENCRYPT_DES_ECB_PKCS5 = 0; // DES with ECB and PKCS5 padding
const ENCRYPT_NONE = 1; // no encryption
// const ENCRYPT_DES_CBC_PKCS5 = 2; // DES with CBC and PKCS5 padding
// const ENCRYPT_AES_CBC_PKCS5 = 4; // AES with CBC and PKCS5 padding

function getPrivateKeyFromBytes(pkBuf: Uint8Array, offset?: number): JsonWebKeyWithG {
    if (!offset) offset = 0;
    const privateKey = {} as JsonWebKeyWithG;
    const view = new DataView(pkBuf.buffer, pkBuf.byteOffset, pkBuf.byteLength);
    let bytesAndOffset = readBytes(view, offset);
    const keyType = bytesAndOffset.bytes;

    if (compareArrays(keyType, KEY_ENCODING_DSA_PRIVATE)) {
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const x = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const p = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const q = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const g = bytesAndOffset.bytes;
        privateKey.x = Encoder.Base64.encodeUrlSafe(unsigned(x));
        privateKey.p = Encoder.Base64.encodeUrlSafe(unsigned(p));
        privateKey.q = Encoder.Base64.encodeUrlSafe(unsigned(q));
        privateKey.g = Encoder.Base64.encodeUrlSafe(unsigned(g));
        privateKey.kty = "DSA";
    } else if (compareArrays(keyType, KEY_ENCODING_RSA_PRIVATE)) {
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const m = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const exp = readBytes(view, bytesAndOffset.offset);
        privateKey.n = Encoder.Base64.encodeUrlSafe(unsigned(m));
        privateKey.d = Encoder.Base64.encodeUrlSafe(unsigned(exp.bytes));
        privateKey.e = Encoder.Base64.encodeUrlSafe(DEFAULT_E);
        privateKey.kty = "RSA";
    } else if (compareArrays(keyType, KEY_ENCODING_RSACRT_PRIVATE)) {
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const n = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const pubEx = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const ex = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const p = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const q = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const exP = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const exQ = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const coeff = bytesAndOffset.bytes;
        privateKey.n = Encoder.Base64.encodeUrlSafe(unsigned(n));
        privateKey.d = Encoder.Base64.encodeUrlSafe(unsigned(ex));
        privateKey.e = Encoder.Base64.encodeUrlSafe(unsigned(pubEx));

        privateKey.p = Encoder.Base64.encodeUrlSafe(unsigned(p));
        privateKey.q = Encoder.Base64.encodeUrlSafe(unsigned(q));
        privateKey.dp = Encoder.Base64.encodeUrlSafe(unsigned(exP));
        privateKey.dq = Encoder.Base64.encodeUrlSafe(unsigned(exQ));
        privateKey.qi = Encoder.Base64.encodeUrlSafe(unsigned(coeff));
        privateKey.kty = "RSA";
    }
    return privateKey;
}

function getPublicKeyFromBytes(pkBuf: Uint8Array, offset?: number): JsonWebKeyWithG {
    if (!offset) offset = 0;
    const publicKey = {} as JsonWebKeyWithG;
    const view = new DataView(pkBuf.buffer, pkBuf.byteOffset, pkBuf.byteLength);
    let bytesAndOffset = readBytes(view, offset);
    const keyType = bytesAndOffset.bytes;
    bytesAndOffset.offset = bytesAndOffset.offset + 2; //skip unused flags
    if (compareArrays(keyType, KEY_ENCODING_DSA_PUBLIC)) {
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const q = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const p = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const g = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const y = bytesAndOffset.bytes;
        publicKey.q = Encoder.Base64.encodeUrlSafe(unsigned(q));
        publicKey.p = Encoder.Base64.encodeUrlSafe(unsigned(p));
        publicKey.g = Encoder.Base64.encodeUrlSafe(unsigned(g));
        publicKey.y = Encoder.Base64.encodeUrlSafe(unsigned(y));
        publicKey.kty = "DSA";
    } else if (compareArrays(keyType, KEY_ENCODING_RSA_PUBLIC)) {
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const ex = bytesAndOffset.bytes;
        bytesAndOffset = readBytes(view, bytesAndOffset.offset);
        const m = bytesAndOffset.bytes;

        publicKey.n = Encoder.Base64.encodeUrlSafe(unsigned(m));
        publicKey.e = Encoder.Base64.encodeUrlSafe(unsigned(ex));
        publicKey.kty = "RSA";
    } else if (compareArrays(keyType, KEY_ENCODING_DH_PUBLIC)) {
        throw { name: "EncryptionError", message: "KEY_ENCODING_DH_PUBLIC key type not supported" };
    }
    return publicKey;
}

function requiresSecretKey(ciphertext: Uint8Array): boolean {
    const encryptionType = readInt(ciphertext, 0);
    return encryptionType !== ENCRYPT_NONE;
}

function decryptPrivateKeyAes(data: Uint8Array, passPhrase: string): Promise<ArrayBuffer> {
    const INT_SIZE = 4;
    let offset = 4;
    const view = new DataView(data.buffer, data.byteOffset, data.byteLength);
    let bytesAndOffset = readBytes(view, offset);
    const salt = bytesAndOffset.bytes;
    offset = offset + INT_SIZE + salt.length;

    const iterations = view.getInt32(offset);
    offset = offset + INT_SIZE;

    const keyLength = view.getInt32(offset);
    offset = offset + INT_SIZE;

    bytesAndOffset = readBytes(view, offset);
    const iv = bytesAndOffset.bytes;
    offset = offset + INT_SIZE + iv.length;

    bytesAndOffset = readBytes(view, offset);
    const ciphertext = bytesAndOffset.bytes;

    const passPhraseBytes = Encoder.Utf8.decode(passPhrase);

    return crypto.subtle.importKey('raw', passPhraseBytes, { name: 'PBKDF2' }, false, ['deriveBits', 'deriveKey'])
        .then((passPhraseKey) => {
            const deriveAlg = {
                name: 'PBKDF2',
                salt,
                iterations,
                hash: 'SHA-1'
            };
            return window.crypto.subtle.deriveKey(deriveAlg, passPhraseKey, {
                name: 'AES-CBC',
                length: keyLength
            }, false, ['decrypt']);
        }).then((keyDerivedFromPassPhrase) => {
            const alg = { name: 'AES-CBC', iv };
            return crypto.subtle.decrypt(alg, keyDerivedFromPassPhrase, ciphertext);
        });
}

function stripContainerFromUnencryptedData(data: Uint8Array): Uint8Array {
    return data.subarray(4);
}

function compareArrays(a: Uint8Array, b: Uint8Array): boolean {
    if (!b) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function readBytes(view: DataView, offset: number): { offset: number; bytes: Uint8Array } {
    const len = view.getInt32(offset);
    if (len < 0 || offset + 4 + len > view.byteLength) throw { name: "HsEncoderError", message: "bad string length" };
    const arr = new Uint8Array(view.buffer, view.byteOffset + offset + 4, len);
    return { offset: offset + 4 + len, bytes: arr };
}

function readInt(buf: Uint8Array, offset: number) {
    return buf[offset] << 24 |
        (0x00ff & buf[offset + 1]) << 16 |
        (0x00ff & buf[offset + 2]) << 8 |
        (0x00ff & buf[offset + 3]);
}

function unsigned(arr: Uint8Array): Uint8Array {
    if (arr.length === 0) return new Uint8Array(1);
    let zeros = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === 0) zeros++;
        else break;
    }
    if (zeros === arr.length) zeros--;
    if (zeros === 0) return arr;
    return new Uint8Array(arr.buffer, arr.byteOffset + zeros, arr.length - zeros);
}
