/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ProgressCallback
} from '@cnri/cordra-client';

import {
    AuthenticationInfo,
    DigitalObject,
    DoipClientResponse,
    DoipRequest,
    HttpDoipClient,
    PasswordAuthenticationInfo,
    RequestOptions,
    Facet,
    QueryParams,
    SearchResponse,
    SortField
} from '@cnri/doip-client';

import { JsonSchema } from 'tv4';
import { default as JsonEditorOnline } from 'jsoneditor';
import { AuthenticatorWidget, CustomAuthenticationConfig } from '../AuthenticatorWidget.js';
import { SubscriptionBanner } from '../SubscriptionBanner.js';
import { SchemasEditor } from '../admin/SchemasEditor.js';
import { UiConfigEditor } from '../admin/UiConfigEditor.js';
import { AuthConfigEditor } from '../admin/AuthConfigEditor.js';
import { HandleMintingConfig, HandleMintingConfigEditor } from '../admin/HandleMintingConfigEditor.js';
import { NetworkAndSecurity } from '../admin/NetworkAndSecurity.js';
import { BatchDelete } from '../admin/BatchDelete.js';
import { BatchUpload } from '../admin/BatchUpload.js';
import { DesignJavaScriptEditor } from '../admin/DesignJavaScriptEditor.js';
import { RelationshipResponse } from '../components/RelationshipsGraphComponent.js';
import { ObjectPreviewUtil } from '../ObjectPreviewUtil.js';
import { FacetBucketWithUi } from '../components/search/FacetOptionComponent.js';
import { SearchComponent } from '../components/search/SearchComponent.js';
import { HtmlPageViewer } from './HtmlPageViewer.js';
import { AboutInfo } from './AboutInfo.js';
import { NavBar, NavLink } from './NavBar.js';
import { SchemaExtractorFactory } from './SchemaExtractor.js';
import { Notifications } from './ToastrNotifications.js';
import ObjectEditor from './ObjectEditor.js';
import { ModalYesNoDialog } from './ModalYesNoDialog.js';
import { JsonUtil } from './JsonUtil.js';
import { InitData } from './InitData.js';
import { CordraUiSupport } from './CordraUiSupport.js';
import { TokenUsingDoipClient } from './TokenUsingDoipClient.js';
import { CordraDoipOperations } from './CordraDoipOperations.js';
import { ObjectConvertUtil } from './ObjectConvertUtil.js';
import { VersionInfo } from "./VersionInfo.js";
import { AccessControlList } from "./AccessControlList.js";
import { AuthResponse } from "./AuthResponse.js";

interface SchemaUiConfig {
    facetFields?: FacetFieldConfig[];
}

export interface FacetFieldConfig {
    displayName: string;
    path: string;
}

export class CordraUiMain {
    private baseUri: string;
    private schemasByName: Record<string, JsonSchema> = {};
    private schemaIdToNameMap: Record<string, string> = {};
    private schemaUiConfigs: Record<string, SchemaUiConfig> = {};
    private baseUriToNameMap: Record<string, string> = {};
    private nameToBaseUriMap: Record<string, string> = {};
    private refToNormalizedSchemaMap: Record<string, JsonSchema | undefined> = {};
    // TODO once APP.search is async-ified, make this private again and use APP.search instead
    readonly doipClient: TokenUsingDoipClient;
    private cordraDoipOps: CordraDoipOperations;

    private uiConfig: Record<string, any> = {};
    private version: Record<string, string> = {};
    private handleMintingPrefix?: string;
    readonly notifications: Notifications;
    private searchWidget!: SearchComponent;
    private objectId?: string;
    private editor?: ObjectEditor;
    private fragmentJustSetInCreate?: string;

    private readonly editorDiv: JQuery;
    private readonly searchDiv: JQuery;
    private readonly htmlContentDiv: JQuery;
    private readonly aboutInfoDiv: JQuery;
    private navBar!: NavBar;

    private authConfig: unknown;

    private design!: Record<string, unknown>;

    private authWidget!: AuthenticatorWidget;

    // Admin sections
    private readonly schemasDiv: JQuery;
    private schemasEditor!: SchemasEditor;
    private uiConfigEditor!: UiConfigEditor;
    private readonly uiConfigDiv: JQuery;
    private readonly authConfigDiv: JQuery;
    private authConfigEditor!: AuthConfigEditor;
    private readonly handleMintingConfigDiv: JQuery;
    private handleMintingConfigEditor!: HandleMintingConfigEditor;
    private readonly designJavaScriptDiv: JQuery;
    private designJavaScriptEditor!: DesignJavaScriptEditor;
    private readonly networkAndSecurityDiv: JQuery;
    private networkAndSecurity!: NetworkAndSecurity;
    private readonly batchDeleteDiv: JQuery;
    private batchDelete!: BatchDelete;
    private readonly batchUploadDiv: JQuery;
    private batchUpload!: BatchUpload;
    private readonly sections: Record<string, JQuery> = {};

    private readonly uiSupport: CordraUiSupport;

    constructor(baseUri: string) {
        this.baseUri = this.ensureSlash(baseUri);
        const doipBaseUri = baseUri + '/doip/';
        const httpDoipClient = new HttpDoipClient(doipBaseUri);
        this.doipClient = new TokenUsingDoipClient(httpDoipClient);
        const currentUserKey = this.loadCurrentUserKey();
        this.doipClient.setCurrentUserKey(currentUserKey);
        this.cordraDoipOps = new CordraDoipOperations(this.doipClient);

        this.editorDiv = $('#editor');
        this.searchDiv = $('#search');
        this.htmlContentDiv = $('#htmlContent');
        this.aboutInfoDiv = $('#aboutInfo');

        // admin sections
        this.schemasDiv = $('#schemas');
        this.uiConfigDiv = $('#ui');
        this.authConfigDiv = $('#authConfig');
        this.handleMintingConfigDiv = $('#handleRecords');
        this.networkAndSecurityDiv = $('#networkAndSecurity');
        this.designJavaScriptDiv = $('#designJavaScript');
        this.batchDeleteDiv = $('#batchDelete');
        this.batchUploadDiv = $('#batchUpload');

        this.sections.schemas = this.schemasDiv;
        this.sections.ui = this.uiConfigDiv;
        this.sections.authConfig = this.authConfigDiv;
        this.sections.handleRecords = this.handleMintingConfigDiv;
        this.sections.networkAndSecurity = this.networkAndSecurityDiv;
        this.sections.designJavaScript = this.designJavaScriptDiv;
        this.sections.batchDelete = this.batchDeleteDiv;
        this.sections.batchUpload = this.batchUploadDiv;

        this.notifications = new Notifications();
        this.uiSupport = new CordraUiSupport(this.doipClient, this.notifications, () => this.displaySignInForm());

        this.getInitData()
        .catch(console.error);
        this.onErrorResponse = this.onErrorResponse.bind(this);
    }

    async getInitData(): Promise<void> {
        try {
            const initData = await this.cordraDoipOps.getInitData();
            this.onGotInitData(initData);
        } catch (error) {
            this.clearCurrentUserKey();
            const initData = await this.cordraDoipOps.getInitData();
            this.onGotInitData(initData);
        }
    }

    ensureSlash(baseUri: string): string {
        if (baseUri.slice(-1) !== '/') {
            baseUri = baseUri + '/';
        }
        return baseUri;
    }

    getBaseUri(): string {
        return this.baseUri;
    }

    getUiSupport(): CordraUiSupport {
        return this.uiSupport;
    }

    displaySignInForm(): void {
        this.authWidget.onSignInClick();
    }

    loadCurrentUserKey(): string | undefined {
        const currentUserKey = localStorage.getItem("currentUserKey");
        if (currentUserKey === null) {
            return undefined;
        } else {
            return currentUserKey;
        }
    }

    storeCurrentUserKey(userKey: string | undefined): void {
        if (userKey) {
            localStorage.setItem('currentUserKey', userKey);
        } else {
            localStorage.removeItem('currentUserKey');
        }
        this.doipClient.setCurrentUserKey(userKey);
    }

    clearCurrentUserKey(): void {
        this.storeCurrentUserKey(undefined);
    }

    checkForDuplicateSchemaNames(schemaIds: Record<string, string>): void {
        const nameToIdMap: Record<string, string[]> = {};
        for (const schemaId in schemaIds) {
            const name = schemaIds[schemaId];
            let idsForName = nameToIdMap[name];
            if (!idsForName) {
                idsForName = [];
                nameToIdMap[name] = idsForName;
            }
            idsForName.push(schemaId);
        }
        for (const schemaName in nameToIdMap) {
            const idsForSchemaName = nameToIdMap[schemaName];
            if (idsForSchemaName.length > 1) {
                const message = `More than one schema has the same name '${schemaName}': ${JSON.stringify(idsForSchemaName)}`;
                this.notifications.alertError(message);
                break;
            }
        }
    }

    initializeSchemas(response: InitData): void {
        this.design.schemas = response.design.schemas;
        this.schemasByName = response.design.schemas;
        this.schemaIdToNameMap = response.design.schemaIds;
        this.schemaUiConfigs = response.design.schemaUiConfigs;
        this.checkForDuplicateSchemaNames(this.schemaIdToNameMap);
        this.baseUriToNameMap = {};
        for (const key in response.design.baseUriToNameMap) {
            this.baseUriToNameMap[this.makeBaseUriAbsolute(key)] = response.design.baseUriToNameMap[key];
        }
        this.nameToBaseUriMap = {};
        for (const key in response.design.nameToBaseUriMap) {
            this.nameToBaseUriMap[key] = this.makeBaseUriAbsolute(response.design.nameToBaseUriMap[key] as string);
        }
        this.normalizeSchemasAndUpdateRefToNormalizedSchemaMap();
        SchemaExtractorFactory.newSchemas(this.refToNormalizedSchemaMap);
    }

    onGotInitData(initData: InitData): void {
        this.processInitDataResponse(initData);
        if (!initData.isActiveSession) {
            this.clearCurrentUserKey();
        }
        new SubscriptionBanner(
            $('#subscriptionBanner'),
            this.handleMintingPrefix
        );
        let enableFacetedSearch = false;
        if (this.uiConfig.enableFacetedSearch) {
            enableFacetedSearch = this.uiConfig.enableFacetedSearch;
        }
        this.searchWidget = new SearchComponent(this.searchDiv[0], enableFacetedSearch);
        const allowLogin = this.checkIfLoginAllowed(initData.design.allowInsecureAuthentication as boolean);
        this.authWidget = new AuthenticatorWidget(
            $('#authenticateDiv'),
            () => this.onAuthenticationStateChange(),
            initData.isActiveSession,
            initData.username,
            initData.userId,
            initData.typesPermittedToCreate,
            allowLogin,
            this.uiConfig.customAuthentication as CustomAuthenticationConfig
        );
        this.onAuthenticationStateChange();

        // At this point, the editor and objectId are still null, so onAuthenticationStateChange will
        // call handleNewWindowLocation for us
        window.onhashchange = () => this.handleOnhashchange();
    }

    processInitDataResponse(response: InitData): void {
        if (!response.design.uiConfig) {
            response.design.uiConfig = {
                title: 'Cordra',
                navBarLinks: []
            };
        }
        if (!response.design.handleMintingConfig) {
            response.design.handleMintingConfig = {
                prefix: 'test'
            };
        }
        if (!response.design.authConfig) {
            response.design.authConfig = {};
        }
        this.design = response.design;
        this.uiConfig = response.design.uiConfig;
        this.version = response.version;
        this.initializeSchemas(response);

        this.authConfig = response.design.authConfig;
        this.handleMintingPrefix = response.design.handleMintingConfig.prefix;

        $('.navbar-brand').text(this.uiConfig.title as string);
        $('title').text(this.uiConfig.title as string);

        const navBarElement = $('#navBar');
        navBarElement.empty();
        this.navBar = new NavBar(
            navBarElement,
            this.uiConfig.navBarLinks as NavLink[],
            this.schemasByName,
            this.design.schemaIds as Record<string, string>,
            response.isActiveSession
        );

        const isAdminDisabled = !(
            response.isActiveSession && response.username === 'admin'
        );
        this.buildAdminWidgets(isAdminDisabled);

        if (response.userId === 'admin') {
            this.enableAdminControls();
        } else {
            this.disableAdminControls();
        }
    }

    checkIfLoginAllowed(allowInsecureLogin: boolean): boolean {
        if (allowInsecureLogin) return true;
        return location.protocol === 'https:';
    }

    buildAdminWidgets(isAdminDisabled: boolean): void {
        this.schemasDiv.empty();
        this.schemasEditor = new SchemasEditor(
            this.schemasDiv,
            this.design.schemas as Record<string, JsonSchema>,
            this.design.schemaIds as Record<string, string>,
            isAdminDisabled
        );
        this.uiConfigDiv.empty();
        this.uiConfigEditor = new UiConfigEditor(
            this.uiConfigDiv,
            this.design.uiConfig,
            isAdminDisabled
        );
        this.authConfigDiv.empty();
        this.authConfigEditor = new AuthConfigEditor(
            this.authConfigDiv,
            this.design.authConfig,
            isAdminDisabled
        );
        this.handleMintingConfigDiv.empty();
        this.handleMintingConfigEditor = new HandleMintingConfigEditor(
            this.handleMintingConfigDiv,
            this.design.handleMintingConfig as HandleMintingConfig,
            isAdminDisabled
        );
        this.networkAndSecurityDiv.empty();
        this.networkAndSecurity = new NetworkAndSecurity(
            this.networkAndSecurityDiv,
            isAdminDisabled
        );
        this.designJavaScriptDiv.empty();
        this.designJavaScriptEditor = new DesignJavaScriptEditor(
            this.designJavaScriptDiv,
            this.design,
            isAdminDisabled
        );
        //this.batchDeleteDiv.empty();
        this.batchDelete = new BatchDelete(
            this.batchDeleteDiv[0],
            isAdminDisabled
        );
        this.batchUpload = new BatchUpload(
            this.batchUploadDiv[0],
            isAdminDisabled
        );
    }

    onAuthenticationStateChange(): void {
        const userId = this.authWidget.getCurrentUserId();
        if (userId === 'admin') {
            this.enableAdminControls();
        } else {
            this.disableAdminControls();
        }
        if (this.editor && this.objectId) {
            const currentObjectId = this.objectId;
            this.hideObjectEditor();
            this.resolveHandle(currentObjectId);
        } else {
            this.handleNewWindowLocation();
        }
        this.refreshDesign();
    }

    disableAdminControls(): void {
        this.navBar.hideAdminMenu();
        this.uiConfigEditor.disable();
        this.authConfigEditor.disable();
        this.handleMintingConfigEditor.disable();
        this.networkAndSecurity.disable();
        this.schemasEditor.disable();
        this.designJavaScriptEditor.disable();
        this.batchDelete.disable();
        this.batchUpload.disable();
    }

    enableAdminControls(): void {
        this.navBar.showAdminMenu();
        this.uiConfigEditor.enable();
        this.authConfigEditor.enable();
        this.handleMintingConfigEditor.enable();
        this.networkAndSecurity.enable();
        this.schemasEditor.enable();
        this.designJavaScriptEditor.enable();
        this.batchDelete.enable();
        this.batchUpload.enable();
    }

    handleOnhashchange(): void {
        this.handleNewWindowLocation();
    }

    handleNewWindowLocation(): void {
        const fragment = window.location.hash.substring(1);
        const expectedFragment = this.fragmentJustSetInCreate;
        delete this.fragmentJustSetInCreate;
        if (fragment === expectedFragment) {
            return;
        }

        this.htmlContentDiv.hide();
        this.aboutInfoDiv.hide();
        this.searchWidget.hideResults();
        this.hideObjectEditor();
        this.hideAllAdminSections();

        if (fragment && fragment !== '') {
            if (!fragment.startsWith('objects/?query=')) {
                this.searchWidget.clearInput();
            }
            if (fragment.startsWith('objects/')) {
                if (fragment.startsWith('objects/?query=')) {
                    const params = this.getParamsFromFragment(fragment);
                    const fragmentQuery = params.query;
                    const sortFieldsString = params.sortFields;
                    let sortFields;
                    if (sortFieldsString) {
                        sortFields = JSON.parse(sortFieldsString) as SortField[];
                    }
                    const filterQueriesString = params.filterQueries;
                    let filterQueries;
                    if (filterQueriesString) {
                        filterQueries = JSON.parse(filterQueriesString) as string[];
                    }
                    this.searchWidget.search(fragmentQuery, sortFields, filterQueries);
                } else {
                    const fragmentObjectId = this.getObjectIdFromFragment(fragment);
                    if (fragmentObjectId != null && fragmentObjectId !== '') {
                        if (fragmentObjectId !== this.objectId) {
                            this.resolveHandle(fragmentObjectId);
                        }
                    }
                }
            } else if (fragment.startsWith('urls/')) {
                const url = this.getUrlFromFragment(fragment);
                this.showHtmlPageFor({ url });
            } else if (fragment.startsWith('pages/')) {
                const url = this.getPageUrlFromFragment(fragment);
                this.showHtmlPageFor({ url });
            } else if (fragment.startsWith('about/')) {
                this.showAboutInfo();
            } else if (fragment.startsWith('create/')) {
                const prefix = 'create/';
                const type = decodeURIComponent(fragment.substring(prefix.length));
                this.createNewObject(type);
            } else if (this.sections[fragment]) {
                this.sections[fragment].show();
            }
        } else if (
            !window.location.href.includes('#') &&
            this.uiConfig.initialFragment
        ) {
            window.location.hash = this.uiConfig.initialFragment;
        }
    }

    hideAllAdminSections(): void {
        for (const id in this.sections) {
            this.sections[id].hide();
        }
    }

    encodeURIComponentPreserveSlash(s: string): string {
        return encodeURIComponent(s).replace(/%2F/gi, '/');
    }

    setCreateInFragment(type: string): void {
        window.location.hash = 'create/' + this.encodeURIComponentPreserveSlash(type);
    }

    setObjectIdInFragment(objectId?: string): void {
        if (!objectId) return;
        window.location.hash = 'objects/' + this.encodeURIComponentPreserveSlash(objectId);
    }

    setQueryInFragment(query: string, sortFields?: SortField[], filters?: string[]): void {
        let fragment = 'objects/?query=' + this.encodeURIComponentPreserveSlash(query);
        if (sortFields && sortFields.length > 0) {
            const sortFieldsString = this.encodeURIComponentPreserveSlashForSortFields(sortFields);
            fragment += '&sortFields=' + sortFieldsString;
        }
        if (filters && filters.length > 0) {
            const filterFragment = [];
            for (const filter of filters) {
                filterFragment.push(decodeURIComponent(filter));
            }
            fragment += `&filterQueries=${JSON.stringify(filterFragment)}`;
        }
        window.location.hash = fragment;
    }

    encodeURIComponentPreserveSlashForSortFields(sortFields: SortField[]): string {
        const resultSortFields = [];
        for (const sortField of sortFields) {
            const resultSortField = {
                name: this.encodeURIComponentPreserveSlash(sortField.name),
                reverse: !!sortField.reverse
            };
            resultSortFields.push(resultSortField);
        }
        return JSON.stringify(resultSortFields);
    }

    clearFragment(): void {
        window.location.hash = '';
    }

    performSearchWidgetSearch(query?: string, sortFields?: SortField[], filterQueries?: string[]): void {
        const finalQuery = query ?? this.searchWidget.getQuery();
        const finalSortFields = sortFields ?? this.searchWidget.getSortFields();
        const finalFilterQueries = filterQueries ?? this.searchWidget.getFilterQueries();
        if (!finalQuery) return;
        this.hideHtmlContent();
        this.hideAboutInfo();
        this.setQueryInFragment(finalQuery, finalSortFields, finalFilterQueries);
    }

    hideObjectEditor(): void {
        delete this.objectId;
        if (this.editor) this.editor.destroy();
        this.editorDiv.empty();
        this.editorDiv.hide();
        delete this.editor;
    }

    hideHtmlContent(): void {
        this.htmlContentDiv.empty();
        this.htmlContentDiv.hide();
    }

    hideAboutInfo(): void {
        this.aboutInfoDiv.hide();
    }

    showHtmlPageFor(options: { url: string | undefined; embedded?: boolean }): void {
        delete this.objectId;
        if (this.editor) this.editor.destroy();
        this.editorDiv.empty();
        this.editorDiv.hide();
        this.htmlContentDiv.show();
        new HtmlPageViewer(this.htmlContentDiv, options);
    }

    showAboutInfo(): void {
        delete this.objectId;
        if (this.editor) this.editor.destroy();
        this.editorDiv.empty();
        this.editorDiv.hide();
        new AboutInfo(this.aboutInfoDiv[0], this.version);
        this.aboutInfoDiv.show();
    }

    getUiConfig(): Record<string, any> {
        return this.uiConfig;
    }

    getPrefix(): string | undefined {
        return this.handleMintingPrefix;
    }

    getSchema(type: string): JsonSchema | undefined {
        return this.schemasByName[type];
    }

    getSchemaCount(): number {
        return Object.keys(this.schemasByName).length;
    }

    getSchemaNames(): string[] {
        return Object.keys(this.schemasByName);
    }

    getSchemaUiConfig(type: string): SchemaUiConfig | undefined {
        return this.schemaUiConfigs[type];
    }

    createNewObject(type: string): void {
        this.editorDiv.empty();
        delete this.objectId;
        const allowEdits = true;
        const allowDownloadElements = true;
        const digitalObject: DigitalObject = {
            type,
            attributes: {
                content: {}
            }
        };
        const options = {
            digitalObject,
            schema: this.schemasByName[type],
            type,
            objectJson: {},
            objectId: undefined,
            disabled: false,
            allowEdits,
            allowDownloadElements
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
        this.editorDiv.show();
    }

    resolveHandle(objectId?: string): void {
        if (!objectId) return;
        this.doipClient.retrieve(objectId)
            .then((digitalObject) => {
                if (!digitalObject) {
                    const message = "Missing object: " + objectId;
                    this.onErrorResponse(message);
                } else {
                    this.onGotObject(digitalObject!);
                }
            })
            .catch(this.onErrorResponse);
    }

    // Just gets an object by id, does not start editing that object
    getObject(objectId: string, successCallback: (digitalObject: DigitalObject) => void, errorCallback: (err: any) => void): void {
        this.doipClient
            .retrieve(objectId)
            .then((digitalObject) => {
                successCallback(digitalObject!);
            })
            .catch(errorCallback);
    }

    getElementContent(
            objectId: string | undefined,
            payloadName: string,
            successCallBack: (segment: Body) => void,
            errorCallback: (err: any) => void
    ): void {
        if (!objectId) return;
        this.doipClient.retrieveElement(objectId, payloadName)
            .then((resp) => {
                if (!resp) errorCallback(new Error("No such element"));
                else successCallBack(resp);
            })
            .catch(errorCallback);
    }

    getAclForCurrentObject(onGotAclSuccess: (acl: AccessControlList) => void): void {
        if (!this.objectId) return;
        this.cordraDoipOps.getAcls(this.objectId)
            .then((acl) => {
                onGotAclSuccess(acl);
            })
            .catch(this.onErrorResponse);
    }

    saveAclForCurrentObject(
            newAcl: AccessControlList,
            onSuccess?: (acl: AccessControlList) => void,
            onFail?: (err: any) => void
    ): void {
        if (!this.objectId) return;
        this.notifications.clear();
        this.cordraDoipOps.updateAcls(this.objectId, newAcl)
            .then((res) => {
                this.notifications.alertSuccess('ACL for Object ' + this.objectId + ' saved.');
                if (onSuccess) onSuccess(res);
            })
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    onGotObject(digitalObject: DigitalObject): void {
        this.notifications.clear();
        this.editorDiv.empty();
        this.editorDiv.show();
        this.objectId = digitalObject.id!;
        const type = digitalObject.type!;
        let permission;
        if (digitalObject.attributes.responseContext) {
            permission = (digitalObject.attributes.responseContext as Record<string, any>).permission;
            delete digitalObject.attributes.responseContext;
        }

        this.setObjectIdInFragment(this.objectId);
        this.hideHtmlContent();
        this.hideAboutInfo();
        let allowEdits = false;
        if (permission === 'WRITE') {
            allowEdits = true;
        }
        let allowDownloadElements = false;
        if (permission === 'WRITE' || permission === 'READ_INCLUDING_PAYLOADS') {
            allowDownloadElements = true;
        }

        const schema = this.getSchema(type);
        const content = digitalObject.attributes.content;
        const options = {
            digitalObject,
            schema,
            type,
            objectJson: content,
            objectId: this.objectId,
            disabled: true,
            allowEdits,
            allowDownloadElements
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
    }

    // This method does not change the UI in any way.
    search(
            query: string,
            pageNum: number,
            pageSize: number,
            sortFields: string | SortField[] | undefined,
            onSuccess: (res: SearchResponse<DigitalObject>) => void,
            onError: (err: unknown) => void
    ): void {
        if (!pageNum) pageNum = 0;
        if (!pageSize) pageSize = -1;
        let parsedSortFields = sortFields;
        if (typeof sortFields === 'string') {
            parsedSortFields = this.getSortFieldsFromString(sortFields);
        }
        const params = {
            pageNum,
            pageSize,
            sortFields: parsedSortFields
        } as QueryParams;
        this.doipClient
            .search(query, params)
            .then((res: SearchResponse<DigitalObject>) => {
                let pageNum = 0;
                let pageSize = -1;
                if (params && (params.pageNum !== undefined)) {
                    pageNum = params.pageNum;
                }
                if (params && (params.pageSize !== undefined)) {
                    pageSize = params.pageSize;
                }
                if (onSuccess) onSuccess(res);
            })
            .catch(onError);
    }

    // This method does not change the UI in any way.
    searchWithParams(
            query: string,
            params?: QueryParams,
            onSuccess?: (res: SearchResponse<string | DigitalObject>) => void,
            onError?: (err: unknown) => void
    ): void {
        this.doipClient.search(query, params)
            .then((res: SearchResponse<string | DigitalObject>) => {
                let pageNum = 0;
                let pageSize = -1;
                if (params && (params.pageNum !== undefined)) {
                    pageNum = params.pageNum;
                }
                if (params && (params.pageSize !== undefined)) {
                    pageSize = params.pageSize;
                }
                if (onSuccess) onSuccess(res);
            })
            .catch(onError);
    }

    getSortFieldsFromString(sortFieldsString: string): SortField[] {
        let sortFields: SortField[] = [];
        if (sortFieldsString) {
            if (sortFieldsString.trim().startsWith('[')) {
                sortFields = JSON.parse(sortFieldsString);
                return sortFields;
            }
            const fieldStrings = sortFieldsString.split(',');
            fieldStrings.forEach((value) => {
                const terms = value.split(' ');
                let reverse = false;
                if (terms.length > 1) {
                    if (terms[1].toUpperCase() === 'DESC') reverse = true;
                }
                sortFields.push({
                    name: terms[0],
                    reverse
                });
            });
        }
        return sortFields;
    }

    async getRelationships(objectId: string, outboundOnly: boolean): Promise<RelationshipResponse> {
        return this.cordraDoipOps.getRelationships(objectId, outboundOnly)
            .then((relationships: RelationshipResponse) => {
                return relationships;
            });
    }

    deleteObject(objectId?: string): void {
        if (!objectId) return;
        const dialog = new ModalYesNoDialog(
            'Are you sure you want to delete this object?',
            (() => {
                this.yesDeleteCallback(objectId, this.schemaIdToNameMap);
            }),
            () => this.noDeleteCallback()
        );
        dialog.show();
    }

    yesDeleteCallback(objectId: string, schemaIds: Record<string, string>): void {
        const isSchema = Object.keys(schemaIds).includes(objectId);
        this.doipClient.delete(objectId)
            .then(() => {
                if (isSchema) this.refreshDesign();
                if (this.editor) this.editor.destroy();
                this.editorDiv.empty();
                delete this.editor;
                this.clearFragment();
                this.notifications.alertSuccess('Object ' + objectId + ' deleted.');
            })
            .catch(this.onErrorResponse);
    }

    noDeleteCallback(): void {
        //no-op
    }

    saveObject(
            digitalObject: DigitalObject,
            elementsToDelete: string[] | undefined,
            errorCallback: (err: unknown) => void,
            progressCallback?: ProgressCallback): void {
        this.notifications.clear();
        let requestOptions: RequestOptions | undefined = undefined;
        if (progressCallback) {
            requestOptions = {
                elementUploadProgressCallback: ObjectConvertUtil.progressCallbackToDoipProgressCallback(progressCallback)
            };
        }
        this.doipClient.update(digitalObject, undefined, undefined, elementsToDelete, requestOptions)
            .then((responseDigitalObject) => {
                this.onSaveSuccess(responseDigitalObject);
            })
            .catch((response) => {
                this.onErrorResponse(response, errorCallback);
            });
    }

    onSaveSuccess(digitalObject: DigitalObject): void {
        this.editorDiv.empty();
        this.objectId = digitalObject.id;
        const type = digitalObject.type!;
        let permission;

        if (digitalObject.attributes.responseContext) {
            permission = (digitalObject.attributes.responseContext as Record<string, any>).permission;
            delete digitalObject.attributes.responseContext;
        }
        this.setObjectIdInFragment(this.objectId);
        if (type === "Schema" || this.objectId === 'design') this.refreshDesign();

        let allowEdits = false;
        if (permission === "WRITE") {
            allowEdits = true;
        }
        let allowDownloadElements = false;
        if (permission === "WRITE" || permission === "READ_INCLUDING_PAYLOADS") {
            allowDownloadElements = true;
        }
        const content = digitalObject.attributes.content;
        const options = {
            digitalObject,
            schema: this.schemasByName[type],
            type,
            objectJson: content,
            objectId: this.objectId,
            disabled: true,
            allowEdits,
            allowDownloadElements
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
        this.notifications.alertSuccess("Object " + this.objectId + " saved.");
    }

    publishVersion(
            objectId: string,
            onSuccess: (res: VersionInfo) => void,
            onFail?: (err: unknown) => void
    ): void {
        this.notifications.clear();
        this.cordraDoipOps.publishVersion(objectId)
            .then((res) => {
                this.notifications.alertSuccess("Version published with id " + res.id);
                if (onSuccess) {
                    onSuccess(res);
                }
            })
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    getVersionsFor(
            objectId: string | undefined,
            onSuccess: (res: VersionInfo[]) => void,
            onFail?: (err: unknown) => void
    ): void {
        if (!objectId) return;
        this.cordraDoipOps.getVersions(objectId)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    async listOperations(objectId: string): Promise<string[]> {
        return this.doipClient.listOperations(objectId);
    }

    performOperation(
            objectId: string,
            operationId: string,
            input: unknown,
            attributes: any,
            onSuccess: (doipResponse: DoipClientResponse) => void,
            onFail?: (err: unknown) => void): void {

        const doipRequest: DoipRequest = {
            targetId: objectId,
            operationId,
            inputJson: input,
            attributes
        };
        this.doipClient.performCompactOperation(doipRequest)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    createObject(
            digitalObject: DigitalObject,
            suffix?: string,
            errorCallback?: (err: unknown) => void,
            progressCallback?: ProgressCallback //TODO DOIP progress call back
    ): void {
        this.notifications.clear();
        this.doipClient.create(digitalObject, suffix)
            .then((responseDigitalObject) => {
                this.onCreateSuccess(responseDigitalObject);
            })
            .catch((response) => {
                this.onErrorResponse(response, errorCallback);
            });
    }

    onCreateSuccess(digitalObject: DigitalObject): void {
        this.editorDiv.empty();
        this.objectId = digitalObject.id;
        const type = digitalObject.type!;
        this.setObjectIdInFragment(this.objectId);
        this.fragmentJustSetInCreate = window.location.hash.substring(1);
        if (type === "Schema" || this.objectId === 'design') this.refreshDesign();
        const allowDownloadElements = this.editor!.getAllowDownloadElements();
        const content = digitalObject.attributes.content;
        const options = {
            digitalObject,
            schema: this.schemasByName[type],
            type,
            objectJson: content,
            objectId: this.objectId,
            disabled: true,
            allowEdits: true,
            allowDownloadElements
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
        this.notifications.alertSuccess("Object " + this.objectId + " saved.");
    }

    resolveNormalizedSchema(uri: string): JsonSchema | undefined {
        let baseUri = uri;
        let fragment = '';
        const hashIndex = uri.indexOf('#');
        if (hashIndex !== -1) {
            baseUri = uri.substring(0, hashIndex);
            fragment = uri.substring(hashIndex + 1);
        }
        const name = this.baseUriToNameMap[baseUri] || this.getNameFromCordraSchemasUri(baseUri);
        if (!name) return undefined;
        const normalizedSchema = this.schemasByName[name];
        if (!fragment || !normalizedSchema || typeof normalizedSchema !== 'object') return normalizedSchema;
        if (fragment && !fragment.startsWith('/')) {
            // TODO: $ref fragment is not a JSON pointer
            return undefined;
        }
        return JsonUtil.getJsonAtPointer(normalizedSchema, fragment) as JsonSchema;
    }

    getNameFromCordraSchemasUri(uri: string): string | undefined {
        if (!uri) return undefined;
        const lowercaseUri = uri.toLowerCase();
        if (lowercaseUri.startsWith("file:///")) uri = uri.substring(7);
        else if (lowercaseUri.startsWith("file://")) uri = uri.substring(6);
        else if (lowercaseUri.startsWith("file:/")) uri = uri.substring(5);
        if (!uri.startsWith("/cordra/schemas/")) return undefined;
        uri = uri.substring(16);
        if (uri.substring(uri.length - 12) === ".schema.json") uri = uri.substring(0, uri.length - 12);
        return decodeURIComponent(uri);
    }

    normalizeSchema(schema: JsonSchema | JsonSchema[], uri: string, addToRefToNormalizedSchemaMap: boolean = false): void {
        if (!uri || !schema || typeof schema !== 'object') return;
        if (Array.isArray(schema)) {
            for (let i = 0; i < schema.length; i++) {
                this.normalizeSchema(schema[i], uri, addToRefToNormalizedSchemaMap);
            }
        } else {
            if (typeof schema.$ref === 'string') {
                // if already normalized, done
                if (!schema.$ref.match(/^[-A-Za-z+.]*:/)) {
                    // not IE11 safe
                    schema.$ref = new URL(schema.$ref, uri).href;
                }
                if (addToRefToNormalizedSchemaMap) {
                    let theRef = schema.$ref;
                    if (theRef.indexOf('#') >= 0) theRef = theRef.substring(0, theRef.indexOf('#'));
                    this.refToNormalizedSchemaMap[theRef] = undefined;
                }

            }
            for (const key in schema) {
                if (key !== 'enum') {
                    this.normalizeSchema(schema[key] as JsonSchema, uri, addToRefToNormalizedSchemaMap);
                }
            }
        }
    }

    makeBaseUriAbsolute(uri: string): string {
        if (uri.match(/^[-A-Za-z+.]*:/)) return uri;
        return new URL(uri, "file:/").href;
    }

    getSchemaBaseUri(type: string, schemaDigitalObject: DigitalObject): string {
        if (this.nameToBaseUriMap[type]) return this.nameToBaseUriMap[type];
        const content = schemaDigitalObject.attributes.content as { baseUri: string | undefined };
        if (content.baseUri) {
            return this.makeBaseUriAbsolute(content.baseUri);
        }
        return this.makeBaseUriAbsolute('/cordra/schemas/' + encodeURIComponent(type));
    }

    normalizeSchemasAndUpdateRefToNormalizedSchemaMap(): void {
        this.refToNormalizedSchemaMap = {};
        for (const type in this.schemasByName) {
            const uri = this.nameToBaseUriMap[type];
            // make the $refs fully qualified paths
            this.normalizeSchema(this.schemasByName[type], uri, true);
        }
        for (const key in this.refToNormalizedSchemaMap) {
            this.refToNormalizedSchemaMap[key] = this.resolveNormalizedSchema(key);
        }
    }

    saveUiConfig(uiConfig: Record<string, any>): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.doipClient.retrieve("design")
            .then(designDigitalObject => {
                (designDigitalObject!.attributes.content as Record<string, unknown>).uiConfig = uiConfig;
                return this.doipClient.update(designDigitalObject!);
            })
            .then(() => {
                $(".navbar-brand").text(uiConfig.title as string);
                $("title").text(uiConfig.title as string);
                this.refreshDesign();
                this.notifications.alertSuccess("UiConfig saved.");
            })
            .catch(this.onErrorResponse);
    }

    saveDesignJavaScript(designJavaScriptIsModule: boolean | undefined, designJavaScript: unknown): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.doipClient.retrieve("design")
        .then(designDo => {
            const designContent = (designDo!.attributes as any).content!;
            if (designJavaScriptIsModule === undefined) delete designContent.javascriptIsModule;
            else designContent.javascriptIsModule = designJavaScriptIsModule;
            if (!designJavaScript) delete designContent.javascript;
            else designContent.javascript = designJavaScript;
            return this.doipClient.update(designDo!);
        }).then(() => {
            this.notifications.alertSuccess("Design JavaScript saved.");
        }).catch(this.onErrorResponse);
    }

    saveAdminPassword(newPassword: string, authInfo: PasswordAuthenticationInfo): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.doipClient.changePassword(newPassword, authInfo)
            .then(() => {
                this.notifications.alertSuccess("Admin password saved.");
            })
            .then(() => {
                if (authInfo.username !== 'admin') return;
                const newAuthInfo: AuthenticationInfo = new PasswordAuthenticationInfo('admin', newPassword);
                this.authenticate(newAuthInfo).catch(this.onErrorResponse);
            })
            .catch(this.onErrorResponse);
    }

    saveHandleMintingConfig(handleMintingConfig: unknown, successCallback: (() => void) | undefined): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.doipClient.retrieve("design")
            .then(designDigitalObject => {
                (designDigitalObject!.attributes.content as Record<string, unknown>).handleMintingConfig = handleMintingConfig;
                return this.doipClient.update(designDigitalObject!);
            })
            .then(() => {
                this.notifications.alertSuccess("Handle minting config saved.");
                if (successCallback) successCallback();
            })
            .catch(this.onErrorResponse);
    }

    updateAllHandles(successCallback: () => void): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.cordraDoipOps
            .updateAllHandleRecords()
            .then(() => {
                this.notifications.alertSuccess("Update in progress");
                if (successCallback) successCallback();
            })
            .catch(this.onErrorResponse);
    }

    getHandleUpdateStatus(successCallback: (res: any) => void): void {
        this.cordraDoipOps
            .getHandleRecordsUpdateStatus()
            .then((res) => {
                if (successCallback) successCallback(res);
            })
            .catch(this.onErrorResponse);
    }

    saveAuthConfig(authConfig: unknown): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.doipClient.retrieve("design")
            .then(designDigitalObject => {
                (designDigitalObject!.attributes.content as Record<string, unknown>).authConfig = authConfig;
                return this.doipClient.update(designDigitalObject!);
            })
            .then(() => {
                this.notifications.alertSuccess("Auth config saved.");
            })
            .catch(this.onErrorResponse);
    }

    refreshDesign(): void {
        this.cordraDoipOps.getInitData()
            .then((initData: InitData) => {
                this.processInitDataResponse(initData);
                if (this.schemasEditor) {
                    this.schemasEditor.refresh(this.schemasByName, this.schemaIdToNameMap);
                }
                const types = initData.typesPermittedToCreate;
                this.searchWidget.refreshTypes(types, this.uiConfig.numTypesForCreateDropdown as number);
                this.authWidget.setTypesPermittedToCreate(types);
            })
            .catch(console.error);
    }

    loadObjects(digitalObjects: DigitalObject[], successCallback?: (() => void), errorCallback?: (() => void)): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.cordraDoipOps
            .batchUpload(digitalObjects)
            .then(() => {
                this.refreshDesign();
                this.notifications.alertSuccess("Objects loaded.");
                if (successCallback) successCallback();
            })
            .catch((response) => {
                this.onErrorResponse(response);
                if (errorCallback) errorCallback();
            });
    }

    async performBatchDelete(all: boolean, query: string | undefined, ids: string[] | undefined, parallel: boolean, dryRun: boolean): Promise<any> {
        const result = await this.cordraDoipOps.batchDelete(all, query, ids, parallel, dryRun);
        return result;
    }

    getIdForSchema(type: string): string | undefined {
        let id;
        Object.keys(this.schemaIdToNameMap).forEach((key) => {
            if (this.schemaIdToNameMap[key] === type) id = key;
        });
        return id;
    }

    saveSchema(schemaDigitalObject: DigitalObject, type: string): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        if (schemaDigitalObject.id) {
            this.doipClient
                .update(schemaDigitalObject)
                .then(() => {
                    this.refreshDesign();
                    this.notifications.alertSuccess("Schema " + type + " saved.");
                })
                .catch(this.onErrorResponse);
        } else {
            schemaDigitalObject.type = "Schema";
            this.doipClient
                .create(schemaDigitalObject)
                .then(() => {
                    this.refreshDesign();
                    this.notifications.alertSuccess("Schema " + type + " created.");
                })
                .catch(this.onErrorResponse);
        }
    }

    deleteSchema(type: string): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        const id = this.getIdForSchema(type);
        if (id) {
            this.doipClient.delete(id)
                .then(() => {
                    this.refreshDesign();
                    this.notifications.alertSuccess("Schema " + type + " deleted.");
                    this.closeSchemaEditor();
                })
                .catch(this.onErrorResponse);
        }
    }

    closeSchemaEditor(): void {
        this.schemasEditor.refresh();
        this.schemasEditor.showSchemaEditorFor();
    }

    viewOrEditCurrentObject(disabled: boolean): void {
        if (!this.editor) return;
        this.editorDiv.empty();
        this.editorDiv.show();
        const type = this.editor.getType();
        const jsonObject = this.editor.getJsonFromEditor();
        const allowEdits = this.editor.getAllowEdits();
        const allowDownloadElements = this.editor.getAllowDownloadElements();
        const digitalObject: DigitalObject = this.editor.getDigitalObject();
        const options = {
            digitalObject,
            schema: this.schemasByName[type],
            type,
            objectJson: jsonObject,
            objectId: this.objectId,
            disabled,
            allowEdits,
            allowDownloadElements
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
    }

    getObjectId(): string | undefined {
        return this.objectId;
    }

    getObjectIdFromFragment(fragment: string): string {
        const path = "objects/";
        return decodeURIComponent(fragment.substring(path.length));
    }

    getParamsFromFragment(fragment: string): Record<string, string> {
        const prefix = "objects/?";
        const queryParamsString = fragment.substring(prefix.length);
        const paramsArray = queryParamsString.split("&");
        const params: Record<string, string> = {};
        for (const paramString of paramsArray) {
            const paramTokens = paramString.split("=");
            params[decodeURIComponent(paramTokens[0])] = decodeURIComponent(paramTokens[1]);
        }
        return params;
    }

    getUrlFromFragment(fragment: string): string {
        const path = "urls/";
        return fragment.substring(path.length);
    }

    getPageUrlFromFragment(fragment: string): string | undefined {
        const path = "pages/";
        const pageName = fragment.substring(path.length);
        if (!pageName) return undefined;
        const pageConfig = (this.design.uiConfig as any).customPages[pageName];
        if (!pageConfig) return undefined;
        const objectId = pageConfig.objectId ?? 'design';
        const payloadName = pageConfig.payloadName ?? pageName;
        //TODO use doip
        return `${this.baseUri}objects/${objectId}?payload=${payloadName}`;
    }

    async getAccessToken(): Promise<string | undefined> {
        return this.doipClient.getAccessToken();
    }

    disableJsonEditorOnline(editor: JsonEditorOnline): void {
        editor.aceEditor.container.style.backgroundColor = "rgb(238, 238, 238)";
        editor.aceEditor.setReadOnly(true);
    }

    enableJsonEditorOnline(editor: JsonEditorOnline): void {
        editor.aceEditor.container.style.backgroundColor = "";
        editor.aceEditor.setReadOnly(false);
    }

    fixAceJavascriptEditor(editor: AceAjax.Editor): void {
        // This ceremony causes the ace editor to uses a new ES version, which means the
        // builtin jshint will no longer complain about things like async/await.
        // See https://github.com/ajaxorg/ace/issues/3160
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        editor.session.on('changeMode', (_: Event, session: any) => {
            if ("ace/mode/javascript" === session.getMode().$id) {
                if (session.$worker) {
                    session.$worker.send("setOptions", [{
                        esversion: 11,
                        esnext: false
                    }]);
                }
            }
        });
    }

    async authenticate(authInfo: AuthenticationInfo): Promise<AuthResponse> {
        return this.doipClient.authenticate(authInfo);
    }

    getAuthenticationStatus(full: boolean = false): Promise<AuthResponse> {
        return this.doipClient.checkCredentials({ attributes: { full } });
    }

    changePassword(newPassword: string, authInfo: AuthenticationInfo): Promise<Response> {
        return this.doipClient.changePassword(newPassword, authInfo);
    }

    signOut(): Promise<AuthResponse> {
        const authResponse: Promise<AuthResponse> = this.doipClient.signOut() as Promise<AuthResponse>;
        return authResponse;
    }

    async getFacetBucketsForField(field: string, query: string): Promise<FacetBucketWithUi[]> {
        const facets: Facet[] = [{ field }];
        const params = {
            facets,
            pageSize: 0
        };
        const response = await this.doipClient.search(query, params);
        const buckets: FacetBucketWithUi[] = response.facets?.at(0)?.buckets || [];
        await this.setBucketDisplayNames(buckets);
        return buckets;
    }

    private async setBucketDisplayNames(buckets: FacetBucketWithUi[]) {
        const maybeIds = buckets
            .map(b => b.value)
            .filter(v => v.includes('/'))
            .join('" OR "');
        const query = `id:("${maybeIds}")`;
        const results = await this.doipClient.search(query, { });
        const idToDisplayNameMap: Record<string, string> = {};
        for (const digitalObject of results.results) {
            if (!digitalObject) continue;
            const previewData = ObjectPreviewUtil.getPreviewData(digitalObject);
            const names = Object.entries(previewData)
                .filter(v => v[1].isPrimary)
                .map(v => v[1].previewJson as string);
            if (names.length === 0) continue;
            idToDisplayNameMap[digitalObject.id!] = names[0];
        }
        for (const bucket of buckets) {
            bucket.displayName = idToDisplayNameMap[bucket.value];
        }
    }

    onErrorResponse(response?: any, errorCallback?: (err: unknown) => void): void {
        //TODO DOIP change to look at DOIP response codes rather than http status codes
        if (!response) {
            this.notifications.alertError("Something went wrong.");
        } else if (typeof response === 'string') {
            this.notifications.alertError(response);
        } else if (response.status === 401) {
            this.clearCurrentUserKey();
            this.authWidget.setUiToStateUnauthenticated();
            const message = response.statusText || "Authentication failed";
            this.notifications.alertError(message as string);
        } else if (response.status === 403) {
            const message = response.statusText || "Forbidden";
            this.notifications.alertError(message as string);
        } else if (response.message) {
            this.notifications.alertError(response.message as string);
        } else {
            response.json()
            .then((json: any) => {
                this.notifications.alertError(json.message as string);
            });
        }
        if (errorCallback) errorCallback(response);
    }
}
