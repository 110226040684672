import 'corejs-typeahead';
import { html, HTMLTemplateResult, render } from 'lit-html';
import { ref } from 'lit-html/directives/ref.js';
import { DigitalObject, SearchResponse, QueryParams, SortField } from '@cnri/doip-client';
import { FacetsComponent } from './FacetsComponent.js';
import { SortComponent } from './SortComponent.js';
import { CreateButtonComponent } from './CreateButtonComponent.js';
import { PaginationComponent } from './PaginationComponent.js';
import { SearchResultComponent } from './SearchResultComponent.js';

export class SearchComponent {
    private readonly container: HTMLElement;
    private readonly pageSize: number = 10;
    private enableFacetedSearch: boolean;

    private searchInput!: HTMLInputElement;
    private paginationDiv!: HTMLDivElement;
    private paginationBottomDiv!: HTMLDivElement;
    private resultsContainer!: HTMLDivElement;
    private resultsDiv!: HTMLDivElement;
    private retrieveJsonForm!: HTMLFormElement;

    private sortBy!: SortComponent;
    private facetedSearch!: FacetsComponent;

    constructor(container: HTMLElement, enableFacetedSearch: boolean) {
        this.container = container;
        this.enableFacetedSearch = enableFacetedSearch;
        this.render();
    }

    render(types: string[] = [], numTypes: number = 15): void {
        let sortByButtonSpan: Element | undefined;
        let accessTokenInput: HTMLInputElement | undefined;
        const currentSortFields = this.sortBy?.getSortFields() || [];
        const currentFilters = this.facetedSearch?.getFilters() || [];
        let facetedSearchHtml = this.getFacetedSearchHtml(currentFilters);
        let searchResultsContainerClasses = "row col-md-9 search-results-container";
        if (!this.enableFacetedSearch) {
            searchResultsContainerClasses = "row col-md-12 search-results-container";
        }
        const template = () => {
            return html`
                <div class="row search-bar">
                    <form class="form-inline" onsubmit="return false;" role="form">
                        <div class="col-md-12 nopadding">
                            <div class="input-group">
                                <span class="input-group-btn"
                                    style="width:1%"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Show/Hide Sort Options"
                                    ${ref(el => sortByButtonSpan = el)}>
                                        <button @click=${(e: Event) => this.toggleSortVisibility(e)} class="btn btn-primary" type="button">
                                            <i class="fa fa-sort"></i>
                                        </button>
                                </span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search"
                                    ${ref(el => this.searchInput = el as HTMLInputElement)}
                                    @change=${() => this.facetedSearch?.clear()}
                                    @keypress=${(e: KeyboardEvent) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            this.onSearchButtonClick();
                                        }
                                    }}
                                />
                                <span class="input-group-btn" style="width:1%">
                                    <button @click=${() => this.onSearchButtonClick()} class="btn btn-primary cordra-search-button" type="button">
                                        <i class="fa fa-search"></i><span>Search<span>
                                    </button>
                                </span>
                                <div class="cordra-create-button-container"
                                    ${ref(el => new CreateButtonComponent(el, types, numTypes))}>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div hidden
                         ${ref(el => this.sortBy = new SortComponent(el, currentSortFields, () => this.updateHashFragment()))}>
                    </div>
                </div>
                ${facetedSearchHtml}
                <div class="${searchResultsContainerClasses}" hidden
                    ${ref(el => this.resultsContainer = el as HTMLDivElement)}>
                        <div class="pagination-controls-top">
                            <div class="row">
                                <form
                                    hidden
                                    method="POST"
                                    target="_blank"
                                    ${ref(el => this.retrieveJsonForm = el as HTMLFormElement)}>
                                        <input
                                            ${ref(el => accessTokenInput = el as HTMLInputElement)}
                                            type="hidden" name="access_token"
                                        />
                                </form>
                                <div class="col-md-11" ${ref(el => this.paginationDiv = el as HTMLDivElement)}></div>
                                <div class="col-md-1" @click=${(e: Event) => {
                                    e.preventDefault();
                                    APP.getAccessToken()
                                        .then((accessToken: string | undefined) => {
                                            if (accessToken && accessTokenInput) accessTokenInput.value = accessToken;
                                            this.retrieveJsonForm.submit();
                                        })
                                        .catch(console.error);
                                }}>
                                    <a class="link"><i class="fa fa-external-link-alt"></i><span>JSON</span></a>
                                </div>
                            </div>
                        </div>
                        <div ${ref(el => this.resultsDiv = el as HTMLDivElement)}></div>
                        <div>
                            <div
                                class="pagination-controls-bottom"
                                ${ref(el => this.paginationBottomDiv = el as HTMLDivElement)}
                            ></div>
                        </div>
                <div/>
            `;
        };
        render(template(), this.container);

        // tooltip library requires jQuery
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        $(sortByButtonSpan).tooltip();

        APP.getAccessToken()
            .then((accessToken: string | undefined) => {
                if (accessToken && accessTokenInput) accessTokenInput.value = accessToken;
            })
            .catch(console.error);
    }

    private getFacetedSearchHtml(currentFilters: string[]): HTMLTemplateResult {
        if (this.enableFacetedSearch) {
            return html`
            <div class="col-md-3"
                ${ref(el => this.facetedSearch = new FacetsComponent(el, currentFilters, () => this.updateHashFragment(), () => this.getQuery()))}>
            </div>
        `;
        } else {
            return html`
            <div></div>
        `;
        }
    }

    clearInput(): void {
        this.hideSortOptions();
        this.hideFacetedSearch();
        this.searchInput.value = '';
        this.facetedSearch?.clear();
    }

    hideResults(): void {
        this.resultsContainer.setAttribute('hidden', '');
    }

    showSortOptions(): void {
        this.sortBy.show();
    }

    hideSortOptions(): void {
        this.sortBy.hide();
    }

    showFacetedSearch(): void {
        if (this.enableFacetedSearch) {
            this.facetedSearch?.show();
            this.resultsContainer.classList.remove('col-md-12');
            this.resultsContainer.classList.add('col-md-9');
        }
    }

    hideFacetedSearch(): void {
        if (this.enableFacetedSearch) {
            this.facetedSearch?.hide();
            this.resultsContainer.classList.remove('col-md-9');
            this.resultsContainer.classList.add('col-md-12');
        }
    }

    toggleSortVisibility(e: Event): void {
        e.preventDefault();
        if (this.sortBy.isHidden()) {
            this.showSortOptions();
        } else {
            this.hideSortOptions();
        }
        this.updateHashFragment();
    }

    onSearchButtonClick(): void {
        this.facetedSearch?.clear();
        this.updateHashFragment();
    }

    updateHashFragment(): void {
        if (!this.searchInput) return;
        let query = this.searchInput.value;
        if ('' === query) {
            return;
        }
        if (this.isObjectId(query)) {
            query = 'id:' + query;
        }

        let sortFields = undefined;
        let filters = undefined;
        if (!this.sortBy.isHidden()) {
            sortFields = this.sortBy.getSortFields();
        }
        if (this.enableFacetedSearch) {
            if (!this.facetedSearch.isHidden()) {
                filters = this.facetedSearch.getFilters();
            }
        }
        APP.setQueryInFragment(query, sortFields, filters);
    }

    search(query: string, sortFields?: SortField[], filterQueries?: string[]): void {
        if ('' === query) {
            return;
        }
        this.searchInput.value = query;
        this.sortBy.setSortFields(sortFields);
        if (sortFields) {
            this.showSortOptions();
        }
        if (this.enableFacetedSearch) {
            this.facetedSearch.setFilters(filterQueries);
            this.showFacetedSearch();
        }
        const params = {
            pageNum: 0,
            pageSize: this.pageSize,
            sortFields,
            filterQueries
        } as QueryParams;
        APP.searchWithParams(
            query,
            params,
            (response: SearchResponse<string | DigitalObject>) => {
                this.onSuccess(query, sortFields, filterQueries, response, params);
            },
            (resp: unknown) => this.onError(resp)
        );
    }

    isObjectId(str: string): boolean {
        const prefix = APP.getPrefix() as string;
        if (str.startsWith(prefix + '/')) {
            const suffix = str.substring(str.lastIndexOf(prefix));
            return !this.containsSpaces(suffix);
        } else {
            return false;
        }
    }

    containsSpaces(str: string): boolean {
        return str.lastIndexOf(' ', 0) === 0;
    }

    onSuccess(
            query: string,
            sortFields: SortField[] | undefined,
            filterQueries: string[] | undefined,
            response: SearchResponse<string | DigitalObject>,
            params: QueryParams
    ): void {
        APP.notifications.clear();
        const clickHandler = (e: Event) => this.onPageClick(query, sortFields, filterQueries, e);
        new PaginationComponent(this.paginationDiv, response, params, clickHandler);
        new PaginationComponent(this.paginationBottomDiv, response, params, clickHandler);
        this.writeResultsToResultsDiv(response.results as DigitalObject[]);
        this.retrieveJsonForm.setAttribute(
            'action',
            this.getDoipApiUriFor(query, params.pageNum!, params.pageSize!, sortFields, filterQueries)
        );
        this.resultsContainer.removeAttribute('hidden');
    }

    getDoipApiUriFor(query: string, pageNum: number, pageSize: number, sortFields?: SortField[], filterQueries?: string[]): string {
        let uri =
            APP.getBaseUri() + "doip/?t=service&o=0.DOIP/Op.Search&query=" +
            encodeURIComponent(query) +
            "&pageNum=" +
            pageNum +
            "&pageSize=" +
            pageSize;
        if (sortFields) {
            uri = `${uri}&sortFields=${encodeURIComponent(JSON.stringify(sortFields))}`;
        }
        if (filterQueries) {
            uri = `${uri}&filterQueries=${encodeURIComponent(JSON.stringify(filterQueries))}`;
        }
        return uri;
    }

    writeResultsToResultsDiv(results: DigitalObject[]): void {
        const template = html`
            <div class="search-results-list">
                ${results.length === 0
                        ? html`<label>No Results</label>`
                        : results.map(result => html`
                            <div ${ref(el => new SearchResultComponent(el, result))}></div>`)}
            </div>
        `;
        render(template, this.resultsDiv);
    }

    onPageClick(
            query: string,
            sortFields: SortField[] | undefined,
            filterQueries: string[] | undefined,
            ev: Event
    ): void {
        ev.preventDefault();
        const pageNum = (ev.target! as HTMLElement).dataset.pagenum;
        const params = {
            pageNum: pageNum ? Number.parseInt(pageNum) : 0,
            pageSize: this.pageSize,
            sortFields,
            filterQueries
        };
        APP.searchWithParams(
            query,
            params,
            (response: SearchResponse<string | DigitalObject>) => {
                this.onSuccess(query, sortFields, filterQueries, response, params);
            },
            (resp: unknown) => this.onError(resp)
        );
    }

    onError(response: unknown): void {
        APP.onErrorResponse(response);
    }

    getQuery(): string | undefined {
        return this.searchInput.value;
    }

    getSortFields(): SortField[] | undefined {
        return this.sortBy.getSortFields();
    }

    getFilterQueries(): string[] {
        return this.facetedSearch?.getFilters() || [];
    }

    refreshTypes(types?: string[], numTypes?: number): void {
        this.render(types, numTypes);
    }
}
