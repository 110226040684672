import { html, HTMLTemplateResult, render } from 'lit-html';
import { ref } from 'lit-html/directives/ref.js';
import { SortField } from '@cnri/doip-client';
import { SortFieldComponent } from './SortFieldComponent.js';

export class SortComponent {
    private readonly container: HTMLElement | undefined;
    private readonly changeCallback: () => void;
    private readonly template: (sortFields: SortField[]) => HTMLTemplateResult;
    private sortFields: SortField[];

    constructor(
            container: Element | undefined,
            initialSortFields: SortField[],
            changeCallback: () => void
    ) {
        this.container = container as HTMLElement | undefined;
        this.sortFields = initialSortFields || [];
        this.changeCallback = changeCallback;
        this.template = (sortFields: SortField[]) => {
            const fields = [];
            for (const [i, sortField] of sortFields.entries()) {
                const removeCallback = () => this.removeItem(i);
                const updateCallback = (field?: SortField) => this.updateItem(i, field);
                const field = html`
                    <div
                            ${ref(el => new SortFieldComponent(el, sortField, removeCallback, updateCallback))}>
                    </div>
                `;
                fields.push(field);
            }
            return html`
                <div class="col-md-12 nopadding">
                    <form onsubmit="return false;" class="form-inline">
                        <div style="padding-left: 10px">
                            Sort By:
                            <button class="btn btn-sm" @click=${() => this.addItem()}>
                                <i class="fa fa-add"></i>
                            </button>
                            <div class="sort-fields"}>
                                ${fields}
                            </div>
                        </div>
                    </form>
                </div>
            `;
        };
        this.render();
    }

    hide(): void {
        this.container?.setAttribute('hidden', '');
    }

    show(): void {
        this.container?.removeAttribute('hidden');
    }

    isHidden(): boolean {
        if (!this.container) return true;
        return this.container.hasAttribute('hidden') || false;
    }

    private render(): void {
        if (!this.container) return;
        render(this.template(this.sortFields), this.container);
    }

    getSortFields(): SortField[] | undefined {
        return this.sortFields;
    }

    setSortFields(sortFields: SortField[] = []): void {
        if (sortFields.length === 0) {
            sortFields.push({ name: '' });
        }
        this.sortFields = sortFields;
        this.render();
    }

    private addItem(): void {
        this.sortFields.push({ name: '' });
        this.render();
    }

    private updateItem(index: number, sortField?: SortField): void {
        if (index >= this.sortFields.length || !sortField) return;
        this.sortFields[index] = sortField;
        this.render();
        this.changeCallback();
    }

    private removeItem(index: number): void {
        if (!this.container) return;
        this.sortFields.splice(index, 1);
        this.render();
        this.changeCallback();
    }
}
