import { Constants } from "./Constants.js";
import { JsonUtil } from "./JsonUtil.js";

export const SchemaUtil = {
    getDeepCordraSchemaProperty
};

function getDeepCordraSchemaProperty(obj: unknown, ...args: string[]): unknown {
    args.unshift(Constants.CORDRA_SCHEMA_KEYWORD);
    let res = JsonUtil.getDeepProperty(obj, ...args);
    if (res) return res;
    args[0] = Constants.OLD_REPOSITORY_SCHEMA_KEYWORD;
    res = JsonUtil.getDeepProperty(obj, ...args);
    return res;
}
