const ONE_DAY = 1000 * 60 * 60 * 24;

interface HandleValue {
    type: string;
    data: { value: string };
}

interface HandleRecord {
    values: HandleValue[];
}

export class SubscriptionBanner {
    private readonly prefix?: string;
    private readonly containerDiv: JQuery<HTMLDivElement>;

    constructor(containerDiv: JQuery<HTMLDivElement>, prefixParam?: string) {
        this.containerDiv = containerDiv;
        if (!prefixParam || prefixParam === 'test') {
            return;
        }
        if (!prefixParam.startsWith("0.NA/") && !prefixParam.startsWith("0.na/")) {
            this.prefix = "0.NA/" + prefixParam;
        } else {
            this.prefix = prefixParam;
        }

        const uri = "https://hdl.handle.net/api/handles/" + this.prefix;

        fetch(uri)
            .then(r => r.json())
            .then((handleRecord: HandleRecord) => {
                const subscriptionInfoHandleValue = this.getValueByType(handleRecord, "PREFIX_SUBSCRIPTION_INFO");
                if (subscriptionInfoHandleValue) {
                    const subscriptionInfoJson = subscriptionInfoHandleValue.data.value;
                    const subscriptionInfo: { expirationDate: string } = JSON.parse(subscriptionInfoJson);
                    this.displaySubscriptionBanner(subscriptionInfo);
                } else {
                    //no subscription info, do not display banner
                }
            })
            .catch(() => {
                // no-op
            });
    }

    displaySubscriptionBanner(subscriptionInfo: { expirationDate: string }): void {
        const closeButton = $('<button class="btn btn-sm btn-default pull-right"><span class="glyphicon glyphicon-remove"></span></button>');
        this.containerDiv.append(closeButton);
        closeButton.on('click', () => this.onCloseClick());

        const expirationDateISO8601: string = subscriptionInfo.expirationDate;
        const expirationDate = new Date(expirationDateISO8601);
        const duration = this.getTimeUntilExpiration(expirationDate);
        const daysLeft = this.msToDays(duration);
        let message;
        if (daysLeft > 1) {
            message = $('<p>Handle Prefix ' + this.prefix + ' will expire in ' + daysLeft + ' days. Click <a href="https://www.handle.net">here</a> to convert the prefix to operational status.</p>');
        } else if (daysLeft === 1) {
            message = $('<p>Handle Prefix ' + this.prefix + ' will expire in ' + daysLeft + ' day. Click <a href="https://www.handle.net/">here</a> to convert the prefix to operational status.</p>');
        } else {
            message = $('<p>Handle Prefix ' + this.prefix + ' has expired. Click <a href="https://www.handle.net">here</a> to convert the prefix to operational status.</p>');
        }
        this.containerDiv.append(message);
        this.containerDiv.show();
    }

    onCloseClick(): void {
        this.containerDiv.hide();
    }

    msToDays(duration: number): number {
        return Math.round(duration / ONE_DAY);
    }

    getTimeUntilExpiration(expirationDate: Date): number {
        const expiresTimestamp = expirationDate.getTime();
        const now = new Date().getTime();
        return expiresTimestamp - now;
    }

    getValueByType(handleRecord: HandleRecord, type: string): HandleValue | null {
        for (let i = 0; i < handleRecord.values.length; i++) {
            const value = handleRecord.values[i];
            if (type === value.type) return value;
        }
        return null;
    }
}
