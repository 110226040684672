import { DigitalObject } from '@cnri/doip-client';
import { default as JsonEditorOnline, JSONEditorOptions } from 'jsoneditor';
import { JsonSchema } from 'tv4';
import { ModalYesNoDialog } from "../cordra/ModalYesNoDialog.js";
import { PreviewObjectEditor } from "./PreviewObjectEditor.js";

export class SchemaEditor {
    private readonly typeDigitalObject: DigitalObject;
    private readonly typeName: string;
    private readonly editorDiv: JQuery<HTMLDivElement>;
    private readonly javascriptDiv: JQuery<HTMLDivElement>;
    private readonly toolBarDiv: JQuery<HTMLDivElement>;
    private readonly bottomToolBarDiv: JQuery<HTMLDivElement>;
    private readonly editor: JsonEditorOnline;
    private readonly previewDiv: JQuery<HTMLDivElement>;
    private saveButton!: JQuery<HTMLButtonElement>;
    private deleteButton!: JQuery<HTMLButtonElement>;
    private cancelButton!: JQuery<HTMLButtonElement>;
    private editButton!: JQuery<HTMLButtonElement>;
    private previewButton!: JQuery<HTMLButtonElement>;
    private saveButtonBottom!: JQuery<HTMLButtonElement>;
    private deleteButtonBottom!: JQuery<HTMLButtonElement>;

    private scriptEditorDiv!: JQuery<HTMLDivElement>;
    private scriptEditor!: AceAjax.Editor;

    constructor(containerDiv: JQuery<HTMLDivElement>, typeDigitalObject: DigitalObject, typeName: string, disabled: boolean) {
        this.typeDigitalObject = typeDigitalObject;
        this.typeName = typeName;
        const headerRow = $('<div class="row object-header"></div>');
        containerDiv.append(headerRow);

        const objectHeader = $('<div class="heading col-md-12"></div>');
        headerRow.append(objectHeader);
        const objectIdHeading = $(
            '<h3 class="editorTitle">Object Type: ' + typeName + "</h3>"
        );
        objectHeader.append(objectIdHeading);

        const typeText = $("<p>Type: Schema</p>");
        objectHeader.append(typeText);

        this.toolBarDiv = $(
            '<div class="object-editor-toolbar col-md-12 pull-right"></div>'
        );
        headerRow.append(this.toolBarDiv);

        this.createToolBar();

        this.editorDiv = $(
            '<div class="col-md-12 nopadding schema-editor"  style="height:500px;"></div>'
        );
        containerDiv.append(this.editorDiv);

        const container = this.editorDiv[0];
        const options = {
            ace,
            theme: "ace/theme/textmate",
            mode: "code",
            modes: ["code", "tree"], // allowed modes
            onError(err: Error) {
                alert(err.toString());
            }
        } as JSONEditorOptions;
        const schema = (typeDigitalObject.attributes.content as { schema: unknown }).schema;
        this.editor = new JsonEditorOnline(container, options, schema);
        if (disabled) {
            APP.disableJsonEditorOnline(this.editor);
        }
        this.previewDiv = $('<div class="col-md-12 nopadding"></div>');
        containerDiv.append(this.previewDiv);

        this.javascriptDiv = $('<div class="col-md-12 nopadding js-editor"></div>');
        containerDiv.append(this.javascriptDiv);
        this.buildScriptEditor(this.javascriptDiv);

        this.bottomToolBarDiv = $(
            '<div class="object-editor-toolbar col-md-offset-6 col-md-6 pull-right nopadding"></div>'
        );
        containerDiv.append(this.bottomToolBarDiv);
        this.createBottomToolBar();
    }

    buildScriptEditor(containerDiv: JQuery<HTMLDivElement>): void {
        containerDiv.append($("<p></p>"));
        const label = $("<label></label>");
        label.text("JavaScript");
        containerDiv.append(label);

        this.scriptEditorDiv = $(
            '<div id="schemaJavaScriptEditor" class="ace_editor"></div>'
        );
        containerDiv.append(this.scriptEditorDiv);

        this.scriptEditor = ace.edit("schemaJavaScriptEditor");
        this.scriptEditor.setTheme("ace/theme/textmate");
        APP.fixAceJavascriptEditor(this.scriptEditor);
        this.scriptEditor.getSession().setMode("ace/mode/javascript");
        this.scriptEditor.setOptions({
            maxLines: Infinity,
            minLines: 10
        });
        this.scriptEditor.$blockScrolling = Infinity;
        const content = this.typeDigitalObject.attributes.content as { javascript: string | undefined };
        if (content.javascript) {
            this.scriptEditor.setValue(content.javascript, -1);
        }
    }

    createToolBar(): void {
        this.previewButton = $(
            '<button class="btn btn-sm btn-primary"><i class="fa fa-eye"></i></button>'
        );
        this.toolBarDiv.append(this.previewButton);
        this.previewButton.on("click", () => this.previewClick());

        const previewButtonSpan = $("<span><span>");
        this.previewButton.append(previewButtonSpan);
        previewButtonSpan.text("Preview UI");

        this.editButton = $(
            '<button class="btn btn-sm btn-primary"><i class="fa fa-edit"></i></button>'
        );
        this.toolBarDiv.append(this.editButton);
        this.editButton.on("click", () => this.onEditClick());

        const editButtonSpan = $("<span><span>");
        this.editButton.append(editButtonSpan);
        editButtonSpan.text("Edit");
        this.editButton.hide();

        this.deleteButton = $(
            '<button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>'
        );

        this.toolBarDiv.append(this.deleteButton);
        this.deleteButton.on("click", () => this.deleteSchema());

        const deleteButtonSpan = $("<span><span>");
        this.deleteButton.append(deleteButtonSpan);
        deleteButtonSpan.text("Delete");

        this.cancelButton = $(
            '<button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>'
        );

        this.toolBarDiv.append(this.cancelButton);
        this.cancelButton.on("click", () => this.closeClick());

        const cancelButtonSpan = $("<span><span>");
        this.cancelButton.append(cancelButtonSpan);
        cancelButtonSpan.text("Cancel");
        this.cancelButton.hide();

        this.saveButton = $(
            '<button class="btn btn-sm btn-success" data-loading-text="Saving..."><i class="fa fa-save"></i></button>'
        );

        this.toolBarDiv.append(this.saveButton);
        this.saveButton.on("click", () => this.save());

        const saveButtonSpan = $("<span><span>");
        this.saveButton.append(saveButtonSpan);
        saveButtonSpan.text("Save");
    }

    createBottomToolBar(): void {
        this.deleteButtonBottom = $(
            '<button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>'
        );

        this.bottomToolBarDiv.append(this.deleteButtonBottom);
        this.deleteButtonBottom.on("click", () => this.deleteSchema());

        const deleteButtonBottomSpan = $("<span><span>");
        this.deleteButtonBottom.append(deleteButtonBottomSpan);
        deleteButtonBottomSpan.text("Delete");

        this.saveButtonBottom = $(
            '<button class="btn btn-sm btn-success" data-loading-text="Saving..."><i class="fa fa-save"></i></button>'
        );

        this.bottomToolBarDiv.append(this.saveButtonBottom);
        this.saveButtonBottom.on("click", () => this.save());

        const saveButtonBottomSpan = $("<span><span>");
        this.saveButtonBottom.append(saveButtonBottomSpan);
        saveButtonBottomSpan.text("Save");
    }

    toggleToolBarControls(): void {
        this.previewButton.toggle();
        this.saveButton.toggle();
        this.deleteButton.toggle();
        this.saveButtonBottom.toggle();
        this.deleteButtonBottom.toggle();
        this.editButton.toggle();
    }

    toggleCancelDeleteControls(): void {
        this.cancelButton.toggle();
        this.deleteButton.toggle();
    }

    enable(): void {
        this.toggleToolBarControls();
        APP.enableJsonEditorOnline(this.editor);
    }

    disable(): void {
        this.toggleToolBarControls();
        APP.disableJsonEditorOnline(this.editor);
    }

    destroy(): void {
        this.editor.destroy();
    }

    save(): void {
        let schema = "";
        try {
            schema = this.editor.get();
            const content = this.typeDigitalObject.attributes.content as Record<string, unknown>;
            content.schema = schema;
            content.javascript = this.scriptEditor.getValue();
            APP.saveSchema(this.typeDigitalObject, this.typeName);
            APP.closeSchemaEditor();
        } catch (e) {
            console.log(e);
            APP.notifications.alertError(
                `Type ${this.typeName} failed to save. Check schema syntax.`
            );
        }
    }

    deleteSchema(): void {
        const dialog = new ModalYesNoDialog(
            "Are you sure you want to delete this type?",
            (() => this.yesDeleteCallback())
        );
        dialog.show();
    }

    yesDeleteCallback(): void {
        APP.deleteSchema(this.typeName);
    }

    closeClick(): void {
        APP.closeSchemaEditor();
    }

    onEditClick(): void {
        this.toggleToolBarControls();
        this.editorDiv.show();
        this.javascriptDiv.show();
        this.previewDiv.hide();
    }

    previewClick(): void {
        this.previewDiv.empty();
        let schema = this.editor.get();
        schema = JSON.parse(JSON.stringify(schema));
        APP.normalizeSchema(schema as JsonSchema, APP.getSchemaBaseUri(this.typeName, this.typeDigitalObject));
        new PreviewObjectEditor(
            this.previewDiv,
            schema,
            this.typeName,
            null,
            "example/id"
        );
        this.toggleToolBarControls();
        this.editorDiv.hide();
        this.javascriptDiv.hide();
        this.previewDiv.show();
    }
}
