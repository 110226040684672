import { JSONEditor } from '../cordra/JSONEditorCordraMods.js';
import { ObjectPreviewUtil } from "../ObjectPreviewUtil.js";

export class PreviewObjectEditor {
    private readonly typeName: string;
    private readonly objectJson: unknown;
    private readonly objectId: string;
    private readonly editor: typeof JSONEditor;
    private readonly objectIdHeading: JQuery<HTMLHeadingElement>;
    private readonly containerDiv: JQuery<HTMLDivElement>;
    private readonly editorDiv: JQuery<HTMLDivElement>;

    constructor(
            containerDiv: JQuery<HTMLDivElement>,
            schema: unknown,
            typeName: string,
            objectJson: unknown,
            objectId: string
    ) {
        this.containerDiv = containerDiv;
        this.typeName = typeName;
        this.objectJson = objectJson;
        this.objectId = objectId;
        this.objectIdHeading = $("<h3></h3>");

        this.containerDiv.append(this.objectIdHeading);
        if (objectId != null) {
            const objectHeadingText = this.getObjectHeadingText();
            this.objectIdHeading.text("Object Id: " + objectId);
            this.objectIdHeading.text(objectHeadingText);
        }
        this.editorDiv = $("<div></div>");
        this.containerDiv.append(this.editorDiv);

        this.fixPropertyOrder(schema);
        const options = {
            theme: "bootstrap3",
            iconlib: "fontawesome4",
            schema,
            startval: objectJson,
            disable_edit_json: true,
            disable_properties: true,
            disable_collapse: true,
            disabled: false,
            prompt_before_delete: false
        };
        JSONEditor.defaults.options.iconlib = "bootstrap3";
        JSONEditor.defaults.editors.object.options.disable_properties = true;
        JSONEditor.defaults.editors.object.options.disable_edit_json = true;
        JSONEditor.defaults.editors.object.options.disable_collapse = false;

        this.editor = new JSONEditor(this.editorDiv[0], options);
        this.editor.on("change", () => this.onChange());

        const editorTitle = $("div[data-schemapath=root] > h3 > span:first-child");
        if (editorTitle.text() === "root") {
            editorTitle.parent().hide();
        }
    }

    fixPropertyOrder(schema: unknown): void {
        if (typeof schema !== 'object') return;
        for (const item of Object.values(schema as object)) {
            this.fixPropertyOrder(item);
        }
        const props = (schema as Record<string, unknown>).properties;
        if (typeof props === 'object') {
            let count = 1;
            for (const propSchema of Object.values(props as object)) {
                if (typeof propSchema === 'object') {
                    propSchema.propertyOrder = count;
                    count++;
                }
            }
        }
    }

    getObjectHeadingText(): string {
        const digitalObject = {
            id: this.objectId,
            attributes:  {
                content: this.objectJson
            },
            type: this.typeName
        };
        const previewData = ObjectPreviewUtil.getPreviewData(digitalObject);
        let objectHeadingText = null;
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            const prettifiedPreviewData = ObjectPreviewUtil.prettifyPreviewJson(thisPreviewData.previewJson);
            if (!prettifiedPreviewData) continue;
            if (thisPreviewData.isPrimary) {
                objectHeadingText = prettifiedPreviewData;
                break;
            }
        }
        if (objectHeadingText == null) {
            objectHeadingText = `Object Id: ${this.objectId}`;
        }
        return objectHeadingText;
    }

    onChange(): void {
        this.fixButtonGroupCss();
    }

    fixButtonGroupCss(): void {
        $(".cordra-round-left").removeClass("cordra-round-left");
        $(".cordra-round-right").removeClass("cordra-round-right");
        this.editorDiv.find(".btn-group").each((_, div) => {
            const $div = $(div);
            const firstChild = $div.children().first();
            if (!firstChild.is(":visible")) {
                const firstDisplayedChild = $div.children(":visible").first();
                firstDisplayedChild.addClass("cordra-round-left");
            }
            const lastChild = $div.children().last();
            if (!lastChild.is(":visible")) {
                const lastDisplayedChild = $div.children(":visible").last();
                lastDisplayedChild.addClass("cordra-round-right");
            }
        });
    }
}
