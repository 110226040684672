import { default as JsonEditorOnline, JSONEditorOptions } from 'jsoneditor';

export class UiConfigEditor {
    private readonly editor: JsonEditorOnline;
    private readonly saveButton: JQuery<HTMLButtonElement>;
    private readonly toolBarDiv: JQuery<HTMLDivElement>;

    constructor(containerDiv: JQuery, uiConfig: unknown, disabled: boolean) {
        const headerRow = $('<div class="row object-header"></div>');
        containerDiv.append(headerRow);

        const objectHeader = $('<div class="heading col-md-6"></div>');
        const objectIdHeading = $('<h3 class="editorTitle">UI Configuration</h3>');
        objectHeader.append(objectIdHeading);
        headerRow.append(objectHeader);

        this.toolBarDiv = $(
            '<div class="object-editor-toolbar col-md-6 pull-right"></div>'
        );
        headerRow.append(this.toolBarDiv);

        if (disabled) {
            this.saveButton = $(
                '<button class="btn btn-sm btn-primary" style="display:none"><i class="fa fa-save"></i></button>'
            );
        } else {
            this.saveButton = $(
                '<button class="btn btn-sm btn-primary"><i class="fa fa-save"></i></button>'
            );
        }
        this.toolBarDiv.append(this.saveButton);
        this.saveButton.on("click", () => this.save());

        const saveButtonSpan = $("<span></span>");
        this.saveButton.append(saveButtonSpan);
        saveButtonSpan.text("Save");

        const description = $(
            "<p>This structure lets you configure some aspects of the user interface. Specifically the title of the service, the query that should be run when the page first loads, and the list of links in the navigation bar can be configured.</p>"
        );
        containerDiv.append(description);

        const editorDiv = $('<div style="height:500px;" class="col-md-12"></div>');
        containerDiv.append(editorDiv);

        const container = editorDiv[0];

        const options = {
            ace,
            theme: "ace/theme/textmate",
            mode: "code",
            modes: ["code", "tree"], // allowed modes
            onError(err: Error) {
                alert(err.toString());
            }
        } as JSONEditorOptions;
        this.editor = new JsonEditorOnline(container, options, uiConfig);
        if (disabled) {
            APP.disableJsonEditorOnline(this.editor);
        }
    }

    onCloseClick(): void {
        APP.clearFragment();
    }

    disable(): void {
        this.saveButton.hide();
        APP.disableJsonEditorOnline(this.editor);
    }

    enable(): void {
        this.saveButton.show();
        APP.enableJsonEditorOnline(this.editor);
    }

    destroy(): void {
        this.editor.destroy();
    }

    save(): void {
        const config = this.editor.get();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        APP.saveUiConfig(config as Record<string, any>);
    }
}
