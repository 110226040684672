export class ClientSideFileWidget {
    private readonly fileInput: JQuery<HTMLInputElement>;
    private readonly onFileReadCallback: (keyBytes: ArrayBuffer | string | null) => void;
    private readonly buttonText: string;
    private readonly isButtonSmall: boolean;
    private isReadAsText = false;

    constructor(
            container: JQuery<HTMLDivElement>,
            onFileReadCallback: (keyBytes: ArrayBuffer | string | null) => void,
            isReadAsTextParam: boolean,
            buttonText: string,
            isButtonSmall: boolean = false
    ) {
        this.onFileReadCallback = onFileReadCallback;
        this.buttonText = buttonText;
        this.isButtonSmall = isButtonSmall;
        this.fileInput = $('<input type="file"/>');
        container.append(this.fileInput);
        this.prettifyThisFileInput();
        this.setIsReadAsText(isReadAsTextParam);
    }

    readFileAsBase64(): void {
        if (!this.fileInput[0].files || this.fileInput[0].files.length === 0) return;
        const reader = new FileReader();
        reader.onload = (e) => this.onload(e);
        reader.readAsDataURL(this.fileInput[0].files[0]);
    }

    readFileAsBytes(): void {
        if (!this.fileInput[0].files || this.fileInput[0].files.length === 0) return;
        const reader = new FileReader();
        reader.onload = (e) => this.onload(e);
        reader.readAsArrayBuffer(this.fileInput[0].files[0]);
    }

    readFileAsText(): void {
        if (!this.fileInput[0].files || this.fileInput[0].files.length === 0) return;
        const reader = new FileReader();
        reader.onload = (e) => this.onload(e);
        reader.readAsText(this.fileInput[0].files[0]);
    }

    onload(event: ProgressEvent<FileReader>): void {
        if (!event.target) return;
        let data;
        if (this.isReadAsText) {
            data = event.target.result;
        } else {
            data = new Uint8Array(event.target.result as ArrayBuffer);
        }
        this.onFileReadCallback(data);
    }

    clear(): void {
        this.fileInput[0].value = '';
        this.onFileReadCallback(null);
        this.fileInput.trigger('change');
    }

    setIsReadAsText(isReadAsTextParam: boolean): void {
        this.isReadAsText = isReadAsTextParam;
        if (this.isReadAsText) {
            this.fileInput.on('change', () => this.readFileAsText());
        } else {
            this.fileInput.on('change', () => this.readFileAsBytes());
        }
    }

    prettifyThisFileInput(): void {
        if (this.fileInput.css('left') === '-1999px') return;
        this.fileInput.css('position', 'absolute');
        this.fileInput.css('left', '-1999px');
        let textForButton = "Choose files";
        if (this.buttonText) {
            textForButton = this.buttonText;
        }
        const button = $('<button class="btn btn-sm btn-default" style="min-width: 130px;" type="button"></button>');

        button.text(textForButton);
        if (this.isButtonSmall) {
            button.addClass("btn-sm");
        }
        const span = $('<span class="help-inline">No files chosen</span>');
        this.fileInput.before(button, " ", span);
        button.off('click').on('click', (event) => {
            event.stopImmediatePropagation();
            this.fileInput.trigger('click');
        });
        this.fileInput.on('change', () => {
            if (!this.fileInput[0].files || this.fileInput[0].files.length === 0) {
                span.text('No files chosen');
            } else if (this.fileInput[0].files.length === 1) {
                span.text(this.fileInput[0].files[0].name);
            } else {
                span.text(this.fileInput[0].files.length + ' files');
            }
        });
    }
}
