import {
    DigitalObject,
    DigitalElement,
    DigitalElementWithBody,
    SearchResponse,
    UploadProgressEvent
} from '@cnri/doip-client';

import {
    CordraObject,
    Payload,
    SearchResults,
    FacetResult,
    ProgressCallback,
    ProgressEvent
} from '@cnri/cordra-client';

export const ObjectConvertUtil = {
    digitalObjectToCordraObject,
    cordraObjectToDigitalObject,
    digitalObjectSearchResultsToCordraObjects,
    progressCallbackToDoipProgressCallback,
    cordraObjectsToDigitalObjects,
    getUserMetadataFromDigitalObject,
    setUserMetadataOnDigitalObject
};

function progressCallbackToDoipProgressCallback(progressCallBack: ProgressCallback): (progressEvent: UploadProgressEvent) => void {
    return (doipProgressEvent: UploadProgressEvent) => {
        const cordraProgressEvent: ProgressEvent = doipProgressEventToCordraProgressEvent(doipProgressEvent);
        progressCallBack(cordraProgressEvent);
    };
}

function doipProgressEventToCordraProgressEvent(doipProgressEvent: UploadProgressEvent): ProgressEvent {
    //IN
    // UploadProgressEvent
    // [elementId: string]: number;

    //OUT
    // export interface ProgressEvent {
    //     readonly lengthComputable?: boolean;
    //     readonly loaded?: number;
    //     readonly total?: number;
    // }

    let loaded = 0;
    for (const id in doipProgressEvent) {
        const count = doipProgressEvent[id];
        loaded += count;
    }
    const cordraProgressEvent: ProgressEvent = {
        lengthComputable: false,
        loaded
    };
    return cordraProgressEvent;

}

function digitalObjectSearchResultsToCordraObjects(doipResults: SearchResponse<DigitalObject>, pageNum: number, pageSize: number): SearchResults<CordraObject> {
    const results: SearchResults<CordraObject> = {
        size: doipResults.size,
        results: [],
        pageNum,
        pageSize
    };
    for (const dobj of doipResults.results) {
        const co = digitalObjectToCordraObject(dobj);
        results.results.push(co!);
        if (doipResults.facets) {
            results.facets = doipResults.facets as FacetResult[];
        }
    }
    return results;
}

//Essentually get non-cordra specific attributes
function getUserMetadataFromDigitalObject(digitalObject: DigitalObject): any {
    const userMetadata: any = {};
    if (digitalObject.attributes) {
        const attributes: any = digitalObject.attributes;
        for (const attribute in attributes) {
            if ("content" === attribute) {
                continue;
            } else if ("acl" === attribute) {
                continue;
            } else if ("metadata" === attribute) {
                continue;
            } else if ("responseContext" === attribute) {
                continue;
            } else {
                //userMetadata[userMetadataKeyOfDoipAttribute(attribute)] = attributes[attribute];
                userMetadata[attribute] = attributes[attribute];
            }
        }
    }
    return userMetadata;
}

function setUserMetadataOnDigitalObject(userMetadata: any, digitalObject: DigitalObject): void {
    const attributes: any = digitalObject.attributes;
    for (const attribute in userMetadata) {
        if ("content" === attribute) {
            continue;
        } else if ("acl" === attribute) {
            continue;
        } else if ("metadata" === attribute) {
            continue;
        } else if ("responseContext" === attribute) {
            continue;
        } else {
            attributes[attribute] = userMetadata[attribute];
        }
    }
}

function digitalObjectToCordraObject(digitalObject: DigitalObject | null, includePayloadBytes: boolean = false): CordraObject | null {
    if (!digitalObject) return null;
    const co: CordraObject = {};
    co.id = digitalObject.id;
    co.type = digitalObject.type;
    if (digitalObject.attributes) {
        const attributes = digitalObject.attributes! as any;
        const userMetadata: any = {};
        for (const attribute in attributes) {
            if ("content" === attribute) {
                co.content = attributes.content;
            } else if ("acl" === attribute) {
                co.acl = attributes.acl;
            } else if ("metadata" === attribute) {
                co.metadata = attributes.metadata;
            } else if ("responseContext" === attribute) {
                const responseContextValue = attributes.responseContext;
                if (typeof responseContextValue === 'object') {
                    co.responseContext = responseContextValue;
                }
            } else {
                userMetadata[userMetadataKeyOfDoipAttribute(attribute)] = attributes[attribute];
            }
        }
    }
    if (digitalObject.elements && digitalObject.elements.length > 0) {
        co.payloads = [];
        for (const el of digitalObject.elements) {
            const p: Payload = {
                name: el.id,
                mediaType: el.type
            };
            if (el.length != null) {
                p.size = el.length;
            }
            if (el.attributes) {
                const elAttributes: any = el.attributes! as any;
                if (elAttributes.filename) {
                    p.filename = elAttributes.filename;
                }
            }
            if (includePayloadBytes) {
                //TODO
            }
            co.payloads.push(p);
        }
    }
    return co;
}

function userMetadataKeyOfDoipAttribute(key: string): string {
    if (key.startsWith("userMetadata.")) return key.substring(13);
    else return key;
}

function cordraObjectsToDigitalObjects(cordraObjects: CordraObject[], includePayloadBytes: boolean = false): DigitalObject[] {
    const result: DigitalObject[] = [];
    for (let co of cordraObjects) {
        const dobj = cordraObjectToDigitalObject(co, includePayloadBytes);
        result.push(dobj!);
    }
    return result;
}

function cordraObjectToDigitalObject(co: CordraObject, includePayloadBytes: boolean = false): DigitalObject | null {
    if (!co) return null;
    const digitalObject: DigitalObject = {
        attributes: {}
    };
    digitalObject.id = co.id;
    digitalObject.type = co.type;
    const dobjAttributes = digitalObject.attributes;
    dobjAttributes.content = co.content;
    if (co.acl) dobjAttributes.acl = co.acl;
    if (co.metadata) dobjAttributes.metadata = co.metadata;
    if (co.responseContext) dobjAttributes.responseContext = co.responseContext;
    if (co.userMetadata) {
        for (const attribute in co.userMetadata) {
            const dobjAttribute: string = doipAttributeOfUserMetadataKey(attribute);
            const userMetadataAsAny = co.userMetadata as any;
            dobjAttributes[dobjAttribute] = userMetadataAsAny[attribute];
        }
    }
    if (co.payloads != null && co.payloads.length !== 0) {
        digitalObject.elements = [];
        for (const payload of co.payloads) {
            let el: DigitalElement = {
                id: payload.name
            };
            if (payload.size && payload.size >= 0) el.length = payload.size;
            el.type = payload.mediaType;
            el.attributes = {};
            if (payload.filename != null) (el.attributes as any).filename = payload.filename;
            if (includePayloadBytes && payload.body) {
                el = new DigitalElementWithBody({ ...el, body: payload.body });
            }
            digitalObject.elements.push(el);
        }
    }
    return digitalObject;
}

function doipAttributeOfUserMetadataKey(key: string): string {
    if ("content" === key) return "userMetadata.content";
    else if ("acl" === key) return "userMetadata.acl";
    else if ("metadata" === key) return "userMetadata.metadata";
    else if ("responseContext" === key) return "userMetadata.responseContext";
    else if (key.startsWith("userMetadata.")) return "userMetadata." + key;
    else return key;
}
