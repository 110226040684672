export function getFontAwesomeIconNameFor(mediaType?: string, filename?: string): string {
    if (!mediaType && !filename) {
        return "file-o";
    }
    if (isMsWord(mediaType, filename)) return "file-word-o";
    if (isMsExcel(mediaType, filename)) return "file-excel-o";
    if (isMsPowerpoint(mediaType, filename)) return "file-powerpoint-o";
    if (isZip(mediaType, filename)) return "file-zip-o";
    if (isImage(mediaType, filename)) return "file-image-o";
    if (isPdf(mediaType, filename)) return "file-pdf-o";
    if (isVideo(mediaType, filename)) return "file-video-o";
    if (isAudio(mediaType, filename)) return "file-audio-o";
    if (isText(mediaType, filename)) return "file-text-o";
    return "file-o";
}

function isMsWord(mediaType?: string, filename = ''): boolean {
    if (mediaType === "application/msword") return true;
    if (filename.endsWith(".doc")) return true;
    return filename.endsWith(".docx");
}

function isMsExcel(mediaType?: string, filename = ''): boolean {
    if (mediaType === "application/vnd.ms-excel") return true;
    if (filename.endsWith(".xls")) return true;
    return filename.endsWith(".xlsx");
}

function isMsPowerpoint(mediaType?: string, filename = ''): boolean {
    if (mediaType === "application/vnd.ms-powerpoint") return true;
    if (filename.endsWith(".ppt")) return true;
    return filename.endsWith(".pptx");
}

function isZip(mediaType?: string, filename = '') {
    if (mediaType === "application/zip") return true;
    if (filename.endsWith(".zip")) return true;
    if (filename.endsWith(".gz")) return true;
    return filename.endsWith(".tar");
}

export function isText(_mediaType?: string, filename = ''): boolean {
    if (filename.endsWith(".txt")) return true;
    if (filename.endsWith(".js")) return true;
    if (filename.endsWith(".html")) return true;
    return filename.endsWith(".json");
}

export function isJson(_mediaType?: string, filename = ''): boolean {
    return filename.endsWith(".json");
}

export function isJavaScript(_mediaType?: string, filename = ''): boolean {
    return filename.endsWith(".js");
}

export function isHtml(_mediaType?: string, filename = ''): boolean {
    return filename.endsWith(".html");
}

export function isImage(_mediaType?: string, filename = ''): boolean {
    if (!filename) {
        return false;
    }

    if (hasExtension(filename, ".gif")) return true;
    else if (hasExtension(filename, ".jpg")) return true;
    else if (hasExtension(filename, ".jpeg")) return true;
    else if (hasExtension(filename, ".tiff")) return true;
    else if (hasExtension(filename, ".png")) return true;
    else if (hasExtension(filename, ".bmp")) return true;
    return false;
}

export function isVideo(mediaType?: string, filename = ''): boolean {
    if (mediaType && mediaType.startsWith("video/")) {
        return true;
    }
    if (!filename) {
        return false;
    }
    const videoExtensions = [".3gp", ".amv", ".asf", ".avi", ".divx", ".mov", ".mp4", ".mpg", ".mpeg", ".qt", ".rm", ".wmv"];
    for (const extension of videoExtensions) {
        if (filename.endsWith(extension)) return true;
    }
    return false;
}

export function isAudio(mediaType?: string, filename = ''): boolean {
    if (mediaType && mediaType.startsWith("audio/")) {
        return true;
    }
    if (!filename) {
        return false;
    }
    const audioExtensions = [".mp3", ".wav", ".ogg", ".wma"];
    for (const extension of audioExtensions) {
        if (filename.endsWith(extension)) return true;
    }
    return false;
}

function isPdf(_mediaType?: string, filename = '') {
    return filename.endsWith(".pdf");
}

function hasExtension(str: string, suffix: string): boolean {
    if (str.endsWith(suffix)) {
        return true;
    } else {
        const strU = str.toUpperCase();
        const suffixU = suffix.toUpperCase();
        return strU.endsWith(suffixU);
    }
}
