import * as toastr from 'toastr';

export class Notifications {
    private readonly optionsOverride: ToastrOptions;

    constructor() {
        this.optionsOverride = {
            escapeHtml: true,
            closeButton: false,
            debug: false,
            newestOnTop: false,
            progressBar: false,
            positionClass: "toast-bottom-full-width",
            preventDuplicates: false,
            onclick: undefined,
            showDuration: 300,
            hideDuration: 1000,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut"
        };
    }

    alertWarning(messageText: string): void {
        toastr.warning(messageText, undefined, this.optionsOverride);
    }

    alertError(messageText: string): void {
        toastr.error(messageText, undefined, this.optionsOverride);
    }

    alertSuccess(messageText: string): void {
        toastr.success(messageText, undefined, this.optionsOverride);
    }

    alertInfo(messageText: string): void {
        toastr.info(messageText, undefined, this.optionsOverride);
    }

    clear(): void {
        toastr.remove();
    }
}
