import {
    DigitalObject,
    AbstractDoipClient,
    DoipRequest,
    AuthenticationInfo
} from '@cnri/doip-client';

import { AccessControlList } from "./AccessControlList.js";
import { VersionInfo } from "./VersionInfo.js";
import { InitData } from './InitData.js';

export class CordraDoipOperations {

    private readonly doipClient: AbstractDoipClient;

    static OP_AUTH_INTROSPECT = "20.DOIP/Op.Auth.Introspect";
    static OP_AUTH_TOKEN = "20.DOIP/Op.Auth.Token";
    static OP_AUTH_REVOKE = "20.DOIP/Op.Auth.Revoke";
    static OP_CHANGE_PASSWORD = "20.DOIP/Op.ChangePassword";
    static OP_CHECK_CREDENTIALS = "20.DOIP/Op.CheckCredentials";

    static OP_GET_INIT_DATA = "20.DOIP/Op.GetInitData";
    static OP_GET_RELATIONSHIPS = "20.DOIP/Op.Relationships.Get";
    static OP_PUBLISH_VERSION = "20.DOIP/Op.Versions.Publish";
    static OP_GET_VERSIONS = "20.DOIP/Op.Versions.Get";
    static OP_GET_ACLS = "20.DOIP/Op.GetAcls";
    static OP_UPDATE_ACLS = "20.DOIP/Op.UpdateAcls";
    static OP_UPDATE_AT_POINTER = "20.DOIP/Op.UpdateAtPointer";
    static OP_UPDATE_ALL_HANDLE_RECORDS = "20.DOIP/Op.UpdateAllHandleRecords";
    static OP_GET_HANDLE_RECORDS_UPDATE_STATUS = "20.DOIP/Op.GetHandleRecordsUpdateStatus";
    static OP_BATCH_UPLOAD = "20.DOIP/Op.BatchUpload";
    static OP_BATCH_DELETE = "20.DOIP/Op.BatchDelete";

    constructor(doipClient: AbstractDoipClient) {
        this.doipClient = doipClient;
    }

    async getInitData(): Promise<InitData> {
        const request: DoipRequest = {
            targetId: "service",
            operationId: CordraDoipOperations.OP_GET_INIT_DATA

        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        const initData: InitData = responseJson as InitData;
        return initData;
    }

    async getRelationships(targetId: string, outboundOnly: boolean = false): Promise<any> {
        const request: DoipRequest = {
            targetId,
            operationId: CordraDoipOperations.OP_GET_RELATIONSHIPS
        };
        if (outboundOnly) {
            request.attributes = {
                outboundOnly: true
            };
        }
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson;
    }

    async publishVersion(objectId: string): Promise<VersionInfo> {
        const request: DoipRequest = {
            targetId: objectId,
            operationId: CordraDoipOperations.OP_PUBLISH_VERSION
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson as VersionInfo;
    }

    async getVersions(objectId: string): Promise<VersionInfo[]> {
        const request: DoipRequest = {
            targetId: objectId,
            operationId: CordraDoipOperations.OP_GET_VERSIONS
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson as VersionInfo[];
    }

    async getAcls(objectId: string): Promise<AccessControlList> {
        const request: DoipRequest = {
            targetId: objectId,
            operationId: CordraDoipOperations.OP_GET_ACLS
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson as AccessControlList;
    }

    async updateAcls(objectId: string, acls: AccessControlList): Promise<AccessControlList> {
        const request: DoipRequest = {
            targetId: objectId,
            operationId: CordraDoipOperations.OP_UPDATE_ACLS,
            inputJson: acls
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson as AccessControlList;
    }

    async updateAtPointer(objectId: string, jsonPointer: string, input: any): Promise<DigitalObject> {
        const request: DoipRequest = {
            targetId: objectId,
            operationId: CordraDoipOperations.OP_UPDATE_AT_POINTER,
            inputJson: input,
            attributes: {
                jsonPointer
            }
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson as DigitalObject;
    }

    async updateAllHandleRecords(): Promise<void> {
        const request: DoipRequest = {
            targetId: "service",
            operationId: CordraDoipOperations.OP_UPDATE_ALL_HANDLE_RECORDS
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
    }

    async getHandleRecordsUpdateStatus(): Promise<any> {
        const request: DoipRequest = {
            targetId: "service",
            operationId: CordraDoipOperations.OP_GET_HANDLE_RECORDS_UPDATE_STATUS
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson;
    }

    async changePassword(newPassword: string, authInfo: AuthenticationInfo): Promise<any> {
        const request: DoipRequest = {
            targetId: "service",
            operationId: CordraDoipOperations.OP_CHANGE_PASSWORD,
            authentication: authInfo,
            inputJson: {
                password: newPassword
            }
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson;
    }

    async changeAdminPassword(newPassword: string, authInfo: AuthenticationInfo): Promise<any> {
        const request: DoipRequest = {
            targetId: "service",
            operationId: CordraDoipOperations.OP_CHANGE_PASSWORD,
            authentication: authInfo,
            inputJson: {
                password: newPassword
            }
        };
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson;
    }

    async batchUpload(objects: DigitalObject[]): Promise<any> {
        const request: DoipRequest = {
            targetId: "service",
            operationId: CordraDoipOperations.OP_BATCH_UPLOAD,
            attributes: {
                parallel: false
            },
            inputJson: objects
        };
        await this.doipClient.performCompactOperationJsonResponse(request);
    }

    async batchDelete(all: boolean, query: string | undefined, ids: string[] | undefined, parallel: boolean, dryRun: boolean): Promise<any> {
        const request: DoipRequest = {
            targetId: "service",
            operationId: CordraDoipOperations.OP_BATCH_DELETE,
            attributes: {
                all,
                query,
                parallel,
                dryRun
            }
        };
        if (ids) {
            request.inputJson = ids;
        }
        const responseJson = await this.doipClient.performCompactOperationJsonResponse(request);
        return responseJson;
    }
}
