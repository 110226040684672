import { QueryFormField } from "./QueryForm.js";

export class QueryFormMultiSelect {

    private readonly queryFormField: QueryFormField;
    private readonly div: JQuery;
    private readonly checkboxes: JQuery[] = [];

    constructor(
            containerDiv: JQuery,
            queryFormField: QueryFormField
    ) {
        this.queryFormField = queryFormField;
        this.div = this.buildBooleanElementsForField(this.queryFormField);
        containerDiv.append(this.div);
    }

    buildBooleanElementsForField(field: QueryFormField): JQuery {
        const group = $('<div class="form-group"></div>');
        const label = $('<label></label>');
        label.text(field.name);
        group.append(label);
        for (const text of field.allowedValues!) {
            const option = this.buildBooleanElementForOption(text);
            group.append(option);
        }
        return group;
    }

    buildBooleanElementForOption(text: string): JQuery {
        const checkbox = $('<div class="checkbox"></div>');
        const label = $('<label></label>');
        checkbox.append(label);
        const input = $('<input type="checkbox"/>');
        this.checkboxes.push(input);
        label.append(input);
        const span = $('<span></span>');
        span.text(text);
        label.append(span);
        return checkbox;
    }

    getQueryForSelectedOptions() {
        let result = "+(";
        let queryField = this.queryFormField.queryField;
        let allowedValues = this.queryFormField.allowedValues!;
        let operator = " +";
        if (this.queryFormField.combineTermsWithOr) {
            operator = " ";
        }
        for (let i = 0; i < allowedValues.length; i++) {
            let value = allowedValues[i];
            let checkbox = this.checkboxes[i];
            if (checkbox.prop('checked')) {
                result += operator + queryField + ":" + this.escapeForQuery(value);
            }
        }
        result += ")";
        return result;
    }

    val() {
        return this.getQueryForSelectedOptions();
    }

    //hack becasue this object will be in a list of input elements
    attr() {
        return 'QueryFormMultiSelect';
    }

    escapeForQuery(s: string): string {
        if (!s) return s;
        return s.replace(/[\\+\-!():^[\]"{}~*?|&/]/g, '\\$&');
    }
}