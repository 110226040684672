import { JsonSchema } from "tv4";
import { JSONEditor } from '../cordra/JSONEditorCordraMods.js';
import { ComboBox } from "../cnri.ui.ComboBox.js";
import { SchemaUtil } from './SchemaUtil.js';

export class SuggestedVocabularySelector {
    private readonly textInput: JQuery<HTMLInputElement>;
    private readonly editor: typeof JSONEditor;

    constructor(textInput: JQuery<HTMLInputElement>, editor: typeof JSONEditor) {
        this.textInput = textInput;
        this.editor = editor;
        const items = SchemaUtil.getDeepCordraSchemaProperty(this.editor.schema as JsonSchema, 'type', 'suggestedVocabulary');
        new ComboBox(this.textInput, items as string[]);
        this.textInput.on('change', () => this.onChange());
    }

    onChange(): void {
        const value = this.textInput.val();
        this.textInput.val(this.editor.getValue() as string);
        this.editor.setValue(value);
    }

    enable(): void {

    }

    disable(): void {
        // consider disable in ComboBox
    }
}
