import { Notifications } from './ToastrNotifications.js';
import { TokenUsingDoipClient } from './TokenUsingDoipClient.js';
import {
    AuthenticationInfo,
    PasswordAuthenticationInfo,
} from '@cnri/doip-client';

import * as DoipModule from '@cnri/doip-client';

export class CordraUiSupport {

    private readonly doipClient: TokenUsingDoipClient;
    private readonly notifications: Notifications;
    private readonly displaySignInFunction: () => void;
    public Doip = DoipModule;

    constructor(doipClient: TokenUsingDoipClient, notifications: Notifications, displaySignInFunction: () => void) {
        this.doipClient = doipClient;
        this.notifications = notifications;
        this.displaySignInFunction = displaySignInFunction;
    }

    getDoipClient(): TokenUsingDoipClient {
        return this.doipClient;
    }

    getNotifications(): Notifications {
        return this.notifications;
    }

    displaySignInForm(): void {
        this.displaySignInFunction();
    }

    getPasswordAuthenticationInfoClass() {
        return PasswordAuthenticationInfo;
    }

}
