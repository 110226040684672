export class DesignJavaScriptEditor {
    private readonly jimSelect: JQuery<HTMLSelectElement>;
    private readonly saveButton: JQuery<HTMLButtonElement>;
    private readonly scriptEditorDiv: JQuery<HTMLDivElement>;
    private readonly scriptEditor: AceAjax.Editor;
    private readonly toolBarDiv: JQuery<HTMLDivElement>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(containerDiv: JQuery, design: Record<string, any>, disabled: boolean) {
        const headerRow = $('<div class="row object-header"></div>');
        containerDiv.append(headerRow);

        const objectHeader = $('<div class="heading col-md-6"></div>');
        const objectIdHeading = $('<h3 class="editorTitle">Design JavaScript</h3>');
        objectHeader.append(objectIdHeading);
        headerRow.append(objectHeader);

        this.toolBarDiv = $(
            '<div class="object-editor-toolbar col-md-6 pull-right"></div>'
        );
        headerRow.append(this.toolBarDiv);

        if (disabled) {
            this.saveButton = $(
                '<button class="btn btn-sm btn-primary" style="display:none"><i class="fa fa-save"></i></button>'
            );
        } else {
            this.saveButton = $(
                '<button class="btn btn-sm btn-primary"><i class="fa fa-save"></i></button>'
            );
        }
        this.toolBarDiv.append(this.saveButton);
        this.saveButton.on("click", () => this.save());

        const saveButtonSpan = $("<span></span>");
        this.saveButton.append(saveButtonSpan);
        saveButtonSpan.text("Save");

        const description = $(
            '<p>Edit the "javascript" property of the design object. This allows for the creation of a "generateId" that can be used to programmatically generate the handles on objects for create requests.</p>'
        );
        containerDiv.append(description);

        const jimLabel = $('<label class="col-sm-2 control-label">JavaScript is module</label>');
        containerDiv.append(jimLabel);

        const jimDiv = $('<div class="col-sm-10"/>');
        containerDiv.append(jimDiv);
        this.jimSelect = $('<select class="form-control"><option value=""> </option><option value="true">true</option><option value="false">false</option></select>');
        if (design.javascriptIsModule !== undefined) {
            this.jimSelect.val((design.javascriptIsModule as boolean).toString());
        }
        jimDiv.append(this.jimSelect);
        this.jimSelect.css("margin-bottom", "10px");

        const jsLabel = $('<label class="col-sm-2 control-label">JavaScript</label>');
        containerDiv.append(jsLabel);

        const scriptEditorDivContainer = $('<div class="col-sm-10"/>');
        this.scriptEditorDiv = $(
            '<div id="designJavaScriptEditor" class="ace_editor"></div>'
        );
        scriptEditorDivContainer.append(this.scriptEditorDiv);
        containerDiv.append(scriptEditorDivContainer);

        this.scriptEditor = ace.edit("designJavaScriptEditor");
        this.scriptEditor.setTheme("ace/theme/textmate");
        APP.fixAceJavascriptEditor(this.scriptEditor);
        this.scriptEditor.getSession().setMode("ace/mode/javascript");
        this.scriptEditor.setOptions({
            maxLines: Infinity,
            minLines: 10
        });
        this.scriptEditor.$blockScrolling = Infinity;
        if (design.javascript) {
            this.scriptEditor.setValue(design.javascript as string, -1);
        }
    }

    onCloseClick(): void {
        APP.clearFragment();
    }

    disable(): void {
        this.saveButton.hide();
    }

    enable(): void {
        this.saveButton.show();
    }

    destroy(): void {
        this.scriptEditor.destroy();
    }

    save(): void {
        const jimString = this.jimSelect.val();
        const jim = (jimString === "") ? undefined : (jimString === "true");
        const js = this.scriptEditor.getValue();
        APP.saveDesignJavaScript(jim, js);
    }
}
