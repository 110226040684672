import { html, render } from 'lit-html';

interface AboutInfoVersion {
    number?: string;
    timestamp?: string;
    id?: string;
}

export class AboutInfo {
    constructor(containerDiv: HTMLElement, version: AboutInfoVersion) {
        const template = html`
            <div style='text-align: center;'>
                <img width="80" src="./img/cordra-primary-blue-large.png" alt="logo"/>
                <h3>Cordra</h3>
                <p>Version: ${version.number}</p>
                <p>Date: ${version.timestamp}</p>
                <p>Build id: ${version.id}</p>
                <p><a href="#urls/openapi/index.html">DOIP HTTP API Docs</a></p>
            </div>
        `;
        render(template, containerDiv);
    }
}
