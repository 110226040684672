import { JSONEditor } from '../cordra/JSONEditorCordraMods.js';

export class UriEditor {
    private readonly link: JQuery<HTMLLinkElement>;
    private readonly textInput: JQuery<HTMLInputElement>;

    constructor(textInput: JQuery<HTMLInputElement>, editor: typeof JSONEditor) {
        textInput.on('input', () => this.updateLink());
        const div = $('<div/>');
        this.link = $('<a target="_blank"></a>');
        textInput.after(div);
        div.append(this.link);
        editor.jsoneditor.watch(editor.path, () => this.updateLink());
        this.textInput = textInput;
    }

    updateLink(): void {
        this.link.attr('href', this.textInput.val() as string);
        this.link.text(this.textInput.val() as string);
    }

    enable(): void {
        this.textInput.show();
    }

    disable(): void {
        this.textInput.hide();
    }
}
