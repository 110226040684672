export class UploadProgressBar {
    private progressBar?: JQuery;
    private statusLabel?: JQuery;
    private readonly containerDiv: JQuery;

    constructor(containerDiv: JQuery) {
        this.containerDiv = containerDiv;
        this.build();
    }

    build(): void {
        const progressDiv = $('<div class="progress"></div>');
        progressDiv.css("margin-bottom", "0");
        progressDiv.css("margin-top", "10px");
        this.containerDiv.append(progressDiv);
        this.progressBar = $('<div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>');
        progressDiv.append(this.progressBar);
        this.statusLabel = $('<div></div>');
        this.containerDiv.append(this.statusLabel);
    }

    setStatus(percent: number, bytesComplete: number, bytesTotal: number): void {
        if (!this.progressBar || ! this.statusLabel) return;
        const percentString = percent + "%";
        this.progressBar.width(percentString);
        let statusText = `${percentString} (${bytesComplete}/${bytesTotal} bytes)`;
        if (percent === 100) {
            statusText = "Upload complete, processing data...";
        }
        this.statusLabel.text(statusText);
    }

    clear(): void {
        this.containerDiv.empty();
        this.build();
    }
}
