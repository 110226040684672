import { html, render, HTMLTemplateResult } from 'lit-html';
import { ref } from 'lit-html/directives/ref.js';
import { FilterSelectComponent } from './FilterSelectComponent.js';
import { TypeSelectComponent } from './TypeSelectComponent.js';

export class FacetsComponent {
    private readonly container: HTMLElement | undefined;
    private readonly changeCallback: () => void;
    private readonly template: () => HTMLTemplateResult;
    private filters: string[];

    constructor(
            container: Element | undefined,
            selectedFilters: string[],
            changeCallback: () => void,
            getQueryCallback: () => string | undefined
    ) {
        this.container = container as HTMLElement | undefined;
        this.changeCallback = changeCallback;
        this.filters = selectedFilters;
        this.template = () => {
            const typeOptions = APP.getSchemaNames().sort().filter(t => t !== 'CordraDesign');
            const selectedTypes = this.getSelectedTypesFromFilters(this.filters);
            const typeChangeCallback = (newTypes: string[]) => this.typeSelected(newTypes);
            const filtersChangeCallback = (newFilters: string[]) => this.filtersChanged(newFilters);
            return html`
                <div>
                    <div
                            ${ref(el =>
                                new TypeSelectComponent(el, typeChangeCallback, typeOptions, selectedTypes))}>
                    </div>
                    <div
                            ${ref(el =>
                                new FilterSelectComponent(el, selectedTypes, this.filters, getQueryCallback, filtersChangeCallback))}>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    private render(): void {
        if (!this.container) return;
        render(this.template(), this.container);
    }

    hide(): void {
        this.container?.setAttribute('hidden', '');
    }

    show(): void {
        this.container?.removeAttribute('hidden');
    }

    isHidden(): boolean {
        if (!this.container) return true;
        return this.container.hasAttribute('hidden');
    }

    private typeSelected(types: string[] = []): void {
        this.filters = [];
        if (types.length > 0) {
            const typesFilter = `type:("${types.join('" "')}")`;
            this.filters = [typesFilter];
        }
        this.changeCallback();
    }

    private filtersChanged(filters: string[] = []): void {
        const typesOnlyFilter = this.filters.filter(f => f.startsWith('type:'));
        this.filters = [...typesOnlyFilter, ...filters];
        this.changeCallback();
    }

    getFilters(): string[] {
        return this.filters;
    }

    setFilters(filters?: string[]): void {
        this.filters = filters || [];
        this.render();
    }

    clear(): void {
        this.filters = [];
        this.render();
    }

    private getSelectedTypesFromFilters(selectedFilters: string[]): string[] {
        const typeFilters = selectedFilters?.filter(s => s.startsWith('type:'));
        const types: string[] = [];
        for (const filter of typeFilters) {
            const matches = filter.matchAll(/"([^"]*)"/g);
            for (const match of matches) {
                types.push(match[1]);
            }
        }
        return types;
    }
}
