import 'bootstrap/dist/css/bootstrap.css';
import 'jsoneditor/dist/jsoneditor.css';
import 'jsoneditor/examples/css/darktheme.css';
import '../css/cordra.css';
import 'vis-network/styles/vis-network.min.css';
import 'toastr/build/toastr.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '../css/typeahead.css';
import 'bootstrap';
import $ from 'jquery';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - ace-builds doesn't have typedefs
import ace from 'ace-builds/src-noconflict/ace';
import modHtmlUrl from 'ace-builds/src-noconflict/mode-html?url';
import modeJsonUrl from 'ace-builds/src-noconflict/mode-json?url';
import jsonWorkerUrl from 'ace-builds/src-noconflict/worker-json?url';
import modeJsUrl from 'ace-builds/src-noconflict/mode-javascript.js?url';
import jsWorkerUrl from 'ace-builds/src-noconflict/worker-javascript?url';
import themeTextmateUrl from 'ace-builds/src-noconflict/theme-textmate?url';
import languageToolsUrl from 'ace-builds/src-noconflict/ext-language_tools.js?url';

import { CordraUiMain } from './cordra/CordraUiMain.js';

// eslint-disable-next-line
(window as any).$ = $;
window.ace = ace;
ace.config.setModuleUrl('ace/mode/html', modHtmlUrl);
ace.config.setModuleUrl('ace/mode/json', modeJsonUrl);
ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl);
ace.config.setModuleUrl('ace/mode/javascript', modeJsUrl);
ace.config.setModuleUrl("ace/mode/javascript_worker", jsWorkerUrl);
ace.config.setModuleUrl("ace/theme/textmate", themeTextmateUrl);
ace.config.setModuleUrl("ace/ext/language_tools", languageToolsUrl);
ace.config.loadModule("ace/ext/language_tools");

const baseUri: string = import.meta.env.VITE_CORDRA_BASE_URI || '.';
window.APP = new CordraUiMain(baseUri);
