import { VersionInfo } from "./VersionInfo.js";

export class ObjectVersions {
    private readonly versionsListDiv: JQuery;
    private readonly objectId: string;

    constructor(
            containerDiv: JQuery,
            versions: VersionInfo[],
            objectId: string,
            allowEdits: boolean) {
        this.objectId = objectId;
        const actionToolBar = $(
            '<div class="object-editor-toolbar col-md-12 pull-right"></div>'
        );
        containerDiv.append(actionToolBar);
        if (allowEdits) {
            const publishButton = $(
                '<button class="btn btn-sm btn-primary"><i class="fa fa-clone"></i></button>'
            );
            actionToolBar.append(publishButton);
            publishButton.on("click", () => this.onPublishClick());

            const publishButtonSpan = $("<span></span>");
            publishButton.append(publishButtonSpan);
            publishButtonSpan.text("Make Version");
        }
        this.versionsListDiv = $('<div class="col-md-12"></div>');
        containerDiv.append(this.versionsListDiv);
        this.refreshList(versions);
    }

    onPublishClick(): void {
        this.publishVersion();
    }

    refreshList(versionsList: VersionInfo[]): void {
        this.sortByDate(versionsList);

        this.versionsListDiv.empty();
        if (versionsList.length === 0 || versionsList.length === 1) {
            this.versionsListDiv.append("<p>No versions</p>");
        } else {
            const table = $('<table class="table"></table>');
            this.versionsListDiv.append(table);

            const thead = $("<thead></thead>");
            table.append(thead);

            const titleRow = $("<tr></tr>");
            thead.append(titleRow);

            titleRow.append("<th>Versioned on</th>");
            titleRow.append("<th>Versioned by</th>");
            titleRow.append("<th>Id</th>");

            const tbody = $("<tbody></tbody>");
            table.append(tbody);

            for (let i = 0; i < versionsList.length; i++) {
                const versionInfo = versionsList[i];

                const versionDiv = $("<div></div>");
                this.versionsListDiv.append(versionDiv);

                const tr = $("<tr></tr>");
                table.append(tr);

                const tdPublishedOn = $("<td></td>");
                const tdPublishedBy = $("<td></td>");

                if (versionInfo.isTip) {
                    tdPublishedOn.text("(Current)");
                    tdPublishedBy.text("");
                } else {
                    const publishedOn = new Date(versionInfo.publishedOn!).toISOString();
                    tdPublishedOn.text(publishedOn);
                    tdPublishedBy.text(versionInfo.publishedBy!);
                }

                tr.append(tdPublishedOn);
                tr.append(tdPublishedBy);

                const link = $('<a style="display:inline-block" target="_blank">')
                    .attr("href", APP.getBaseUri() + "#objects/" + versionInfo.id)
                    .text(versionInfo.id);
                link.attr("data-handle", versionInfo.id);
                link.on("click", (e) => this.onHandleClick(e));

                const tdId = $("<td></td>");
                tdId.append(link);

                if (versionInfo.id === this.objectId) {
                    tr.addClass("info");
                    const thisObjectFlag = $("<span></span>");
                    thisObjectFlag.text(" This " + versionInfo.type);
                    tdId.append(thisObjectFlag);
                }
                tr.append(tdId);
            }
        }
    }

    onHandleClick(e: JQuery.ClickEvent): void {
        e.preventDefault();
        const link = $(e.target);
        const handle = link.attr("data-handle");
        APP.resolveHandle(handle);
    }

    sortByDate(versionsList: VersionInfo[]): void {
        versionsList.sort(this.compare);
        versionsList.reverse();
    }

    compare(a: VersionInfo, b: VersionInfo): number {
        if (!a.publishedOn) {
            return 1;
        }
        if (!b.publishedOn) {
            return -1;
        }
        if (a.publishedOn < b.publishedOn) {
            return -1;
        }
        if (a.publishedOn > b.publishedOn) {
            return 1;
        }
        return 0;
    }

    getVersions(): void {
        APP.getVersionsFor(
            this.objectId,
            (resp: VersionInfo[]) => this.onGotVersionsSuccess(resp)
        );
    }

    onGotVersionsSuccess(response: VersionInfo[]): void {
        this.refreshList(response);
    }

    publishVersion(): void {
        APP.publishVersion(
            this.objectId,
            () => this.onPublishVersionSuccess()
        );
    }

    onPublishVersionSuccess(): void {
        this.getVersions();
    }
}
