export class UpdateAllHandlesStatusWidget {
    private progressBar?: JQuery<HTMLDivElement>;
    private statusLabel?: JQuery<HTMLDivElement>;
    private readonly containerDiv: JQuery<HTMLDivElement>;

    constructor(containerDiv: JQuery<HTMLDivElement>) {
        this.containerDiv = containerDiv;
        this.build();
    }

    build(): void {
        const progressDiv = $('<div class="progress"></div>');
        progressDiv.css("margin-bottom", "0");
        progressDiv.css("margin-top", "10px");
        this.containerDiv.append(progressDiv);
        this.progressBar = $('<div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: 0"></div>');
        progressDiv.append(this.progressBar);
        this.statusLabel = $('<div></div>');
        this.containerDiv.append(this.statusLabel);
    }

    setStatus(status: { progress: number; total: number; inProgress: boolean; exceptionCount: number }): void {
        if (!this.progressBar || !this.statusLabel) return;
        const percent = Math.round((status.progress / status.total) * 100);
        const percentString = percent + "%";
        this.progressBar.width(percentString);

        let statusText = status.progress + "/" + status.total + " (" + percentString + ")";
        if (!status.inProgress) {
            statusText = "Complete " + statusText;
        }
        if (status.exceptionCount > 0) {
            statusText = statusText + ", " + status.exceptionCount + " errors, see server logs.";
        }
        this.statusLabel.text(statusText);
    }

    clear(): void {
        this.containerDiv.empty();
        this.build();
    }
}
